import React, {
  Dispatch,
  ForwardedRef,
  PropsWithRef,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  Caption,
  H4,
  TertiaryButton,
  useStylesCard,
  Alert,
  Tooltip,
  useFeature,
  useGetAuthorizationByIdWithFallback,
} from "@coherehealth/common";
import {
  ServiceRequestResponse,
  useGetVatNotification,
  VatNotification,
  AuthorizationResponse,
  UserResponse,
  LettersExpansionConfigurationResponse,
} from "@coherehealth/core-platform-api";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useAuthorized } from "authorization";
import { useSnackbar } from "notistack";
import { SRFormViewState } from "components/PatientSummary/ServiceRequestSummaryCard/ServiceRequestFormSection";
import {
  getLatestDeterminedServiceRequest,
  getLatestServiceRequest,
  getSortedServiceRequests,
  getPriorServiceRequest,
  getNextServiceRequest,
} from "util/authorization";
import { Grid } from "@mui/material";
import ServiceRequestRelationDisplay from "components/ServiceRequestRelationDisplay";
import InfoAlert from "common/InfoAlert";
import AuthorizationSummaryDetails from "./AuthorizationSummaryDetails";
import ExpandedServiceRequestCard from "components/ServiceRequest/ExpandedServiceRequestCard/ExpandedServiceRequestCard";
import { extractClinicalServicesText } from "@coherehealth/common";
import useDraftContinue from "components/ServiceRequest/DraftContinue";
import useIsOverflow from "../../../../hooks/useIsOverflow";
import AuthVisibilityMessage from "common/AuthVisibilityMessage";
import { AuthorizationSummaryDropDownMenu } from "./AuthorizationSummaryDropDownMenu/index";
import { useAuthCardStyles, usePatientSummarySharedStyles } from "components/PatientSummary/patientSummaryStyles";
import { CareCoordinationBadge } from "./AuthorizationSummaryCardAdornments/CareCoordinationBadge";
import AuthVisibilityToggle from "common/AuthVisibilityToggle";
import { isSrCreatedByUser } from "util/serviceRequest";
import { ServiceRequestStatusDisplay } from "components/ServiceRequestStatusDisplay/StatusBanner/ServiceRequestStatusDisplay";
import useOpsEditDisabled from "hooks/useOpsEditDisabled";
import PatientSearchModal from "common/UnifiedPatientSearchModal/UnifiedPatientSearchModal";

export interface AuthorizationSummaryCardProps {
  id?: string;
  authorization: AuthorizationResponse;
  // HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
  // eslint-disable-next-line @typescript-eslint/ban-types
  onEdit?: Function;
  initialIsExpanded?: boolean;
  onShowSummaryPrintout?: (auth: AuthorizationResponse) => void;
  clinicalReviewView?: boolean;
  targetReviewId?: string | null;
  setTargetReview?: Dispatch<SetStateAction<HTMLElement | null>>;
  setAuthorizations?: React.Dispatch<React.SetStateAction<AuthorizationResponse[]>>;
  onShowCallLog?: (sr: ServiceRequestResponse, vn?: VatNotification | null, callDispositionMessage?: string) => void;
  authorizationRefetch?: () => Promise<AuthorizationResponse | null>;
  user?: UserResponse | null;
  loadExtendedAuthView?: boolean;
  allowContinuations?: boolean;
  lettersExpansionConfig?: LettersExpansionConfigurationResponse | null;
}

const AuthorizationSummaryCard = React.forwardRef(
  (
    {
      id,
      authorization,
      onEdit,
      onShowSummaryPrintout,
      initialIsExpanded = false,
      clinicalReviewView,
      onShowCallLog,
      targetReviewId,
      setTargetReview,
      authorizationRefetch,
      setAuthorizations,
      user,
      loadExtendedAuthView = true,
      allowContinuations = true,
      lettersExpansionConfig,
      ...otherProps
    }: PropsWithRef<AuthorizationSummaryCardProps>,
    ref: ForwardedRef<HTMLElement>
  ) => {
    const hideContinuations = Boolean(
      useFeature("showPatientStayTimeline") && authorization?.encounterType === "INPATIENT"
    );

    const [isExpanded, setIsExpanded] = useState<boolean>(initialIsExpanded);
    // In order to not render any detail contents (like clinical summary)
    // when it's not needed, because there's a LOT there
    const [contentsVisible, setContentsVisible] = useState(isExpanded);

    const [sortedServiceRequest, setSortedServiceRequest] = useState<ServiceRequestResponse[]>([]);

    const [visibilityToggleStatus, setVisibilityToggleStatus] = useState(
      authorization?.restrictedDataSettings?.allowEditForAuthorizationParticipants || false
    );
    const initialSR = authorization?.serviceRequestsOnAuth?.filter((sr) => sr?.requestType === "INITIAL")?.[0];
    const initialSrCreatedByUser = isSrCreatedByUser(user?.id, initialSR, user?.name);

    const { refetch: authRefetch } = useGetAuthorizationByIdWithFallback({ id: authorization.id || "", lazy: true });

    const showNotificationViewer = !!lettersExpansionConfig?.notificationViewer;
    const adhocLetter = !!lettersExpansionConfig?.adhocLetter;
    const singleAttachmentViewerRedesign = !!lettersExpansionConfig?.singleAttachmentViewerRedesign;

    const textElementRef = useRef<HTMLInputElement | null>(null);

    const { hoverStatus } = useIsOverflow(textElementRef);

    const [isContactInfoModalOpen, setIsContactInfoModalOpen] = useState<boolean>(false);

    const getAuthorization = useCallback(async () => {
      if (!loadExtendedAuthView) {
        onEdit?.();
        setContentsVisible(true);
      } else {
        const response = await authRefetch();
        if (response) {
          setAuthorizations?.((prev) => {
            if (prev) {
              const idx = prev?.findIndex((auth) => auth.id === response.id);
              if (idx >= 0) {
                const start = prev.slice(0, idx);
                const end = prev.slice(idx + 1);
                return [...start, response, ...end];
              } else {
                return [...prev];
              }
            } else {
              return [response];
            }
          });
          setContentsVisible(true);
        }
      }
    }, [authRefetch, loadExtendedAuthView, onEdit, setAuthorizations]);

    useEffect(() => {
      if (contentsVisible && !isExpanded) {
        const timeout = setTimeout(() => {
          setContentsVisible(false);
        }, 1000);
        return clearTimeout(timeout);
      }
      if (!contentsVisible && isExpanded && loadExtendedAuthView) {
        getAuthorization();
      }
    }, [isExpanded, contentsVisible, authorizationRefetch, setAuthorizations, getAuthorization, loadExtendedAuthView]);

    useEffect(() => {
      if (authorization && authorization.serviceRequestsOnAuth && authorization?.serviceRequestsOnAuth?.length > 1) {
        const sortedSR = getSortedServiceRequests(authorization);
        const reverseSR = sortedSR.slice().reverse();
        setSortedServiceRequest(reverseSR);
      }
    }, [authorization]);

    // Might be any authStatus
    const latestSr = getLatestServiceRequest(authorization)?.[1];
    // The latest SR with authStatus != DRAFT
    const latestDeterminedSr = getLatestDeterminedServiceRequest(authorization);
    // The latest determined SR, or if none exist, the latest SR which must be a DRAFT
    const latestDeterminedSrOrDraft = latestDeterminedSr ? latestDeterminedSr : latestSr;
    const [srFormViewState, setSRFormViewState] = useState<SRFormViewState>("READ_ONLY");

    // Continue Draft logic
    const draftContinue = useDraftContinue(latestSr);

    const serviceCategoryName = authorization.clinicalService?.serviceCategoryName;
    const hasServiceCategory = !!serviceCategoryName;
    const patientSummaryStyles = usePatientSummarySharedStyles({ hasServiceCategory });
    const authCardStyles = useAuthCardStyles({ hasServiceCategory });
    const { withHover, ...cardStyles } = useStylesCard({});

    const scrollToClinicalAssessment =
      latestDeterminedSrOrDraft?.type === "Pharmacy" &&
      latestDeterminedSrOrDraft?.authStatus === "PENDING" &&
      latestDeterminedSrOrDraft?.pendingReason === "PENDING_ASSESSMENT_SUBMISSION";

    const withdrawSR = sortedServiceRequest?.filter(
      (sr) => !["VOIDED", "WITHDRAWN"].includes(sr.authStatus ?? "") && !sr?.isSrNoLongerNeeded
    );
    let withdrawSRId = "";
    const withdrawValue = withdrawSR?.[0];

    if (!useOpsEditDisabled(withdrawValue) && withdrawSR && withdrawSR.length) {
      withdrawSRId = withdrawSR[0].id;
    }

    const isAuthViewOnlyUser = useAuthorized("AUTH_VIEW_ONLY");

    const serviceRequestIsRestricted = latestDeterminedSrOrDraft?.isRestricted;
    const serviceRequestIsViewable =
      !latestDeterminedSrOrDraft?.isRestricted && latestDeterminedSrOrDraft?.hasRestrictedCodes;

    const clinicalServicesText = extractClinicalServicesText(
      authorization.clinicalServices || latestDeterminedSrOrDraft?.clinicalServices || [],
      latestDeterminedSrOrDraft?.authCategory,
      latestDeterminedSrOrDraft?.authSubcategory,
      latestDeterminedSrOrDraft?.palCategory
    );

    if (latestDeterminedSrOrDraft) {
      return (
        <>
          {clinicalReviewView ? (
            <>
              {authorization.clinicalService?.description && (
                <Caption className={`${patientSummaryStyles.serviceDescription}${isExpanded ? "" : " hidden"}`}>
                  {authorization.clinicalService?.description}
                </Caption>
              )}
              {latestDeterminedSrOrDraft.isAdvanceCoverageDetermination && (
                <Alert
                  severity="warning"
                  message="This request has been processed as an advanced coverage determination."
                  className={`${patientSummaryStyles.acdAlert}${isExpanded ? "" : " hidden"}`}
                >
                  This is a Medicare Advantage private fee-for-service (PFFS) member.
                </Alert>
              )}
              <ServiceRequestStatusDisplay
                authorization={authorization}
                onDeleteDraft={onEdit}
                onEdit={onEdit}
                serviceRequest={latestDeterminedSrOrDraft}
                openServiceRequestForEditing={() => {
                  setIsExpanded(true);
                  setSRFormViewState("EDIT");
                }}
                canCreateContinuation={
                  allowContinuations && !latestDeterminedSrOrDraft.clinicalService?.hasUnitsOnPxChanged
                }
                authorizationRefetch={authorizationRefetch}
                actionConfiguration={{
                  printActionConfiguration: {
                    disabled: true,
                    hidden: true,
                    noRender: true,
                  },
                }}
              />
              {latestDeterminedSrOrDraft.serviceRequestRelations?.map((relation) => (
                <ServiceRequestRelationDisplay
                  sourceServiceRequestId={latestDeterminedSrOrDraft.id}
                  serviceRequestRelation={relation}
                  patientId={latestDeterminedSrOrDraft.patient?.id}
                  key={relation.id}
                  className={patientSummaryStyles.relationDisplayContainer}
                  isOnPatientSummary
                />
              ))}
            </>
          ) : (
            <div>
              {latestDeterminedSrOrDraft.reconClaim && (
                <InfoAlert message="Claim">Claim number: {latestDeterminedSrOrDraft.reconClaim.claimId}</InfoAlert>
              )}
              <Tooltip
                title={
                  !isContactInfoModalOpen
                    ? "Access to this request is limited due to sensitive health information."
                    : ""
                }
                disableHoverListener={!serviceRequestIsRestricted}
                disableFocusListener={!serviceRequestIsRestricted}
                placement="top"
              >
                <Accordion
                  data-testid={id ? `${id}-accordion` : "authorization-summary-card-accordion"}
                  className={`${authCardStyles.authorizationCard} ${withHover}`}
                  classes={cardStyles}
                  expanded={isExpanded}
                  ref={scrollToClinicalAssessment ? undefined : ref}
                >
                  <div className={authCardStyles.authorizationForm}>
                    <AccordionSummary
                      classes={{
                        root: patientSummaryStyles.summaryRoot,
                        content: patientSummaryStyles.serviceRequestSummary,
                        expanded: patientSummaryStyles.expanded,
                      }}
                    >
                      {!serviceRequestIsRestricted && (
                        <div className={patientSummaryStyles.rowContainer}>
                          <div className={patientSummaryStyles.captionContainer}>
                            {hasServiceCategory && (
                              <Caption className={patientSummaryStyles.serviceCategoryCaption} data-public>
                                {serviceCategoryName || ""}
                              </Caption>
                            )}
                            <Tooltip
                              title={clinicalServicesText}
                              placement="top"
                              arrow
                              disableHoverListener={!hoverStatus}
                              classes={{ tooltip: patientSummaryStyles.customWidth }}
                            >
                              <div
                                className={
                                  hoverStatus
                                    ? `${patientSummaryStyles.styledBodyContainer} ${patientSummaryStyles.hover}`
                                    : patientSummaryStyles.styledBodyContainer
                                }
                                ref={textElementRef}
                              >
                                <H4 className={patientSummaryStyles.serviceName} data-public>
                                  {clinicalServicesText}
                                </H4>
                              </div>
                            </Tooltip>
                          </div>
                          {authorization.isCareRecommendationRequest && <CareCoordinationBadge />}
                          <div className={patientSummaryStyles.buttonContainer}>
                            <ServiceRequestPrint
                              authorization={authorization}
                              serviceRequest={latestDeterminedSrOrDraft}
                              onShowSummaryPrintout={onShowSummaryPrintout}
                              onShowCallLog={onShowCallLog}
                              showNotificationViewer={showNotificationViewer}
                            />
                            <TertiaryButton
                              className={patientSummaryStyles.expandToggleButton}
                              startIcon={
                                <ExpandMoreIcon
                                  className={
                                    isExpanded
                                      ? patientSummaryStyles.expandIconRotated
                                      : patientSummaryStyles.expandIconUnRotated
                                  }
                                />
                              }
                              // disabled={!onShowSummaryPrintout}
                              disabled={false}
                              onClick={() => setIsExpanded(!isExpanded)}
                            ></TertiaryButton>
                          </div>
                        </div>
                      )}
                      {authorization.clinicalService?.description && (
                        <Caption className={`${patientSummaryStyles.serviceDescription}${isExpanded ? "" : " hidden"}`}>
                          {authorization.clinicalService?.description}
                        </Caption>
                      )}
                      {latestSr?.isAdvanceCoverageDetermination && (
                        <Alert
                          severity="warning"
                          message="This request has been processed as an advanced coverage determination."
                          className={`${patientSummaryStyles.acdAlert}${isExpanded ? "" : " hidden"}`}
                        >
                          This is a Medicare Advantage private fee-for-service (PFFS) member.
                        </Alert>
                      )}
                      {latestSr?.authStatus === "DRAFT" && (
                        <ServiceRequestStatusDisplay
                          onDeleteDraft={onEdit}
                          onEdit={onEdit}
                          serviceRequest={latestSr}
                          openServiceRequestForEditing={() => {
                            setIsExpanded(true);
                            setSRFormViewState("EDIT");
                          }}
                          authorization={authorization}
                          authorizationRefetch={authorizationRefetch}
                          canCreateContinuation={allowContinuations && !latestSr.clinicalService?.hasUnitsOnPxChanged}
                          useServiceRequestAuthStatus
                          actionConfiguration={{
                            printActionConfiguration: {
                              disabled: true,
                              hidden: true,
                              noRender: true,
                            },
                            startContinuationActionConfiguration: {
                              disabled: Boolean(serviceRequestIsRestricted),
                              hidden: Boolean(serviceRequestIsRestricted),
                            },
                            draftContinueActionConfiguration: {
                              disabled: Boolean(serviceRequestIsRestricted),
                              hidden: Boolean(serviceRequestIsRestricted),
                            },
                            draftDeleteActionConfiguration: {
                              disabled: Boolean(serviceRequestIsRestricted),
                              hidden: Boolean(serviceRequestIsRestricted),
                            },
                            editStatusActionConfiguration: {
                              disabled: Boolean(serviceRequestIsRestricted),
                              hidden: Boolean(serviceRequestIsRestricted),
                            },
                          }}
                        />
                      )}
                      {(latestDeterminedSrOrDraft?.authStatus === "VOIDED" ||
                        latestDeterminedSrOrDraft?.authStatus === "WITHDRAWN") && (
                        <ServiceRequestStatusDisplay
                          onDeleteDraft={onEdit}
                          onEdit={onEdit}
                          serviceRequest={latestDeterminedSrOrDraft}
                          openServiceRequestForEditing={() => {
                            setIsExpanded(true);
                            setSRFormViewState("EDIT");
                          }}
                          authorization={authorization}
                          authorizationRefetch={authorizationRefetch}
                          canCreateContinuation={false}
                          actionConfiguration={{
                            printActionConfiguration: {
                              disabled: true,
                              hidden: true,
                              noRender: true,
                            },
                            startContinuationActionConfiguration: {
                              disabled: Boolean(serviceRequestIsRestricted),
                              hidden: Boolean(serviceRequestIsRestricted),
                            },
                            draftContinueActionConfiguration: {
                              disabled: Boolean(serviceRequestIsRestricted),
                              hidden: Boolean(serviceRequestIsRestricted),
                            },
                            draftDeleteActionConfiguration: {
                              disabled: Boolean(serviceRequestIsRestricted),
                              hidden: Boolean(serviceRequestIsRestricted),
                            },
                            editStatusActionConfiguration: {
                              disabled: Boolean(serviceRequestIsRestricted),
                              hidden: Boolean(serviceRequestIsRestricted),
                            },
                          }}
                        />
                      )}
                      {/* Important that this is margins s.t. there's no extra space when the draft banner is inactive */}
                      {latestDeterminedSr && latestDeterminedSr.authStatus !== "DRAFT" && (
                        <>
                          <Grid className={authCardStyles.marginSpacer} />
                          <ServiceRequestStatusDisplay
                            onDeleteDraft={onEdit}
                            onEdit={onEdit}
                            serviceRequest={latestDeterminedSr}
                            openServiceRequestForEditing={() => {
                              setIsExpanded(true);
                              setSRFormViewState("EDIT");
                            }}
                            authorization={authorization}
                            authorizationRefetch={authorizationRefetch}
                            canCreateContinuation={
                              allowContinuations && !latestDeterminedSr.clinicalService?.hasUnitsOnPxChanged
                            }
                            actionConfiguration={{
                              printActionConfiguration: {
                                disabled: true,
                                hidden: true,
                                noRender: true,
                              },
                              startContinuationActionConfiguration: {
                                disabled: Boolean(serviceRequestIsRestricted),
                                hidden: Boolean(serviceRequestIsRestricted),
                              },
                              draftContinueActionConfiguration: {
                                disabled: Boolean(serviceRequestIsRestricted),
                                hidden: Boolean(serviceRequestIsRestricted),
                              },
                              draftDeleteActionConfiguration: {
                                disabled: Boolean(serviceRequestIsRestricted),
                                hidden: Boolean(serviceRequestIsRestricted),
                              },
                              editStatusActionConfiguration: {
                                disabled: Boolean(serviceRequestIsRestricted),
                                hidden: Boolean(serviceRequestIsRestricted),
                              },
                            }}
                          />
                        </>
                      )}
                      {latestDeterminedSrOrDraft.serviceRequestRelations?.map((relation) => (
                        <ServiceRequestRelationDisplay
                          sourceServiceRequestId={latestDeterminedSrOrDraft.id}
                          serviceRequestRelation={relation}
                          patientId={latestDeterminedSrOrDraft.patient?.id}
                          key={relation.id}
                          className={patientSummaryStyles.relationDisplayContainer}
                          isOnPatientSummary
                        />
                      ))}
                    </AccordionSummary>
                    {serviceRequestIsViewable && initialSrCreatedByUser && (
                      <Grid item xs={12}>
                        <AuthVisibilityToggle
                          authorization={authorization}
                          renderedFromPatientSummary={true}
                          user={user}
                          visibilityToggleStatus={visibilityToggleStatus}
                          setVisibilityToggleStatus={setVisibilityToggleStatus}
                        />
                      </Grid>
                    )}
                    <AuthVisibilityMessage
                      serviceRequest={latestDeterminedSrOrDraft}
                      user={user}
                      isRestricted={serviceRequestIsRestricted}
                      isViewable={serviceRequestIsViewable}
                      visibilityToggleStatus={visibilityToggleStatus}
                      setIsContactInfoModalOpen={setIsContactInfoModalOpen}
                    />
                  </div>
                  <div className={authCardStyles.authorizationForm}>
                    <AccordionDetails>
                      {contentsVisible && (
                        <AuthorizationSummaryDetails
                          data-testid="authorizaton-summary-details"
                          authorization={authorization}
                          latestSr={latestDeterminedSrOrDraft}
                          onEdit={onEdit}
                          srFormViewState={srFormViewState}
                          setSRFormViewState={setSRFormViewState}
                          targetReviewId={targetReviewId}
                          setTargetReview={setTargetReview}
                          initialIsExpanded={initialIsExpanded}
                          clinicalAssessmentSectionRef={scrollToClinicalAssessment ? ref : undefined}
                          populateDefaults={false}
                          refreshAuthorization={getAuthorization}
                          adhocLetter={adhocLetter}
                          singleAttachmentViewerRedesign={singleAttachmentViewerRedesign}
                          {...otherProps}
                        />
                      )}
                    </AccordionDetails>
                  </div>
                  {isExpanded &&
                    !hideContinuations &&
                    sortedServiceRequest &&
                    sortedServiceRequest.length > 1 &&
                    sortedServiceRequest
                      .filter((sr) => (isAuthViewOnlyUser ? sr.authStatus !== "DRAFT" : true))
                      .map((sr) => (
                        <ExpandedServiceRequestCard
                          key={sr.id}
                          sr={sr}
                          continueDraft={() => {
                            sr.workflowStep && draftContinue(sr.workflowStep);
                          }}
                          priorSr={getPriorServiceRequest(sr, authorization)}
                          nextSr={getNextServiceRequest(sr, authorization)}
                          onEdit={() => {
                            onEdit?.();
                          }}
                          shouldShowWithdrawButton={withdrawSRId === sr.id}
                        />
                      ))}
                </Accordion>
              </Tooltip>
            </div>
          )}
        </>
      );
    } else {
      return <></>;
    }
  }
);

interface ServiceRequestPrintProps {
  serviceRequest: ServiceRequestResponse;
  authorization?: AuthorizationResponse;
  onShowSummaryPrintout?: (arg0: ServiceRequestResponse | AuthorizationResponse) => void;
  onShowCallLog?: (
    arg0: ServiceRequestResponse,
    arg1: VatNotification | null | undefined,
    arg2: string | undefined
  ) => void;
  showNotificationViewer?: boolean;
}

export function ServiceRequestPrint({
  authorization,
  serviceRequest,
  onShowSummaryPrintout,
  onShowCallLog,
  showNotificationViewer,
}: ServiceRequestPrintProps) {
  const [printButtonAnchorEl, setPrintButtonAnchorEl] = useState<HTMLElement>();
  const [generateCallLogPdf, setGenerateCallLogPdf] = useState<string | undefined>();
  const [vatNotification, setVatNotification] = useState<VatNotification[] | null>();
  const [popSubmenu, setPopSubmenu] = useState<boolean>(false);
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState<HTMLElement | null>();
  const [searchModalOpen, setSearchModalOpen] = useState<boolean>(false);
  const canViewFinalDeterminationLetter = useAuthorized("FINAL_DETERMINATION_LETTER_DISPLAY");
  const healthPlanName = (authorization ?? serviceRequest).healthPlanName ?? "";

  const handleClickPrintButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setPrintButtonAnchorEl(event.currentTarget);
    if (canViewFinalDeterminationLetter) {
      vatNotificationCall();
    }
  };
  const handleClosePrintMenu = () => {
    setPrintButtonAnchorEl(undefined);
  };

  const handleOpenSearchModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSearchModalOpen(true);
    handleClosePrintMenu();
    setPopSubmenu(false);
  };
  const handleServiceSummaryClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onShowSummaryPrintout?.(authorization ? authorization : serviceRequest);
    handleClosePrintMenu();
    setPopSubmenu(false);
  };
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: vatInfo,
    refetch: vatNotificationCall,
    error: vatInfoError,
  } = useGetVatNotification({
    queryParams: { serviceRequestId: `eq:${serviceRequest?.id}`, patientId: `eq:${serviceRequest?.patient?.id || ""}` },
    lazy: true,
  });
  const callDisposition = (vn?: VatNotification | null) => {
    if (
      vn?.interventionResult === "CallCompleted" &&
      (vn?.status === "AUTHENTICATED" || vn?.status === "INBOUND_AUTHENTICATED")
    ) {
      return "Member verified identity and received verbal notification of service request approval";
    } else if (vn?.status === "ANSWERING_MACHINE") {
      return "Answering machine message was left with verbal notification of service request approval";
    }
  };
  useEffect(() => {
    if (vatInfoError) {
      if (vatInfoError.status === 403) {
        enqueueSnackbar("You do not have permission to view Vat", { variant: "error" });
      } else {
        enqueueSnackbar(`There was an error retrieving vat information ${vatInfoError.message}`, {
          variant: "error",
        });
      }
    }
  }, [vatInfoError, enqueueSnackbar]);

  useEffect(() => {
    if (vatInfo) {
      const callDispositionMessage = callDisposition(vatInfo.find((vn) => vn.successful));
      setGenerateCallLogPdf(callDispositionMessage);
      setVatNotification(vatInfo);
    }
  }, [vatInfo]);

  return (
    <>
      <AuthorizationSummaryDropDownMenu
        handlePrintClick={(e) => {
          e.stopPropagation();
          setPopSubmenu(true);
          setSubmenuAnchorEl(e.currentTarget);
        }}
        handleClosePrintMenu={handleClosePrintMenu}
        printButtonAnchorEl={printButtonAnchorEl}
        handleCloseSubmenu={(e: React.MouseEvent) => {
          e.stopPropagation();
          setPopSubmenu(false);
          setSubmenuAnchorEl(null);
        }}
        popoverOpen={popSubmenu}
        submenuAnchorEl={submenuAnchorEl}
        generateCallLogPdf={generateCallLogPdf}
        vatNotification={vatNotification}
        serviceRequest={serviceRequest}
        handleServiceSummaryClick={handleServiceSummaryClick}
        handleClickPrintButton={handleClickPrintButton}
        printButtonDisabled={!onShowCallLog}
        searchModalOpen={searchModalOpen}
        handleOpenSearchModal={handleOpenSearchModal}
        handleCloseSearchModal={() => setSearchModalOpen(false)}
        authorization={authorization}
        showNotificationViewer={showNotificationViewer}
        onShowCallLog={onShowCallLog}
      />
      <PatientSearchModal
        healthPlanName={healthPlanName}
        open={searchModalOpen}
        onClose={() => setSearchModalOpen(false)}
        authorizationTransfer={{
          authorizationId: authorization?.id,
          displayAuthorizationTransfer: true,
        }}
      />
    </>
  );
}

export default AuthorizationSummaryCard;
