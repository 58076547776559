import { useGetAllHistoricPatientStayDates } from "@coherehealth/core-platform-api";
import { useFeature } from "@coherehealth/common";

export default function useFetchPatientStayDates(authorizationId?: string) {
  const expandedReviewStayDatesEdits = useFeature("expandedReviewStayDatesEdits");

  const { data: patientStayDatesHistory } = useGetAllHistoricPatientStayDates({
    authorizationId: authorizationId || "",
    lazy: !authorizationId || !expandedReviewStayDatesEdits,
  });

  return {
    patientStayDatesHistory: patientStayDatesHistory || [],
    expandedReviewStayDatesEdits: expandedReviewStayDatesEdits || false,
  };
}
