import Footer from "../Footer";
import { ServiceDeterminationProcedureCodeBucket } from "@coherehealth/core-platform-api";
import { ContinueToFillFormsFlexProps, useContinueToFillFormsFlex } from "../common";
import { useFeature } from "@coherehealth/common";
import { Dispatch, SetStateAction } from "react";

interface Props extends Omit<ContinueToFillFormsFlexProps, "simplifiedServiceFrequency"> {
  buckets: ServiceDeterminationProcedureCodeBucket[];
  serviceDeterminationLoading: boolean;
  setFooterHeight: Dispatch<SetStateAction<number>>;
  facilityBasedFeatureEnabled?: boolean;
}

export default function ServiceSelectionFlexibleCodeFooter({
  buckets,
  serviceDeterminationLoading,
  priorAuthRequirements,
  setPriorAuthRequirements,
  palProcedureCodes,
  setServiceRequestFormContents,
  setServiceRequestFormsCanBeSubmitted,
  setServiceRequestFormsHaveNewEdits,
  setWorkflowStep,
  patient,
  mostRecentlyUsedFaxNumber,
  importedEhrOrder,
  suggestedFormContent,
  setFooterHeight,
  facilityBasedFeatureEnabled,
}: Props) {
  const simplifiedServiceFrequency = useFeature("simplifiedServiceFrequency");

  const continueToFillFormsFlex = useContinueToFillFormsFlex({
    priorAuthRequirements,
    setPriorAuthRequirements,
    palProcedureCodes,
    setServiceRequestFormContents,
    setServiceRequestFormsCanBeSubmitted,
    setServiceRequestFormsHaveNewEdits,
    setWorkflowStep,
    patient,
    mostRecentlyUsedFaxNumber,
    simplifiedServiceFrequency,
    importedEhrOrder,
    suggestedFormContent,
    facilityBasedFeatureEnabled,
  });

  return (
    <Footer
      onPrimaryButtonClick={() => continueToFillFormsFlex(buckets)}
      primaryButtonDisabled={buckets.length === 0 || serviceDeterminationLoading}
      primaryButtonLoading={serviceDeterminationLoading}
      primaryButtonText="Continue"
      showSaveAndExitButton={false}
      setFooterHeight={setFooterHeight}
    />
  );
}
