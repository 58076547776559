import {
  InformativeModal,
  InlineButton,
  formatDateToISODate,
  useFeature,
  useGeneralAuthSubmissionWorflowOn,
  useConfiguration,
} from "@coherehealth/common";
import { makeStyles, useTheme } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { FaxAttachmentContext } from "components/DocumentViewer/FaxAttachment/FaxAttachmentContext";
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";
import PotentialDuplicate from "./PotentialDuplicate";
import { SuggestionContext } from "../SuggestionContext";
import { useGetOtherInsuranceConfigurationByPayer } from "hooks/useGetFeatureConfigurations";
import { PotentialDuplicateContextProvider } from "./PotentialDuplicateContextProvider";
import {
  Attachment,
  AuthBuilderWorkflowStep,
  AuthorizationCreatePayload,
  ClinicalService,
  Patient,
  PossibleContinuationsAuthorizationResponse,
  ProcedureCode,
  ServiceDeterminationProcedureCodeBucket,
  ServiceDeterminationResponse,
  SimilarServiceRequest,
  ServiceRequestCreatePayload,
  ServiceRequestResponse,
  useCreateAuthorization,
  useCreateServiceRequestAttachment,
  useDeleteServiceRequest,
  useDeleteServiceRequestAttachment,
  useGetCarePath,
  useGetClinicalServiceDetermination,
  useGetPotentialDuplicatesForServiceRequest,
  useGetPossibleContinuations,
  useGetPossibleContinuationSRs,
  useGetServiceRequests,
  UserResponse,
  useUpdateAuthorizationById,
  useUpdateServiceRequest,
  useGetEpisodicAuths,
} from "@coherehealth/core-platform-api";
import { useSnackbar } from "notistack";
import { constructProcedureCodes } from "util/clinicalAssessment";
import { ServiceRequestFormContent } from "../../ServiceRequest";
import Footer from "../Footer";
import {
  PriorAuthRequirements,
  ServiceRequestFormStateSetters,
  formContentFromAuthBuilderContext,
  NonCohereCodes,
  useContinueToFillFormsFlex,
  saveHomeHealthToStorage,
  servRequestFailedSnackbar,
  navigateToPS,
  navigateToSRSummary,
} from "../common";
import CheckExistingAuthorization from "./ExistingAuthorization";
import QuestionModal from "./QuestionModal";
import { useGetReturnToUrl } from "util/queryParams";
import { Error as ErrorIcon, OpenInNew } from "@material-ui/icons";
import { getPatientHealthPlanName, checkOnetimeRequestCoverage } from "util/patientUtils";
import useGetFacilityBasedRequestConfigurationByPayer from "hooks/useGetFeatureConfigurations";
import { useNavigate } from "react-router-dom";
import { formContentFromResponse, payloadFromSRFormContent } from "util/serviceRequest";
import { error as logError } from "logger";
import { SmartOnFhirContext } from "components/SmartOnFhir/SmartOnFhirSecurityProvider";
import {
  createOptionsFromBuckets,
  initializeSelectionsFromOptions,
} from "../ServiceSelectionFlexibleIntake/ServiceSelectionFlexibleIntake";
import { useTrackUserInteraction } from "util/userActivityTracker";
import EpisodicAuthsModal from "common/EpisodicAuthsModal";

interface Props extends ServiceRequestFormStateSetters {
  patient?: Patient;
  palProcedureCodes: ProcedureCode[];
  nonPalProcedureCodes: ProcedureCode[];
  noPxService: ClinicalService | undefined;
  priorAuthRequirements: PriorAuthRequirements;
  setPriorAuthRequirements: Dispatch<SetStateAction<PriorAuthRequirements>>;
  mostRecentlyUsedFaxNumber?: string;
  buckets: ServiceDeterminationProcedureCodeBucket[];
  setHasDuplicates: Dispatch<SetStateAction<boolean>>;
  setBuckets: Dispatch<ServiceDeterminationProcedureCodeBucket[]>;
  primaryCarePathId?: string;
  setPrimaryCarePathId: Dispatch<string | undefined>;
  setWorkflowStep: (step: AuthBuilderWorkflowStep) => void;
  fetchServiceRequests: ReturnType<typeof useGetServiceRequests>["refetch"];
  loading: boolean;
  fetchCarePath: ReturnType<typeof useGetCarePath>["refetch"];
  workflowId: string;
  importedEhrOrder?: ServiceRequestResponse;
  serviceRequestFormContents: ServiceRequestFormContent[];
  setNonPalProcedureCodes: Dispatch<SetStateAction<ProcedureCode[]>>;
  setNonCohereProcedureCodes: Dispatch<SetStateAction<NonCohereCodes[]>>;
  setServiceDeterminationResponse: Dispatch<ServiceDeterminationResponse>;
  setOpenNonPalConfirmationModal?: Dispatch<SetStateAction<boolean>>;
  openNonPalConfirmationModal?: boolean;
  setUserSelectedNonPalCode?: (value: boolean) => void;
  userUpdatedTat?: boolean;
  serviceRequest?: ServiceRequestResponse;
  editServiceRequest: Dispatch<ServiceRequestResponse>;
  addServiceRequest: (serviceRequest: ServiceRequestResponse, newDraftSrId?: string) => void;
  authorizationId?: string;
  attachments?: Attachment[];
  userResponse?: UserResponse;
  selectedClinicalServiceIds: Record<string, string[]>;
  selectedCarePathId: string | undefined;
  setDraftSrId: Dispatch<SetStateAction<string>>;
  setFooterHeight: Dispatch<SetStateAction<number>>;
}

export type QuestionOptions = Omit<ServiceDeterminationResponse, "buckets">;

export type QuestionAnswers = {
  carePathAnswer?: string;
  categoryAnswer?: string;
  serviceAnswer?: string;
  serviceTypeAnswer?: string;
  placeOfServiceAnswer?: string;
  primaryDiagnosisCode?: string;
};

export default function GatherRequirementsFooter({
  patient,
  palProcedureCodes,
  nonPalProcedureCodes,
  noPxService,
  priorAuthRequirements,
  setPriorAuthRequirements,
  mostRecentlyUsedFaxNumber,
  buckets,
  setHasDuplicates,
  setBuckets,
  primaryCarePathId,
  setPrimaryCarePathId,
  setWorkflowStep,
  fetchServiceRequests,
  fetchCarePath,
  loading,
  setServiceRequestFormContents,
  setServiceRequestFormsCanBeSubmitted,
  setServiceRequestFormsHaveNewEdits,
  workflowId,
  importedEhrOrder,
  serviceRequestFormContents,
  setNonPalProcedureCodes,
  setNonCohereProcedureCodes,
  setServiceDeterminationResponse,
  setOpenNonPalConfirmationModal,
  openNonPalConfirmationModal,
  setUserSelectedNonPalCode,
  userUpdatedTat,
  serviceRequest,
  editServiceRequest,
  addServiceRequest,
  attachments,
  userResponse,
  selectedClinicalServiceIds,
  selectedCarePathId,
  setDraftSrId,
  setFooterHeight,
}: Props) {
  const multiSingleService = useFeature("multiSingleService");
  const simplifiedServiceFrequency = useFeature("simplifiedServiceFrequency");
  const createEpisodicAuths = useFeature("episodicAuth");
  const healthPlanName = getPatientHealthPlanName(patient) || "";
  const { facilityBasedFeatureEnabled } = useGetFacilityBasedRequestConfigurationByPayer({
    healthPlanName,
    encounterType: priorAuthRequirements.encounterType,
    skipRequestTimingCheck: true,
    priorAuthRequirements,
    srContent: serviceRequest,
  });
  const generalAuthSubmissionWorkflowEnabled = useGeneralAuthSubmissionWorflowOn(healthPlanName);
  const { enqueueSnackbar } = useSnackbar();
  const { palette } = useTheme();

  const [questionOptions, setQuestionOptions] = useState<QuestionOptions>();
  const [questionAnswers, setQuestionAnswers] = useState<QuestionAnswers>();
  const [defaultPlaceOfServiceId, setDefaultPlaceOfServiceId] = useState<string>();

  const [questionModalOpen, setQuestionModalOpen] = useState(false);
  const [checkExistingAuthModalOpen, setCheckExistingAuthModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  const [continuationsData, setContinuationsData] = useState<ServiceRequestResponse[]>([]);
  const navigate = useNavigate();

  const {
    mutate: createNewServiceRequestOnAuth,
    loading: createNewServiceRequestOnAuthLoading,
    error: createNewServiceRequestOnAuthError,
  } = useCreateAuthorization({});

  const { mutate: deleteServiceRequest, error: deleteError } = useDeleteServiceRequest({});

  const {
    mutate: deleteAttachment,
    loading: deleteAttachmentLoading,
    error: deleteAttachmentError,
  } = useDeleteServiceRequestAttachment({
    id: "",
  });

  const {
    mutate: updateServiceRequest,
    loading: updateServiceRequestLoading,
    error: updateServiceRequestError,
  } = useUpdateServiceRequest({ id: "" });

  const { mutate: uploadSrAttachment } = useCreateServiceRequestAttachment({ id: "" });

  const { mutate: patchAuthorization } = useUpdateAuthorizationById({ id: "" });

  useEffect(() => {
    if (deleteError || deleteAttachmentError) {
      enqueueSnackbar(
        `Error canceling authorization request: ${deleteError?.message || deleteAttachmentError?.message || ""}`,
        { variant: "error" }
      );
    }
  }, [enqueueSnackbar, deleteError, deleteAttachmentError]);

  useEffect(() => {
    if (createNewServiceRequestOnAuthError) {
      servRequestFailedSnackbar(enqueueSnackbar);
    }
  }, [createNewServiceRequestOnAuthError, enqueueSnackbar]);

  useEffect(() => {
    if (updateServiceRequestError) {
      servRequestFailedSnackbar(enqueueSnackbar);
    }
  }, [updateServiceRequestError, enqueueSnackbar]);

  const startDateCoverage = useMemo(
    () =>
      patient?.coverages && priorAuthRequirements.startDate
        ? checkOnetimeRequestCoverage(patient.coverages, priorAuthRequirements.startDate)
        : undefined,
    [patient?.coverages, priorAuthRequirements.startDate]
  );

  const saveAndExitDisabled = useMemo(() => {
    return (
      !!!priorAuthRequirements.primaryDiagnosis &&
      !!!priorAuthRequirements.startDate &&
      (priorAuthRequirements.secondaryDiagnoses || []).length === 0 &&
      (priorAuthRequirements.desiredProcedureCodes || []).length === 0 &&
      !!!priorAuthRequirements.authCategory
    );
  }, [
    priorAuthRequirements.authCategory,
    priorAuthRequirements.desiredProcedureCodes,
    priorAuthRequirements.primaryDiagnosis,
    priorAuthRequirements.secondaryDiagnoses,
    priorAuthRequirements.startDate,
  ]);

  const onQuestionModalClose = () => {
    setQuestionModalOpen(false);
    setQuestionOptions(undefined);
    setQuestionAnswers(undefined);
  };
  const checkExistingAuthModalClose = () => {
    setCheckExistingAuthModalOpen(false);
  };

  const {
    mutate: getClinicalServiceDetermination,
    loading: serviceDeterminationLoading,
    error: serviceDeterminationError,
  } = useGetClinicalServiceDetermination({});

  const stringifiedPxCodes = priorAuthRequirements?.desiredProcedureCodes?.map((pxCode) => pxCode.code).join(",");

  const {
    data: getContinuationsData,
    loading: getPossibleContinuationsLoading,
    refetch: fetchGetPossibleContinuations,
  } = useGetPossibleContinuations({
    queryParams: {
      patient: `eq:${patient?.id}`,
      procedureCodes: stringifiedPxCodes || "",
      diagnosisCode: priorAuthRequirements?.primaryDiagnosis?.code || "",
      authStatus: "ne:PENDING",
      endDate: `ge:${formatDateToISODate(new Date())}`,
    },
    lazy: true,
  });

  const {
    data: getContinuationSRData,
    loading: getPossibleContinuationSRLoading,
    refetch: fetchGetPossibleContinuationsSR,
  } = useGetPossibleContinuationSRs({
    queryParams: {
      patient: `eq:${patient?.id}`,
      procedureCodes: stringifiedPxCodes || "",
      diagnosisCode: priorAuthRequirements?.primaryDiagnosis?.code || "",
      authStatus: "ne:PENDING",
      endDate: `ge:${formatDateToISODate(new Date())}`,
    },
    lazy: true,
  });

  const { mutate: getPotentialDuplicates, loading: getPotentialDuplicatesLoading } =
    useGetPotentialDuplicatesForServiceRequest({});
  const isDuplicateAuthsFeatureOn = useFeature("duplicateAuths");
  const [duplicateAuthsModalOpen, setDuplicateAuthsModalOpen] = useState(false);
  const [duplicateAuthData, setDuplicateAuthData] = useState<SimilarServiceRequest[] | null>(null);

  // Episodic auth data fetch and values needed to drive episodic auth functionality.
  const {
    refetch: getEpisodicServiceRequestsData,
    loading: getEpisodicServiceRequestsLoading,
    error: episodicAuthServiceRequestsError,
  } = useGetEpisodicAuths({
    queryParams: {
      patient: patient?.id || "",
      procedureCodes: stringifiedPxCodes || "",
      startDate: formatDateToISODate(priorAuthRequirements.startDate) || null,
      encounterType: priorAuthRequirements.encounterType || "",
    },
    lazy: true,
  });

  const [episodicSRModal, setEpisodicSRModal] = useState<boolean>(false);
  const [episodicAuthData, setEpisodicAuthData] = useState<ServiceRequestResponse[]>([]);
  const [hasEpisodicAuthTriggered, setHasEpisodicAuthTriggered] = useState<boolean>(false);
  const loadingEpiSRs = getEpisodicServiceRequestsLoading;

  useEffect(() => {
    if (episodicAuthServiceRequestsError) {
      enqueueSnackbar("Failed to get services, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [episodicAuthServiceRequestsError, enqueueSnackbar]);

  /**
   * Search for potential duplicates.
   * If duplicates are found they will be put into the `duplicateAuthData` state and `true` will be returned.
   * If duplicates are not found the modal will be closed and `false` will be returned.
   *
   * @param srPayload The creation payload for the SR draft, should have startDate, procedureCodes, etc.
   * @return True if duplicates are found, false otherwise
   */
  const searchForPotentialDuplicates = useCallback(
    async (
      srPayload: ServiceRequestCreatePayload,
      serviceRequestFormContents?: ServiceRequestFormContent
    ): Promise<boolean> => {
      const potentialDuplicateResponse = await getPotentialDuplicates(srPayload);
      const filterRecentDraftFromPotentialDuplicate = potentialDuplicateResponse.filter((response) => {
        return serviceRequestFormContents?.id !== response?.serviceRequest?.id;
      });
      // todo should we filter out potential duplicates with low match score?
      if (!filterRecentDraftFromPotentialDuplicate.length) {
        // No potential duplicates found, close the modal
        setDuplicateAuthsModalOpen(false);
        setDuplicateAuthData(null);
        return false;
      } else {
        // Found duplicates, keep modal open and set the duplicateAuthData state
        setDuplicateAuthsModalOpen(true);
        setHasDuplicates(true);
        setDuplicateAuthData(filterRecentDraftFromPotentialDuplicate);
        return true;
      }
    },
    [getPotentialDuplicates, setHasDuplicates]
  );
  const loadingContinuations =
    getPossibleContinuationsLoading || getPossibleContinuationSRLoading || getPotentialDuplicatesLoading;

  useEffect(() => {
    if (serviceDeterminationError) {
      enqueueSnackbar("Failed to get services, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [serviceDeterminationError, enqueueSnackbar]);

  const getLatestValidContinuation = useCallback(
    (continuationsData: PossibleContinuationsAuthorizationResponse[]): ServiceRequestResponse[] => {
      const serviceRequests: ServiceRequestResponse[] = [] as unknown as ServiceRequestResponse[];
      /* Return latest service request continuation if exists to the list of possible continuations */
      continuationsData.forEach((continuations) => {
        if (continuations && continuations.serviceRequests && continuations.serviceRequests.length) {
          const sortedSRs = getSortedServiceRequests(continuations.serviceRequests);
          const latestSR = sortedSRs.at(-1);
          if (latestSR && !latestSR?.clinicalService?.hasUnitsOnPxChanged) {
            serviceRequests.push(latestSR);
          }
        }
      });
      return serviceRequests;
    },
    []
  );

  useEffect(() => {
    let serviceRequests: ServiceRequestResponse[];
    if (
      !getPossibleContinuationsLoading &&
      getContinuationsData &&
      !getPossibleContinuationSRLoading &&
      getContinuationSRData &&
      (getContinuationsData.length || getContinuationSRData.length)
    ) {
      serviceRequests = getLatestValidContinuation(getContinuationsData);
      setContinuationsData([...serviceRequests, ...getContinuationSRData]);
    }
  }, [
    getPossibleContinuationsLoading,
    getContinuationsData,
    getPossibleContinuationSRLoading,
    getContinuationSRData,
    getLatestValidContinuation,
  ]);

  useEffect(() => {
    setCheckExistingAuthModalOpen(continuationsData.length > 0);
  }, [continuationsData]);

  //facilityBased continue logic
  const hasSubCategories = !!priorAuthRequirements.authCategory?.subcategories?.length;
  const authSubcategory = priorAuthRequirements.authSubcategory;
  const checkAuthSubCategory = hasSubCategories ? !!authSubcategory : true;
  const currProcedureCodeCount = priorAuthRequirements?.desiredProcedureCodes?.length || 0;
  const facilityBasedCanContinue = facilityBasedFeatureEnabled
    ? !!priorAuthRequirements.authCategory && checkAuthSubCategory
    : currProcedureCodeCount > 0;
  const { otherInsuranceEnabled } = useGetOtherInsuranceConfigurationByPayer({
    healthPlanName: healthPlanName ?? "",
  });
  const isAdditionalInsuranceTextValid =
    !otherInsuranceEnabled || !!((priorAuthRequirements.additionalInsurance?.additionalInsuranceText || "") !== "");

  const numOfProcedureCodes = priorAuthRequirements?.desiredProcedureCodes?.length || 0;
  const footerContinueText = useMemo(() => {
    const palProcedureCodesLen = palProcedureCodes.length || 0;
    if (priorAuthRequirements.noPxService || palProcedureCodesLen <= 0) {
      return "Continue";
    } else {
      return `Continue with ${numOfProcedureCodes} procedure code${numOfProcedureCodes > 1 ? "s" : ""}`;
    }
  }, [numOfProcedureCodes, palProcedureCodes.length, priorAuthRequirements.noPxService]);

  const resultsLoading = (serviceDeterminationLoading || loading) && !questionModalOpen;
  const { faxDbId: defaultFaxId } = useContext(FaxAttachmentContext);
  const { suggestedFormContent } = useContext(SuggestionContext);
  const { blockUserIfLessThanRecommendedEndDate } =
    useConfiguration("patientStayRangesConfiguration", healthPlanName) ?? {};

  const continueToFillFormsFlex = useContinueToFillFormsFlex({
    priorAuthRequirements,
    setPriorAuthRequirements,
    palProcedureCodes,
    setServiceRequestFormContents,
    setServiceRequestFormsCanBeSubmitted,
    setServiceRequestFormsHaveNewEdits,
    setWorkflowStep,
    patient,
    mostRecentlyUsedFaxNumber,
    simplifiedServiceFrequency,
    importedEhrOrder,
    suggestedFormContent,
    facilityBasedFeatureEnabled,
  });

  const updateResponseWithSelectedClinicalServiceIds = async (response: ServiceDeterminationResponse) => {
    const basePayload = {
      patientId: patient?.id,
      encounterType: priorAuthRequirements.encounterType,
      authCategory: priorAuthRequirements.authCategory?.enumName,
      semanticProcedureCodes: [
        ...(priorAuthRequirements?.desiredProcedureCodes ? priorAuthRequirements?.desiredProcedureCodes : []),
      ],
      nonPalSemanticProcedureCodes: [],
      primarySemanticDiagnosisCode: priorAuthRequirements?.primaryDiagnosis,
      secondarySemanticDiagnosisCodes: priorAuthRequirements?.secondaryDiagnoses || [],
      serviceStartDate: formatDateToISODate(priorAuthRequirements.startDate),
      groupProcedureCodes: true,
    };
    let selectedCPId = response?.carePathOptions?.find((cp) => cp?.id === selectedCarePathId)
      ? selectedCarePathId
      : undefined;
    let carePathResponse;
    if (selectedCPId) {
      carePathResponse = await getClinicalServiceDetermination({
        ...basePayload,
        selectedCarePathId: selectedCPId,
      });
    }
    if (selectedClinicalServiceIds && (Object.keys(selectedClinicalServiceIds) as Array<string>).length > 0) {
      const procedureCodeGroupSelections = initializeSelectionsFromOptions(selectedClinicalServiceIds, [
        ...(carePathResponse?.procedureCodeGroupOptions || response?.procedureCodeGroupOptions || []),
        ...createOptionsFromBuckets(response?.buckets),
      ]);
      const updatedResponse = await getClinicalServiceDetermination({
        ...basePayload,
        procedureCodeGroupSelections,
        selectedCarePathId: selectedCPId,
      });
      if (
        updatedResponse.procedureCodeGroupOptions === undefined &&
        updatedResponse.buckets &&
        updatedResponse.buckets.length > 0
      ) {
        setPrimaryCarePathId(primaryCarePathId || updatedResponse.buckets[0].carePath?.id);
        setBuckets(updatedResponse.buckets);
      }
      if (selectedCPId && carePathResponse) {
        setServiceDeterminationResponse({ carePathOptions: response?.carePathOptions, ...carePathResponse });
      }
    }
  };

  const continueToServiceSelectionOrFillForms = async (
    buckets: ServiceDeterminationProcedureCodeBucket[],
    carePathId?: string
  ) => {
    if (carePathId && !generalAuthSubmissionWorkflowEnabled) {
      await fetchServiceRequests();
      await fetchCarePath({ pathParams: { carePathId } });
      saveHomeHealthToStorage(priorAuthRequirements);
      setWorkflowStep("SELECT_SERVICE");
    }
    //if there is no care path found, we treat this as a single service and skip the selection page
    else {
      let updatedServiceRequestForm: ServiceRequestFormContent[] = [];
      const isInpatient = priorAuthRequirements.encounterType === "INPATIENT";
      if (multiSingleService && (!defaultFaxId || buckets.length > 0)) {
        buckets.forEach((bucket, bucketIndex) => {
          const desiredProcedureCodes =
            bucket && bucket.procedureCodes
              ? bucket.procedureCodes
              : isInpatient
              ? priorAuthRequirements.desiredProcedureCodes || []
              : palProcedureCodes;
          /* Preprocessing procedure code list details */
          const desiredServiceLevelUnits =
            bucket && bucket.clinicalService?.defaultUnits ? `${bucket.clinicalService?.defaultUnits}` : "1";

          /* Construct procedure code list */
          const procedureCodes = constructProcedureCodes({
            procedureCodes: desiredProcedureCodes,
            isInpatient: isInpatient,
            serviceLevelUnits: desiredServiceLevelUnits,
            clinicalService: bucket?.clinicalService,
            isUnitsOnPx: true,
          });

          const currentUpdatedServiceRequestForm = {
            ...serviceRequestFormContents[bucketIndex], // This ensures that the previous serviceRequestFormContents are retained and updated with new data
            ...formContentFromAuthBuilderContext({
              priorAuthRequirements,
              patient,
              mostRecentlyUsedFaxNumber,
              simplifiedServiceFrequency,
              ...(bucket || {}),
              procedureCodes,
              importedEhrOrder,
              suggestedFormContent,
              facilityBasedFeatureEnabled,
              useDefaultDurationAsMinimumEndDate: !!blockUserIfLessThanRecommendedEndDate,
            }),
          };
          updatedServiceRequestForm.push(currentUpdatedServiceRequestForm);
        });
      } else {
        const bucket = buckets.find((bucket) => bucket.clinicalService?.isSingleService || !bucket.clinicalService);
        /* Preprocessing procedure code list details */
        const desiredProcedureCodes =
          bucket && bucket.procedureCodes
            ? bucket.procedureCodes
            : isInpatient
            ? priorAuthRequirements.desiredProcedureCodes || []
            : palProcedureCodes;
        const desiredServiceLevelUnits =
          bucket && bucket.clinicalService?.defaultUnits ? `${bucket.clinicalService?.defaultUnits}` : "1";
        /* Construct procedure code list */
        const procedureCodes = constructProcedureCodes({
          procedureCodes: desiredProcedureCodes,
          isInpatient: isInpatient,
          serviceLevelUnits: desiredServiceLevelUnits,
          clinicalService: bucket?.clinicalService,
          isUnitsOnPx: true,
        });
        const currentUpdatedServiceRequestForm = {
          ...serviceRequestFormContents[0], // This ensures that the previous serviceRequestFormContents are retained and updated with new data
          ...formContentFromAuthBuilderContext({
            priorAuthRequirements,
            patient,
            mostRecentlyUsedFaxNumber,
            simplifiedServiceFrequency,
            ...(bucket || {}),
            procedureCodes,
            importedEhrOrder,
            suggestedFormContent,
            facilityBasedFeatureEnabled,
            useDefaultDurationAsMinimumEndDate: !!blockUserIfLessThanRecommendedEndDate,
          }),
        };
        updatedServiceRequestForm.push(currentUpdatedServiceRequestForm);
      }

      setServiceRequestFormContents(updatedServiceRequestForm);
      setServiceRequestFormsCanBeSubmitted([false]);
      setServiceRequestFormsHaveNewEdits([true]);
      saveHomeHealthToStorage(priorAuthRequirements);
      setWorkflowStep("GATHER_REQUIREMENTS_MEDICAL");
    }
    onQuestionModalClose();
  };

  const getServiceDeterminationFlexIntake = async (serviceCategoryName?: string, serviceAnswer?: string) => {
    if (patient?.id) {
      getClinicalServiceDetermination({
        patientId: patient?.id,
        encounterType: priorAuthRequirements.encounterType,
        authCategory: priorAuthRequirements.authCategory?.enumName,
        semanticProcedureCodes: [
          ...(priorAuthRequirements?.desiredProcedureCodes ? priorAuthRequirements?.desiredProcedureCodes : []),
        ],
        nonPalSemanticProcedureCodes: [],
        primarySemanticDiagnosisCode: priorAuthRequirements?.primaryDiagnosis,
        secondarySemanticDiagnosisCodes: priorAuthRequirements?.secondaryDiagnoses || [],
        serviceStartDate: formatDateToISODate(priorAuthRequirements.startDate),
        selectedCarePathId: questionAnswers?.carePathAnswer,
        selectedServiceId: serviceAnswer,
        selectedCategoryName: serviceCategoryName,
        selectedServiceType: questionAnswers?.serviceTypeAnswer,
        selectedPlaceOfServiceId: questionAnswers?.placeOfServiceAnswer,
        groupProcedureCodes: true,
      }).then(async (response) => {
        //if we get buckets in the response then we have succeeded in mapping the inputs to services, and can move on
        if (response.procedureCodeGroupOptions === undefined && response.buckets && response.buckets.length > 0) {
          setPrimaryCarePathId(primaryCarePathId || response.buckets[0].carePath?.id);
          setBuckets(response.buckets);
          setServiceDeterminationResponse(response);

          //general intake workflow: question modal is never shown, it goes direcly to fill forms
          await continueToFillFormsFlex(response.buckets);
        } else {
          //continue to new general code intake select services page
          setServiceDeterminationResponse(response);
          await updateResponseWithSelectedClinicalServiceIds(response);
          (priorAuthRequirements?.desiredProcedureCodes && priorAuthRequirements.desiredProcedureCodes?.length > 0) ||
          (response?.procedureCodeGroupOptions && response.procedureCodeGroupOptions?.length > 0)
            ? setWorkflowStep("SELECT_SERVICES")
            : setWorkflowStep("PROVIDER_DETAILS");
        }
      });
    } else {
      enqueueSnackbar("Cannot find patient information", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  };

  //ignore this function; deprecated
  const getServiceDeterminationResults = async () => {
    if (patient?.id) {
      getClinicalServiceDetermination({
        patientId: patient?.id,
        encounterType: priorAuthRequirements.encounterType,
        semanticProcedureCodes: palProcedureCodes,
        nonPalSemanticProcedureCodes: nonPalProcedureCodes,
        primarySemanticDiagnosisCode: priorAuthRequirements?.primaryDiagnosis,
        secondarySemanticDiagnosisCodes: priorAuthRequirements?.secondaryDiagnoses || [],
        serviceStartDate: formatDateToISODate(priorAuthRequirements.startDate),
        selectedCarePathId: questionAnswers?.carePathAnswer,
        selectedServiceId: questionAnswers?.serviceAnswer || noPxService?.id,
        selectedCategoryName: questionAnswers?.categoryAnswer,
        selectedServiceType: questionAnswers?.serviceTypeAnswer,
        selectedPlaceOfServiceId: questionAnswers?.placeOfServiceAnswer,
      }).then(async (response) => {
        //if we get buckets in the response then we have succeeded in mapping the inputs to services, and can move on
        if (response.buckets && response.buckets.length > 0) {
          setPrimaryCarePathId(primaryCarePathId || response.buckets[0].carePath?.id);
          setBuckets(response.buckets);
          //if the question modal is not open and we get back buckets, we can continue straight to the next page
          if (!questionModalOpen) {
            continueToServiceSelectionOrFillForms(
              response.buckets,
              primaryCarePathId || response.buckets[0].carePath?.id
            );
          }
        } else {
          //user is presented a list of place of service options to choose from
          if ((response.placeOfServiceOptions?.length || 0) > 0) {
            setQuestionOptions({ ...questionOptions, placeOfServiceOptions: response.placeOfServiceOptions });
            if (response.defaultPlaceOfService) {
              setDefaultPlaceOfServiceId(response.defaultPlaceOfService?.id);
              if (
                priorAuthRequirements?.encounterType &&
                response.defaultPlaceOfService?.encounterTypes?.includes(priorAuthRequirements.encounterType)
              ) {
                //only preset the dropdown answer if the default POS encounter types array includes the selected encounter type
                setQuestionAnswers({ ...questionAnswers, placeOfServiceAnswer: response.defaultPlaceOfService?.id });
              }
            }
          }
          //user is presented a list of service type options to choose from
          if ((response.serviceTypeOptions?.length || 0) > 0) {
            setQuestionOptions({ ...questionOptions, serviceTypeOptions: response.serviceTypeOptions });
          }
          //user is presented a list of services to choose from
          if (response.serviceOptions && response.serviceOptions.length > 0) {
            setQuestionOptions({ ...questionOptions, serviceOptions: response.serviceOptions });
          }
          //user is presented a list of service categories to choose from
          if (response.categoryOptions && response.categoryOptions.length > 0) {
            setQuestionOptions({
              ...questionOptions,
              categoryOptions: response.categoryOptions,
              serviceOptions: undefined,
            });
          }
          //user is presented a list of care paths to choose from
          if (response.carePathOptions && response.carePathOptions.length > 0) {
            setQuestionOptions({
              carePathOptions: response.carePathOptions,
              categoryOptions: undefined,
              serviceOptions: undefined,
            });
          }

          setQuestionModalOpen(true);
        }
      });
    } else {
      enqueueSnackbar("Cannot find patient information", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  };

  const continueGeneralAuthSubmissionFlow = async (fromModal?: boolean) => {
    setServiceRequestFormContents((prev) => [
      formContentFromAuthBuilderContext({
        priorAuthRequirements,
        patient,
        mostRecentlyUsedFaxNumber,
        simplifiedServiceFrequency,
        procedureCodes: priorAuthRequirements.userSelectedNonPalCode
          ? [...palProcedureCodes, ...nonPalProcedureCodes]
          : palProcedureCodes,
        importedEhrOrder,
        shouldPopulateAdmissionDates: true,
        previousFormContent: prev[0],
        facilityBasedFeatureEnabled,
        useDefaultDurationAsMinimumEndDate: !!blockUserIfLessThanRecommendedEndDate,
      }),
    ]);
    !priorAuthRequirements.userSelectedNonPalCode && setNonPalProcedureCodes([]);
    setNonCohereProcedureCodes([]);
    fromModal && setOpenNonPalConfirmationModal?.(false);
    await getServiceDeterminationFlexIntake();
  };

  const smartClient = useContext(SmartOnFhirContext);
  const inSmartOnFhirWorkflow = Boolean(smartClient);

  //paCheckRedesigned
  const createDraftServiceRequest = async (saveAndExit: boolean, searchForDuplicates?: boolean) => {
    const formContentObject = formContentFromAuthBuilderContext({
      priorAuthRequirements,
      patient,
      mostRecentlyUsedFaxNumber,
      simplifiedServiceFrequency,
      procedureCodes: priorAuthRequirements.desiredProcedureCodes,
      importedEhrOrder,
      shouldPopulateAdmissionDates: true,
      previousFormContent: serviceRequestFormContents[0],
      facilityBasedFeatureEnabled,
      workflowStep: "GATHER_REQUIREMENTS_MEDICAL",
      useDefaultDurationAsMinimumEndDate: !!blockUserIfLessThanRecommendedEndDate,
    });
    setServiceRequestFormContents((prev) => [formContentObject]);
    const srPayload: ServiceRequestCreatePayload = {
      ...payloadFromSRFormContent(formContentObject, true, serviceRequest?.authStatus || "DRAFT", workflowId, false),
      patient: patient?.id,
      workflowId: workflowId,
      requestType: "INITIAL",
      ehrOrderId: importedEhrOrder?.ehrOrderId,
      ehrPracticeId: importedEhrOrder?.ehrPracticeId,
      ehrVendor: importedEhrOrder?.ehrVendor,
      ehrPatientId: importedEhrOrder?.ehrPatientId,
      ehrDepartmentId: importedEhrOrder?.ehrDepartmentId,
      ehrEncounterId: importedEhrOrder?.ehrEncounterId,
      userUpdatedTat: userUpdatedTat,
    };

    const authorizationPayload: AuthorizationCreatePayload = {
      patient: patient?.id,
      healthPlanName,
      serviceRequests: [srPayload],
      dischargedTo: serviceRequest?.dischargedTo?.enumName ?? undefined,
    };

    if (searchForDuplicates) {
      const duplicatesFound = await searchForPotentialDuplicates(srPayload, serviceRequestFormContents[0]);
      if (duplicatesFound) {
        // Duplicates modal is shown, don't proceed w/ creating service request draft yet.
        return;
      }
    }

    const hasNoDuplicateAuthData = duplicateAuthData?.length === 0 || duplicateAuthData === null;
    const canFetchEpisodicAuths = createEpisodicAuths && !hasEpisodicAuthTriggered;
    if (!saveAndExit && hasNoDuplicateAuthData && canFetchEpisodicAuths) {
      const episodicAuthData = await getEpisodicServiceRequestsData();
      if (episodicAuthData && episodicAuthData.length > 0) {
        setEpisodicAuthData(episodicAuthData);
        setEpisodicSRModal(episodicAuthData.length > 0);
        setHasEpisodicAuthTriggered(true);
        return;
      }
    }

    let draftSRId = "";
    const hasServiceRequestOrFormContentId = serviceRequest?.id || formContentObject?.id;
    const isEpisodicAuth = serviceRequest?.episodeGeneratedFromServiceRequest;

    if (hasServiceRequestOrFormContentId && !isEpisodicAuth) {
      //patch the request because an authorization has already be created
      let response: ServiceRequestResponse;
      response = await updateServiceRequest(
        {
          ...srPayload,
        },
        { pathParams: { id: serviceRequest?.id || formContentObject?.id || "" } }
      );
      if (serviceRequest?.dischargedTo && response?.authorization?.id) {
        const authRes = await patchAuthorization(
          { dischargedTo: serviceRequest.dischargedTo.enumName },
          { pathParams: { id: response.authorization.id } }
        );
        response.dischargedTo = authRes?.dischargedTo;
      }
      if (response) {
        draftSRId = response?.id || formContentObject?.id || "";
        editServiceRequest(response);
      }
    } else {
      draftSRId = await createAuthorization(authorizationPayload);
    }

    //save and exit navigate to patient summary otherwise do regular continue actions
    if (saveAndExit) {
      try {
        if (duplicateAuthData) {
          await trackInteraction({
            event: "CANCEL_DUPLICATE",
            stage: "DUPLICATE_SUBMISSION_REVIEW",
            activityContext: {
              workflowId: workflowId,
            },
          });
        }

        if (inSmartOnFhirWorkflow) {
          await navigateToSRSummary(draftSRId, navigate, true);
        } else {
          await navigateToPS(patient?.id || "", navigate, draftSRId);
        }
      } catch (error) {
        logError(new Error(`Unable to save: ${error}`));
      }
    } else {
      await getServiceDeterminationFlexIntake();
    }
  };

  const createAuthorization = async (authorizationPayload: AuthorizationCreatePayload): Promise<string> => {
    //create an authorization + sr on auth
    const authorizationResp = await createNewServiceRequestOnAuth(authorizationPayload);
    // If this SR case was created from faxId, then immediately
    // link the fax attachment to the newly created SR
    let newDraftSrId = "";

    if (
      authorizationResp?.serviceRequestsOnAuth?.length &&
      authorizationResp.serviceRequestsOnAuth[0].id &&
      defaultFaxId
    ) {
      const formData = new FormData();
      formData.set("faxId", defaultFaxId);
      // Do not block auth submission if unable to auto attach fax
      try {
        await uploadSrAttachment(formData as unknown as void, {
          pathParams: { id: authorizationResp?.serviceRequestsOnAuth[0]?.id || "" },
        });
      } catch (e) {
        logError(new Error(`Unable to auto attach fax to new service request: ${e}`));
      }
    }

    if (authorizationResp?.serviceRequestsOnAuth?.length) {
      newDraftSrId = authorizationResp?.serviceRequestsOnAuth[0].id;
      addServiceRequest(authorizationResp.serviceRequestsOnAuth[0]);
    }

    if (authorizationResp?.serviceRequestsOnAuth?.length) {
      setServiceRequestFormContents((prev) => {
        if (authorizationResp?.serviceRequestsOnAuth?.length) {
          return [formContentFromResponse(authorizationResp.serviceRequestsOnAuth[0])];
        }
        return prev;
      });
    }
    return newDraftSrId;
  };

  const clearServiceDeterminationResults = () => {
    setPrimaryCarePathId(undefined);
    setBuckets([]);
  };

  /*
   * getSortedServiceRequests: Sorts serviceRequests by dateCreated, oldest to newest
   * @param serviceRequestResponse: List of serviceRequests to be sorted
   * @return ServiceRequestResponse[]: List of sorted serviceRequests in ascending order
   */
  const getSortedServiceRequests = (serviceRequests: ServiceRequestResponse[]): ServiceRequestResponse[] => {
    return (
      serviceRequests?.sort((a, b) => {
        return Date.parse(a.dateCreated) - Date.parse(b.dateCreated);
      }) || []
    );
  };

  const confirmNonPalAddition = () => {
    setUserSelectedNonPalCode?.(true);
    setOpenNonPalConfirmationModal?.(false);
  };
  const classes = useStyles();

  const deleteAnAttachment = async (fileId: string, srId: string): Promise<void> => {
    await deleteAttachment(fileId, {
      pathParams: { id: srId },
    });
  };

  const returnToUrl = useGetReturnToUrl();
  const diagnosisCode = priorAuthRequirements?.primaryDiagnosis?.code || "";
  const hasActiveCoverage = !!startDateCoverage?.inRange;
  const commonFooterDisabled =
    !facilityBasedCanContinue ||
    !hasActiveCoverage ||
    resultsLoading ||
    questionModalOpen ||
    loadingContinuations ||
    loadingEpiSRs ||
    resultsLoading ||
    diagnosisCode === "" ||
    !isAdditionalInsuranceTextValid ||
    (!facilityBasedFeatureEnabled && currProcedureCodeCount === 0); //checks if the PX Codes are not optional and if current procedure code count is 0 to disable the button

  const trackInteraction = useTrackUserInteraction();

  const buttonLoading =
    resultsLoading ||
    loadingContinuations ||
    updateServiceRequestLoading ||
    createNewServiceRequestOnAuthLoading ||
    getEpisodicServiceRequestsLoading;
  return (
    <>
      <Footer
        /** the logic for the onClick below
         * getDuplicates replaces getPossibleContinuations - they will never co-exist
         * when there are duplicates, episodic SRs should not be fetched
         * depending on whether duplicates or episodic SRs exist, a modal shows up showing those SRs
         * create a draft SR in either case
         */
        onPrimaryButtonClick={async () => {
          if (!isDuplicateAuthsFeatureOn) {
            const authorizationsResponse = await fetchGetPossibleContinuations();
            const serviceRequestsResponse = await fetchGetPossibleContinuationsSR();
            let serviceRequests;
            if (authorizationsResponse) {
              serviceRequests = getLatestValidContinuation(authorizationsResponse);
            }
            if (
              serviceRequests &&
              serviceRequests.length === 0 &&
              serviceRequestsResponse &&
              serviceRequestsResponse.length === 0
            ) {
              await createDraftServiceRequest(false, isDuplicateAuthsFeatureOn);
            }
          } else {
            // If the duplicate auth feature is on then just create draft service requests, the duplicate auth fetch
            // happens within this method (feature gated)

            await createDraftServiceRequest(false, isDuplicateAuthsFeatureOn);
          }
        }}
        primaryButtonDisabled={
          commonFooterDisabled ||
          updateServiceRequestLoading ||
          createNewServiceRequestOnAuthLoading ||
          deleteAttachmentLoading ||
          getEpisodicServiceRequestsLoading
        }
        primaryButtonLoading={buttonLoading}
        primaryButtonText={footerContinueText}
        showSaveAndExitButton={true}
        saveAndExitButtonDisabled={
          saveAndExitDisabled ||
          createNewServiceRequestOnAuthLoading ||
          updateServiceRequestLoading ||
          deleteAttachmentLoading
        }
        onSaveAndExitClick={async () => {
          await createDraftServiceRequest(true);
        }}
        secondaryButtonText={"Cancel"}
        onSecondaryButtonClick={async () => {
          if (serviceRequest?.id || serviceRequestFormContents[0]?.id) {
            // If there was already a draft service request created, show cancel modal
            setCancelModalOpen(true);
          } else {
            // Otherwise, just navigate out of authBuilder
            if (duplicateAuthData) {
              await trackInteraction({
                event: "CANCEL_DUPLICATE",
                stage: "DUPLICATE_SUBMISSION_REVIEW",
                activityContext: {
                  workflowId: workflowId,
                },
              });
            }
            navigate(returnToUrl);
          }
        }}
        showSecondaryButton={true}
        showError={false}
        setFooterHeight={setFooterHeight}
      />
      {questionModalOpen && (
        <QuestionModal
          open={questionModalOpen}
          onClose={onQuestionModalClose}
          patientId={patient?.id || ""}
          priorAuthRequirements={priorAuthRequirements}
          questionOptions={questionOptions}
          setQuestionOptions={setQuestionOptions}
          questionAnswers={questionAnswers}
          setQuestionAnswers={setQuestionAnswers}
          defaultPlaceOfServiceId={defaultPlaceOfServiceId}
          clearServiceDeterminationResults={clearServiceDeterminationResults}
          getServiceDeterminationResults={getServiceDeterminationResults}
          loading={serviceDeterminationLoading || loading}
          canContinue={buckets.length > 0}
          onContinue={() => continueToServiceSelectionOrFillForms(buckets, primaryCarePathId)}
          workflowId={workflowId}
        />
      )}
      {!isDuplicateAuthsFeatureOn && checkExistingAuthModalOpen && (
        <CheckExistingAuthorization
          open={checkExistingAuthModalOpen}
          onClose={checkExistingAuthModalClose}
          serviceRequests={continuationsData}
          user={userResponse}
          onClickNewSR={async () => {
            checkExistingAuthModalClose();
            if (generalAuthSubmissionWorkflowEnabled) {
              await createDraftServiceRequest(false);
            } else {
              await getServiceDeterminationResults();
            }
          }}
        />
      )}

      {createEpisodicAuths && episodicSRModal && episodicAuthData.length > 0 && (
        <EpisodicAuthsModal
          open={episodicSRModal}
          onClose={() => {
            setEpisodicSRModal(false);
          }}
          serviceRequest={episodicAuthData[0]}
          patient={patient}
          onClickNewSR={async () => {
            // Create the draft service request, but this time don't search for any duplicates
            // The hasEpisodicAuthTriggered flag will ensure that we don't look for episodic auths again for the lifecycle of this component.
            await createDraftServiceRequest(false, false);
          }}
        />
      )}
      {isDuplicateAuthsFeatureOn && (
        // ideally the context should be higher but I am hesitant to put it in the parent compoennt as it renders so many different cases
        <PotentialDuplicateContextProvider workflowId={String(workflowId)}>
          <PotentialDuplicate
            loading={buttonLoading}
            open={duplicateAuthsModalOpen}
            onClose={() => setDuplicateAuthsModalOpen(false)}
            serviceRequests={continuationsData}
            user={userResponse}
            onClickNewSR={async () => {
              // Create the draft service request, but this time don't search for any duplicates
              createDraftServiceRequest(false, false);
            }}
            duplicateAuthData={duplicateAuthData}
            searchForPotentialDuplicates={searchForPotentialDuplicates}
            serviceRequestFormContents={serviceRequestFormContents}
            getPotentialDuplicatesLoading={getPotentialDuplicatesLoading}
          />
        </PotentialDuplicateContextProvider>
      )}
      <InformativeModal
        open={cancelModalOpen}
        onClose={() => setCancelModalOpen(false)}
        icon={<HelpOutlineIcon style={{ color: palette.warning.dark }} />}
        headerText="Are you sure you want to cancel this authorization?"
        subHeaderText="Your progress will not be saved."
        primaryButtonText="Yes, cancel"
        primaryButtonAction={async () => {
          if (attachments) {
            attachments.forEach(async (file) => {
              await deleteAnAttachment(file.id, serviceRequestFormContents[0].id || "");
            });
          }
          await deleteServiceRequest(serviceRequestFormContents[0].id || "");
          navigate(returnToUrl);
        }}
        tertiaryButtonText="Continue with authorization"
        tertiaryButtonAction={() => setCancelModalOpen(false)}
      />
      <InformativeModal
        open={openNonPalConfirmationModal || false}
        onClose={() => setOpenNonPalConfirmationModal?.(false)}
        headerText={`${
          nonPalProcedureCodes.length > 1 ? "These codes don't " : "This code doesn’t "
        } usually require submission`}
        subHeaderElement={
          <div className={classes.subHeader}>
            Including {nonPalProcedureCodes.length > 1 ? "them" : "it"} is only necessary if a review is needed to
            ensure coverage (e.g., an organizational determination or a program exception). Otherwise, please continue
            without
            {nonPalProcedureCodes.length > 1 ? " them" : " it"}.
            <InlineButton
              startIcon={<OpenInNew />}
              onClick={() => {
                window.open("https://coherehealth.zendesk.com/hc/en-us/articles/14198266768279");
              }}
              className={classes.learnMoreButton}
            >
              Learn more
            </InlineButton>
          </div>
        }
        primaryButtonText={`Continue without ${nonPalProcedureCodes.length > 1 ? "these codes" : "this code"}`}
        primaryButtonDisabled={palProcedureCodes.length < 1}
        tertiaryButtonText={`Include ${
          nonPalProcedureCodes.length > 1 ? "these codes" : "this code"
        } (request will pend for review)`}
        primaryButtonAction={() => continueGeneralAuthSubmissionFlow?.(true)}
        tertiaryButtonAction={confirmNonPalAddition}
        icon={<ErrorIcon className={classes.errorIcon} />}
        iconSize="48px"
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  errorIcon: {
    color: theme.palette.warning.dark,
  },
  subHeader: {
    display: "flex",
    flexDirection: "column",
  },
  learnMoreButton: {
    paddingTop: "24px",
  },
}));
