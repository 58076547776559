import { FormEvent, useEffect, useState, useMemo } from "react";
import {
  AuthStatus,
  ServiceRequestResponse,
  ServiceRequestSearchResponse,
  useUpdateServiceRequest,
  useCreatePeerToPeerOutreach,
  useGetNextNegationStatus,
  useExtendServiceRequestTurnAroundTime,
  AuthorizationResponse,
  useGetNextAllowedAuthStatuses,
  OutreachAttempt,
  ServiceRequestUpdatePayload,
} from "@coherehealth/core-platform-api";
import {
  PrimaryButton,
  SingleSelectDropdown,
  TextField,
  TertiaryButton,
  useFeature,
  useConfiguration,
  useGetServiceRequestByIdWithFallback,
} from "@coherehealth/common";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { error as logError } from "logger";
import { useSnackbar } from "notistack";
import {
  isNegationAuthStatus,
  withdrawnReasonOptions,
  mapAuthServiceRequestsToDropdownOptions,
  sortServiceRequestByDateField,
} from "util/serviceRequest";
import { useAuthorized } from "authorization";
import { shouldShowNextReviewDate } from "components/ClinicalReview/ClinicalReviewInfoPanel/NotesCard/noteUtils";
import useFetchPendingReason from "components/ServiceRequest/ReviewSection/common/hooks/useFetchPendingReasons";
import {
  CenteredRow,
  ChangeStatusFormState,
  getFilteredPendingReasons,
  getFormStatusFromSelection,
  getISODateStringFromStateFields,
  pendingReasonOptions,
  srHasPatientStatus,
} from "./utils";
import PendingMDReviewForm from "./PendingMDReviewForm/PendingMDReviewForm";
import PendingMissingInfoForm from "./PendingMissingInfoForm/PendingMissingInfoForm";
import PartiallyApprovedForm from "./PartiallyApprovedForm/PartiallyApprovedForm";
import NegationStatusForm from "./NegationStatusForm.tsx/NegationStatusForm";
import AuthStatusSelectDropdown from "./AuthsStatusSelectDropdown/AuthStatusSelectDropdown";
import { PendingReasonSelectDropdown } from "./PendingReasonSelectDropdown/PendingReasonSelectDropdown";
import { ChangeStatusAppendixForm } from "./ChangeStatusAppendixForm/ChangeStatusAppendixForm";
import { format } from "date-fns";

interface ChangeStatusFormProps {
  onEdit: (postedOutreachAttempt?: OutreachAttempt | undefined) => void;
  onCancel: () => void;
  authServiceRequests: (ServiceRequestResponse | ServiceRequestSearchResponse)[];
  onSubmission?: (sr: ServiceRequestResponse) => void;
  authorizationRefetch?: () => Promise<AuthorizationResponse | null>;
  postDenialP2PEligible?: boolean;
  saveOutreachAttempt?: () => Promise<OutreachAttempt | undefined>;
  setUseEnhancedWithdrawalModal?: React.Dispatch<React.SetStateAction<boolean>>;
  outreachAttemptLoading?: boolean;
  isLatestServiceRequestMemberRequested?: boolean;
}

export default function ChangeStatusForm({
  onEdit,
  onCancel,
  authServiceRequests,
  onSubmission,
  authorizationRefetch,
  saveOutreachAttempt,
  outreachAttemptLoading,
  isLatestServiceRequestMemberRequested,
  setUseEnhancedWithdrawalModal,
}: ChangeStatusFormProps) {
  // Feature flags
  const partialApprovalEnabled = useFeature("partialApproval");
  const diagnosisOutOfScopeEnabled = useFeature("diagnosisOutOfScope");
  const postDenialWorkflowEnabled = useFeature("postDenialWorkflow");
  const postDecisionP2PExpanded = useFeature("postDecisionP2PExpanded");
  const oonReviewConfig = useConfiguration(
    "outOfNetworkReviewConfiguration",
    authServiceRequests[0]?.healthPlanName,
    authServiceRequests[0]?.delegatedVendor
  );
  const reviewerWithdrawalModalEnhanced = useFeature("reviewerWithdrawalModalEnhanced");

  // User authorizations
  const canViewAllWithdrawnReasons = useAuthorized("VIEW_ALL_WITHDRAWAL_REASONS");
  const canEditWithdrawalRequestor = useAuthorized("EDIT_WITHDRAWAL_REQUESTOR");
  const canViewEnhancedWithdrawalModal = useAuthorized("VIEW_ENHANCED_WITHDRAWAL_MODAL");

  // Component states and computed values
  const sortedAuthServiceRequests = sortServiceRequestByDateField("dateCreated", authServiceRequests, true);
  const latestServiceRequest = sortedAuthServiceRequests?.[0] as ServiceRequestResponse;
  const [selectedServiceRequest, setSelectedServiceRequest] = useState(sortedAuthServiceRequests[0]);
  const [sendOptionalDecisionLetter, setSendOptionalDecisionLetters] = useState<boolean>(false);
  const isGHPServiceRequest = selectedServiceRequest.healthPlanName === "Geisinger";
  const isHumanaServiceRequest = selectedServiceRequest.healthPlanName === "Humana";
  const [formState, setFormState] = useState<ChangeStatusFormState>(
    getFormStatusFromSelection(selectedServiceRequest, { postDenialWorkflowEnabled, postDecisionP2PExpanded })
  );

  const [nextNegationStatus, setNextNegationStatus] = useState<AuthStatus>();

  const requireP2POutreachInfo =
    formState.peerToPeer?.attempted && formState.authStatus === "PENDING" && formState.pendingReason === "MD_REVIEW";

  // RESTful React hooks
  const {
    mutate: updateServiceRequest,
    error: updateServiceRequestError,
    loading: updateServiceRequestLoading,
  } = useUpdateServiceRequest({ id: selectedServiceRequest.id });

  const {
    mutate: createP2POutreach,
    error: createP2POutreachError,
    loading: createP2POutreachLoading,
  } = useCreatePeerToPeerOutreach({
    serviceRequestId: selectedServiceRequest.id,
  });

  const { mutate: getNextNegationStatus } = useGetNextNegationStatus({
    id: selectedServiceRequest.id,
  });

  const { data: allowedAuthStatusesResponse, error: nextAllowedAuthStatusesError } = useGetNextAllowedAuthStatuses({
    id: selectedServiceRequest.id,
  });

  const { mutate: extendServiceRequestTAT } = useExtendServiceRequestTurnAroundTime({
    serviceRequestId: selectedServiceRequest.id,
  });

  const { data: persistedServiceRequest, refetch: refetchPersistedServiceRequest } =
    useGetServiceRequestByIdWithFallback({
      id: selectedServiceRequest.id,
      lazy: true,
    });

  // Utility hooks
  const { enqueueSnackbar } = useSnackbar();
  const { spacing } = useTheme();

  // useEffect declarations
  useEffect(() => {
    // When the user chooses to move an SR to WITHDRAWN, we need to determine what the
    // next auth status will be based on the selected withdrawn reason. This value is
    // displayed to the user and sent in the update payload.
    const getNextState = async () => {
      const nextStatus = await getNextNegationStatus({ withdrawnReason: formState.withdrawVoid?.reason });
      setNextNegationStatus(nextStatus);
    };
    if (isNegationAuthStatus(formState.authStatus)) {
      getNextState();
      return;
    }
    setNextNegationStatus(formState.authStatus);
  }, [getNextNegationStatus, formState.authStatus, formState.withdrawVoid?.reason]);

  useEffect(() => {
    // When the user chooses to move an SR that has already had a TAT extension to PENDING
    // with the reason being either missing clinical or non-clinical info, the TAT extension
    // decision state is updated to "YES". (behind FF)
    if (
      formState.authStatus === "PENDING" &&
      (formState.pendingReason === "MISSING_CLINICAL_INFO" ||
        formState.pendingReason === "MISSING_NON_CLINICAL_INFO") &&
      selectedServiceRequest.turnAroundTimeExtension &&
      formState.tatExtension.eligible &&
      formState.tatExtension.decision !== "YES"
    ) {
      setFormState((prevState) => ({
        ...prevState,
        tatExtension: {
          ...prevState.tatExtension,
          decision: "YES",
        },
      }));
    }
  }, [
    formState.authStatus,
    formState.pendingReason,
    selectedServiceRequest.turnAroundTimeExtension,
    formState.tatExtension.decision,
    formState.tatExtension.eligible,
  ]);

  useEffect(() => {
    // If the selected SR has no review outcome withdrawn options, is not a reconsideration,
    // and has no withdraw requester option, then we should re-fetch the persisted version of
    // the SR.
    if (
      !selectedServiceRequest.reviewOutcomeWithdrawOptions ||
      !selectedServiceRequest.reconClaim ||
      selectedServiceRequest.withdrawRequestorOption === undefined
    ) {
      refetchPersistedServiceRequest();
    }
  }, [
    refetchPersistedServiceRequest,
    selectedServiceRequest.reconClaim,
    selectedServiceRequest.reviewOutcomeWithdrawOptions,
    selectedServiceRequest.withdrawRequestorOption,
  ]);

  useEffect(() => {
    const skipRequesterResync =
      canViewEnhancedWithdrawalModal &&
      reviewerWithdrawalModalEnhanced &&
      persistedServiceRequest?.withdrawRequestor !== formState?.withdrawVoid?.requester;
    // When we re-fetch the persisted version of the selected service request and
    // there is no withdrawn reason or requester selected on the form, we should
    // update it to sync with the persisted version of the SR.
    if (persistedServiceRequest?.withdrawnReason && !formState.withdrawVoid?.reason) {
      setFormState((prevState) => {
        return {
          ...prevState,
          withdrawVoid: {
            ...prevState.withdrawVoid,
            reason: persistedServiceRequest.withdrawnReason,
          },
        };
      });
    }
    if (persistedServiceRequest?.withdrawRequestor && !formState.withdrawVoid?.requester && !skipRequesterResync) {
      setFormState((prevState) => {
        return {
          ...prevState,
          withdrawVoid: {
            ...prevState.withdrawVoid,
            requester: persistedServiceRequest.withdrawRequestor,
          },
        };
      });
    }
  }, [
    persistedServiceRequest?.withdrawRequestor,
    persistedServiceRequest?.withdrawnReason,
    formState.withdrawVoid?.reason,
    formState.withdrawVoid?.requester,
    canViewEnhancedWithdrawalModal,
    reviewerWithdrawalModalEnhanced,
  ]);

  const nextAllowedAuthStatuses = useMemo(
    // When the allowed auth statuses are updated (this occurs when a different service request
    // is selected), then map the new values to dropdown option objects.
    () => {
      const statuses = allowedAuthStatusesResponse?.nextAllowedAuthStatuses;
      if (isGHPServiceRequest) {
        return statuses?.filter((status) => status.id !== "PENDING_EXTERNAL_DETERMINATION");
      }
      return statuses;
    },
    [allowedAuthStatusesResponse?.nextAllowedAuthStatuses, isGHPServiceRequest]
  );

  const { allowedPendingReasons } = useFetchPendingReason(selectedServiceRequest.id);
  useEffect(() => {
    // If there is any error updating the service request, notify the end user with a toast
    if (updateServiceRequestError) {
      const error = updateServiceRequestError;
      const errorMessage = typeof error.data !== "string" && error.data?.message ? error.data.message : error.data;
      enqueueSnackbar(`Error updating service request: ${errorMessage}`, { variant: "error" });
    }
  }, [updateServiceRequestError, enqueueSnackbar]);

  useEffect(() => {
    // If there is any error creating the peer-to-peer outreach, log the error in Sentry
    if (createP2POutreachError) {
      logError("Error creating a P2P Outreach");
    }
  }, [createP2POutreachError]);

  useEffect(() => {
    // If there is any error fetching the next allowed auth statuses, log the error in Sentry
    if (nextAllowedAuthStatusesError) {
      logError(`Error retrieving the next allowed auth statuses for service request: ${selectedServiceRequest.id}`);
    }
  }, [nextAllowedAuthStatusesError, selectedServiceRequest.id]);

  // Component function declarations
  const disableSaveButton = () => {
    const {
      authStatus,
      partialApproval: { approvedUnits = 0 },
      peerToPeer: { lastAttempt: lastPeerToPeerAttempt },
      withdrawVoid: { reason: withdrawnReason, requester: withdrawRequester },
    } = formState;

    // Disable submit if:
    //   - the partial approval FF is enabled
    //   - the chosen auth status on the form is PARTIALLY_APPROVED
    //   - the selected service request has a non-zero number of units
    //   - the user attempts to approve more units than requested or a non-positive number of units
    if (partialApprovalEnabled && authStatus === "PARTIALLY_APPROVED" && Boolean(selectedServiceRequest.units)) {
      return approvedUnits > selectedServiceRequest.units! || approvedUnits <= 0;
    }

    // Disable submit if:
    //   - a negated auth status is chosen
    //   - a truthy withdrawn reason is provided
    //   - the calculated next negation status is WITHDRAWN
    //   - the service request's withdrawRequestorOption field is true
    //   - there is no specified withdraw requester on the form
    //   - the end-user is not a back office user
    if (isNegationAuthStatus(authStatus) && Boolean(withdrawnReason) && nextNegationStatus === "WITHDRAWN") {
      return (
        (selectedServiceRequest.withdrawRequestorOption || persistedServiceRequest?.withdrawRequestorOption) &&
        !withdrawRequester &&
        canEditWithdrawalRequestor
      );
    }

    // Disable submit if:
    //   - a negated auth status is chosen
    //   - there is no withdraw reason provided by the user
    if (isNegationAuthStatus(authStatus)) {
      return !Boolean(withdrawnReason);
    }

    // Disable submit if:
    //   - peer-to-peer outreach information is required
    //   - the user has not provided all the last attempt data (date, time, meridian, and outcome)
    if (requireP2POutreachInfo) {
      return !Boolean(
        lastPeerToPeerAttempt?.meridian &&
          lastPeerToPeerAttempt?.outcome &&
          lastPeerToPeerAttempt?.date?.length === 10 &&
          lastPeerToPeerAttempt?.time?.length === 5 &&
          getISODateStringFromStateFields(
            lastPeerToPeerAttempt?.date,
            lastPeerToPeerAttempt?.time,
            lastPeerToPeerAttempt?.meridian === "AM"
          )
      );
    }
    return false;
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const {
      authStatus,
      pendingReason,
      authorizationNote,
      partialApproval: { approvedUnits, approvedProcedureCodes },
      peerToPeer: { lastAttempt: lastPeerToPeerAttempt },
      tatExtension: { decision: tatExtensionDecision },
      withdrawVoid: { reason: withdrawnReason, requester: withdrawRequester },
    } = formState;

    const postedOutreachAttempt = saveOutreachAttempt ? await saveOutreachAttempt() : undefined;

    const getWithdrawRequestorPayload = () => {
      if (
        (selectedServiceRequest.withdrawRequestorOption || persistedServiceRequest?.withdrawRequestorOption) &&
        isNegationAuthStatus(authStatus)
      ) {
        if (!canEditWithdrawalRequestor) {
          return "PROVIDER";
        }
        return withdrawRequester;
      }
    };

    let updateServiceRequestPayload: ServiceRequestUpdatePayload = {
      authStatus: nextNegationStatus || authStatus,
      pendingReason: authStatus === "PENDING" ? pendingReason : undefined,
      approvedUnits:
        authStatus === "PARTIALLY_APPROVED" && Boolean(selectedServiceRequest.units) ? approvedUnits : undefined,
      approvedSemanticProcedureCodes: authStatus === "PARTIALLY_APPROVED" ? approvedProcedureCodes : undefined,
      authorizationNote,
      withdrawnReason: isNegationAuthStatus(authStatus) ? withdrawnReason : undefined,
      withdrawRequestor: getWithdrawRequestorPayload(),
      claimsPaymentNote: formState.claimsPaymentNote,
      nextReviewDate:
        showNextReviewDate && formState.nextReviewDate ? format(formState.nextReviewDate, "yyyy-MM-dd") : undefined,
    };

    if (sendOptionalDecisionLetter && isLatestServiceRequestMemberRequested) {
      updateServiceRequestPayload = {
        ...updateServiceRequestPayload,
        additionalRecipients: ["PROVIDER"],
      };
    }

    const retVal = await updateServiceRequest(updateServiceRequestPayload, {
      queryParams: { createClaimsNote: !!formState.claimsPaymentNote },
    });

    if (requireP2POutreachInfo && lastPeerToPeerAttempt?.outcome !== "") {
      const attemptedTimestamp = getISODateStringFromStateFields(
        lastPeerToPeerAttempt?.date || "",
        lastPeerToPeerAttempt?.time || "",
        lastPeerToPeerAttempt?.meridian === "AM"
      );
      await createP2POutreach({
        attemptedTimestamp,
        outcome: lastPeerToPeerAttempt?.outcome,
        healthPlanName: selectedServiceRequest.healthPlanName || "",
      });
    }

    if (tatExtensionDecision === "YES" && !selectedServiceRequest.turnAroundTimeExtension) {
      await extendServiceRequestTAT();
    }

    onEdit(postedOutreachAttempt);
    onSubmission?.(retVal);
    authorizationRefetch?.();
  };

  const withdrawnReasonSelectOptions = () => {
    const withdrawOptions =
      selectedServiceRequest.reviewOutcomeWithdrawOptions ||
      persistedServiceRequest?.reviewOutcomeWithdrawOptions ||
      withdrawnReasonOptions(
        canViewAllWithdrawnReasons,
        selectedServiceRequest.id,
        !!selectedServiceRequest.reconClaim || !!persistedServiceRequest?.reconClaim
      );
    return withdrawOptions;
  };

  const updateFormBasedOnServiceRequestSelection = (serviceRequestId: string) => {
    const selection = sortedAuthServiceRequests.find((sr) => sr.id === serviceRequestId) || selectedServiceRequest;
    setSelectedServiceRequest(selection);
    setNextNegationStatus(undefined);
    setFormState(getFormStatusFromSelection(selection, { postDenialWorkflowEnabled, postDecisionP2PExpanded }));
  };

  const ghpPendingReasons = [
    "MISSING_CLINICAL_INFO",
    "RN_REVIEW",
    "MD_REVIEW",
    "PEER_TO_PEER_REVIEW",
    "OUT_OF_NETWORK_REVIEW",
    "SCHEDULING_OUTREACH",
  ];

  const filteredPendingReasons = getFilteredPendingReasons(pendingReasonOptions, {
    ghpPendingReasons,
    formState,
    diagnosisOutOfScopeEnabled,
    oonReviewConfig,
    isHumanaServiceRequest,
    isGHPServiceRequest,
  });

  const showNextReviewDate = shouldShowNextReviewDate({
    encounterType: selectedServiceRequest.encounterType,
    authStatus: formState.authStatus,
    patientStatus: srHasPatientStatus(selectedServiceRequest) ? selectedServiceRequest.patientStatus : undefined,
  });

  const useEnhancedWithdrawalModal =
    canViewEnhancedWithdrawalModal && reviewerWithdrawalModalEnhanced && formState.authStatus === "WITHDRAWN";

  const getWidthByFeature = (): number | undefined => {
    if (useEnhancedWithdrawalModal) {
      return 848;
    }
    return undefined;
  };

  useEffect(() => {
    setUseEnhancedWithdrawalModal?.(useEnhancedWithdrawalModal);
  }, [setUseEnhancedWithdrawalModal, useEnhancedWithdrawalModal]);

  return (
    <>
      <Grid container component="form" onSubmit={onSubmit} spacing={2} style={{ maxWidth: getWidthByFeature() }}>
        {sortedAuthServiceRequests.length > 1 && (
          <Grid item xs={12}>
            <SingleSelectDropdown
              label={"Select a request to update"}
              value={selectedServiceRequest.id}
              options={mapAuthServiceRequestsToDropdownOptions(sortedAuthServiceRequests)}
              onChange={(value) => {
                updateFormBasedOnServiceRequestSelection(value);
              }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <AuthStatusSelectDropdown
            formState={formState}
            nextAllowedAuthStatuses={nextAllowedAuthStatuses}
            postDecisionP2PExpanded={postDecisionP2PExpanded}
            setFormState={setFormState}
          />
        </Grid>
        {formState.authStatus === "PENDING" && (
          <>
            <Grid item xs={12}>
              <PendingReasonSelectDropdown
                allowedPendingReasons={allowedPendingReasons}
                filteredPendingReasons={filteredPendingReasons}
                formState={formState}
                setFormState={setFormState}
              />
            </Grid>
            {formState.pendingReason === "MD_REVIEW" && (
              <PendingMDReviewForm setFormState={setFormState} formState={formState} />
            )}
            {(formState.pendingReason === "MISSING_CLINICAL_INFO" ||
              formState.pendingReason === "MISSING_NON_CLINICAL_INFO") &&
              isGHPServiceRequest && (
                <PendingMissingInfoForm
                  setFormState={setFormState}
                  formState={formState}
                  selectedServiceRequest={selectedServiceRequest}
                />
              )}
          </>
        )}
        {formState.authStatus === "PARTIALLY_APPROVED" && !formState.peerToPeer?.isPostDenialEligible && (
          <PartiallyApprovedForm
            setFormState={setFormState}
            formState={formState}
            selectedServiceRequest={selectedServiceRequest}
          />
        )}
        <Grid item xs={12} style={{ paddingTop: spacing(3) }}>
          {isNegationAuthStatus(formState.authStatus) ? (
            <NegationStatusForm
              formState={formState}
              nextNegationStatus={nextNegationStatus}
              persistedServiceRequest={persistedServiceRequest}
              selectedServiceRequest={selectedServiceRequest}
              setFormState={setFormState}
              withdrawnReasonSelectOptions={withdrawnReasonSelectOptions}
            />
          ) : (
            <TextField
              label="Note to provider"
              fullWidth
              multiline
              minRows={4}
              value={formState.authorizationNote}
              onChangeValue={(authNote) => {
                setFormState((prevFormState) => ({
                  ...prevFormState,
                  authorizationNote: authNote,
                }));
              }}
            />
          )}
          <ChangeStatusAppendixForm
            formState={formState}
            latestServiceRequest={latestServiceRequest}
            sendOptionalDecisionLetter={sendOptionalDecisionLetter}
            setFormState={setFormState}
            setSendOptionalDecisionLetters={setSendOptionalDecisionLetters}
            showNextReviewDate={showNextReviewDate}
          />
        </Grid>
        <CenteredRow style={{ paddingTop: spacing(3) }}>
          <PrimaryButton
            disabled={outreachAttemptLoading || updateServiceRequestLoading || disableSaveButton()}
            loading={outreachAttemptLoading || updateServiceRequestLoading || createP2POutreachLoading}
            type="submit"
          >
            Save changes
          </PrimaryButton>
        </CenteredRow>
        <CenteredRow>
          <TertiaryButton onClick={onCancel}>Cancel</TertiaryButton>
        </CenteredRow>
      </Grid>
    </>
  );
}
