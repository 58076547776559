import type { TimelineItem } from "../hooks/usePatientStayTimelineItems";

import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "tss-react/mui";

interface TimelineContentProps {
  item: TimelineItem;
}

export const TimelineContent = ({ item }: TimelineContentProps) => {
  const { classes } = useStyles();

  if (item.type !== "line") {
    return null;
  }

  if (item.dischargedText) {
    return (
      <Box className={classes.timelineContent}>
        <div className={classes.dischargedContent}>
          <div className={classes.leftContent}>
            <Typography className={classes.daysApproved}>{item.mainText}</Typography>
            <Typography className={classes.dateRange}>{item.subText}</Typography>
          </div>

          <Typography className={classes.dischargedText}>{item.dischargedText}</Typography>
        </div>
      </Box>
    );
  }

  return (
    <Box className={classes.timelineContent}>
      <Typography className={classes.daysApproved}>{item.mainText}</Typography>
      <Typography className={classes.dateRange} style={{ minHeight: item.subText !== undefined ? "19px" : "0px" }}>
        {item.subText}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles()(() => ({
  timelineContent: {
    borderRadius: "8px",
    backgroundColor: "#fff",
    marginBottom: "4px",
    position: "relative",
    width: "100%",
    paddingTop: "12px",
  },
  dateRange: {
    fontSize: "12px",
    position: "relative",
    color: "#666",
    display: "block",
  },
  daysApproved: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#333",
  },
  dischargedContent: {
    display: "flex",
    justifyContent: "space-between",
    textAlign: "center",
  },
  leftContent: {
    textAlign: "left",
  },
  dischargedText: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#333",
  },
}));
