import { Body1, Caption, colorsLight, formatDateStrWithTz, InlineButton, Sanitized } from "@coherehealth/common";
import { ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { Body2 } from "@coherehealth/design-system";
import { ButtonBase, Grid, makeStyles } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { HistoryAppeal } from "components/ClinicalReview/ClinicalReviewInfoPanel/NotesCard/notesCardTypes";
import { ClinicalReviewContext } from "components/ClinicalReview/Review/ClinicalReviewPage";
import { LeftPanelTabs } from "components/ClinicalReview/reviewUtils/utils";
import { requestorTypeDisplayName } from "components/ContactInfoModal";
import { channelDisplayName } from "components/PatientSummary/AuthorizationPatientSummary/RequestedByInfo";
import { ReviewOutcomeOption } from "components/ServiceRequest/ReviewSection/util/QueueManagementReviewUtil";
import { format } from "date-fns";
import { useContext, useState } from "react";

const AppealOutcomesToDisplayValue: Record<string, string> = {
  APPROVED: "approval",
  PARTIALLY_APPROVED: "partial approval",
  DENIED: "denial",
  NOT_COMPLETED: "Not completed",
  WITHDRAWN: "Withdrawn",
};

const generateAppealOutcomeSummary = (appeal: HistoryAppeal, serviceRequest: ServiceRequestResponse) => {
  const { initialDecisionDisposition } = serviceRequest ?? {};
  const appealOutcomeDisplayValue = AppealOutcomesToDisplayValue[appeal.outcome];

  if (["DENIED", "APPROVED", "PARTIALLY_APPROVED"].includes(appeal.outcome)) {
    if (appeal.outcome === initialDecisionDisposition) {
      return `Uphold initial ${appealOutcomeDisplayValue}`;
    } else {
      return `Overturned to ${appealOutcomeDisplayValue}`;
    }
  }
  // Not Completed or Withdrawn
  return appealOutcomeDisplayValue;
};

interface AppealViewOnlyProps {
  appeal: HistoryAppeal;
  serviceRequest: ServiceRequestResponse;
  setTabOpen?: React.Dispatch<React.SetStateAction<LeftPanelTabs>>;
}

export const AppealViewOnly = ({ appeal, serviceRequest, setTabOpen }: AppealViewOnlyProps) => {
  const [expanded, setExpanded] = useState(false);
  // If user is trying to highlight text, don't collapse the card
  const toggleExpanded = () => {
    if (!((window.getSelection()?.toString().length || 0) > 0)) {
      setExpanded(!expanded);
    }
  };

  const { component: AppealDetailsComponent } = getAppealConfig(appeal) ?? {};
  if (!AppealDetailsComponent) {
    return null;
  }

  return (
    <Grid
      data-testid="appeal-view-only-container"
      container
      style={{ marginTop: 0 }}
      direction="column"
      alignItems="flex-start"
    >
      <Grid container direction="column">
        <AppealHeader
          appeal={appeal}
          serviceRequest={serviceRequest}
          expanded={expanded}
          toggleExpanded={toggleExpanded}
        />
        <Collapse in={expanded} timeout={100} unmountOnExit style={{ margin: 0 }}>
          <AppealDetailsComponent appeal={appeal} setTabOpen={setTabOpen} />
        </Collapse>
      </Grid>
    </Grid>
  );
};

// Expandable header containing the appeal note
interface AppealHeaderProps {
  appeal: HistoryAppeal;
  serviceRequest: ServiceRequestResponse;
  expanded: boolean;
  toggleExpanded: () => void;
}

function AppealHeader({ appeal, serviceRequest, expanded, toggleExpanded }: AppealHeaderProps) {
  const { allowedReviewOutcomes } = useContext(ClinicalReviewContext);
  const appealOutcome = allowedReviewOutcomes?.find((outcome) => outcome.id === appeal.outcome);
  const outcomeSummary = generateAppealOutcomeSummary(appeal, serviceRequest);
  const { title, generateCaption } = getAppealConfig(appeal) ?? {};
  const caption = generateCaption?.(appeal, appealOutcome, outcomeSummary);

  const style = appealViewOnlyStyle({ expanded });

  return (
    <ButtonBase component="div" className={style.appealHeader} onClick={toggleExpanded}>
      <Grid container direction="column">
        <Grid item container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item container direction="row" xs={11} alignItems="center">
            <Body2 style={{ paddingRight: "16px" }}>{title}</Body2>
            <Caption className={style.appealCaption}>{caption}</Caption>
          </Grid>
          <ExpandMoreIcon className={style.caretIcon} data-testid={`appeal-header-expand-icon-${appeal.id}`} />
        </Grid>
        <Grid item data-testid="post-appeal-note-read-only">
          <Body1 component="div">
            <Sanitized
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                textShadow: "0 0 0.5px rgba(0, 0, 0, 0.5)",
                fontWeight: 400,
                display: "-webkit-inline-box",
                WebkitBoxOrient: "vertical",
                lineClamp: !expanded ? 3 : undefined,
                WebkitLineClamp: !expanded ? 3 : undefined,
              }}
              __html={appeal?.postAppealNote}
              className={style.postAppealNoteRichText}
            />
          </Body1>
        </Grid>
      </Grid>
    </ButtonBase>
  );
}

// Additional appeal info, including the View Attachments button
// Only shows when AppealHeader is expanded
const AppealInitiationDetails = ({
  appeal,
  setTabOpen,
  expanded = false,
}: Omit<AppealViewOnlyProps, "serviceRequest"> & { expanded?: boolean }) => {
  const style = appealViewOnlyStyle({ expanded });

  return (
    <Grid container direction="column" alignItems="flex-start">
      <Grid container direction="row">
        <Grid item xs={3} className={style.appealInitiationRowGrid} data-testid="received-date-read-only">
          <Caption className={style.appealCaption}>Received date</Caption>
          <Body1>
            {appeal.appealInitiationTimestamp ? format(new Date(appeal.appealInitiationTimestamp), "MM/dd/yyyy") : "--"}
          </Body1>
        </Grid>
        <Grid item xs={3} className={style.appealInitiationRowGrid} data-testid="received-time-read-only">
          <Caption className={style.appealCaption}>Received time (24-hour)</Caption>
          <Body1>
            {appeal.appealInitiationTimestamp ? format(new Date(appeal.appealInitiationTimestamp), "hh:mm") : "--"}
          </Body1>
        </Grid>
        <Grid item xs={3} className={style.appealInitiationRowGrid} data-testid="requestor-type-read-only">
          <Caption className={style.appealCaption}>Requestor type</Caption>
          <Body1>{appeal.requestorType ? requestorTypeDisplayName[appeal.requestorType] : "--"}</Body1>
        </Grid>
        <Grid item xs={3} className={style.appealInitiationRowGrid} data-testid="format-received-read-only">
          <Caption className={style.appealCaption}>Format received</Caption>
          <Body1>{appeal.appealChannel ? channelDisplayName[appeal.appealChannel] : "--"}</Body1>
        </Grid>
        <Grid item xs={12} className={style.appealInitiationRowGrid} data-testid="case-description-read-only">
          <Caption className={style.appealCaption}>Case description</Caption>
          <Body1>{appeal.caseDescription ?? "--"}</Body1>
        </Grid>
        <Grid item xs={12} className={style.appealInitiationRowGrid} data-testid="expedited-read-only">
          <Caption className={style.appealCaption}>Expedited</Caption>
          <Body1>{appeal.isExpedited ? "Yes" : "No"}</Body1>
        </Grid>
      </Grid>
      <Grid item className={style.buttonContainer}>
        <InlineButton
          data-testid={`view-appeal-initiation-attachments-${appeal.id}`}
          onClick={() => setTabOpen?.("ATTACHMENTS")}
        >
          {"View appeal initiation attachments"}
        </InlineButton>
      </Grid>
    </Grid>
  );
};

const AppealLogDetails = ({
  appeal,
  setTabOpen,
  expanded = false,
}: Omit<AppealViewOnlyProps, "serviceRequest"> & { expanded?: boolean }) => {
  const style = appealViewOnlyStyle({ expanded });

  return (
    <Grid container direction="column" alignItems="flex-start">
      <Grid container direction="row">
        <Grid item xs={4} data-testid="post-appeal-type-read-only">
          <Caption className={style.appealCaption}>Appeal Type</Caption>
          <Body1>{appeal.postAppealType ?? "--"}</Body1>
        </Grid>
        <Grid item xs={4} data-testid="appeal-requested-by-read-only">
          <Caption className={style.appealCaption}>Appeal Requested By</Caption>
          <Body1>{appeal?.requestorType ?? "--"}</Body1>
        </Grid>
        <Grid item xs={4} data-testid="received-date-read-only">
          <Caption className={style.appealCaption}>Initiation date</Caption>
          <Body1>
            {appeal.appealInitiationTimestamp ? format(new Date(appeal.appealInitiationTimestamp), "MM/dd/yyyy") : "--"}
          </Body1>
        </Grid>
        <Grid item xs={4} data-testid="decisioned-date-read-only">
          <Caption className={style.appealCaption}>Determination date</Caption>
          <Body1>
            {appeal.appealDeterminationDate ? format(new Date(appeal.appealDeterminationDate), "MM/dd/yyyy") : "--"}
          </Body1>
        </Grid>
        <Grid item xs={4} data-testid="coverage-ended-by-read-only">
          <Caption className={style.appealCaption}>Coverage ended by</Caption>
          <Body1>{appeal?.coverageEndedBy ?? "--"}</Body1>
        </Grid>
      </Grid>
      <Grid item className={style.buttonContainer}>
        <InlineButton
          data-testid={`view-post-appeal-attachments-${appeal.id}`}
          onClick={() => setTabOpen?.("ATTACHMENTS")}
        >
          {"View post-appeal attachments"}
        </InlineButton>
      </Grid>
    </Grid>
  );
};

const getAppealConfig = (appeal: HistoryAppeal) => {
  const appealConfigs = {
    APPEAL_INITIATION: {
      title: "Appeal initiation",
      generateCaption: (appeal: HistoryAppeal) =>
        `${formatDateStrWithTz(appeal?.dateCreated)} by ${appeal?.createdByName}`,
      component: AppealInitiationDetails,
    },
    APPEAL_LOG: {
      title: "Post-appeal note",
      generateCaption: (appeal: HistoryAppeal, appealOutcome?: ReviewOutcomeOption, outcomeText?: string) =>
        `${appealOutcome?.label ?? outcomeText} on ${formatDateStrWithTz(appeal?.dateCreated)} by ${
          appeal?.createdByName
        }`,
      component: AppealLogDetails,
    },
  };

  if (appeal.appealType) {
    return appealConfigs[appeal.appealType] ?? null;
  }
  return null;
};

const appealViewOnlyStyle = makeStyles((theme) => ({
  postAppealNoteRichText: {
    "& *": {
      margin: 0,
    },
    "& br": {
      marginBottom: theme.spacing(3),
      content: '""',
      display: "block",
    },
  },
  appealInitiationRowGrid: {
    marginTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  buttonContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  appealCaption: {
    color: colorsLight.font.secondary,
  },
  appealHeader: {
    marginTop: 0,
    marginRight: 1,
    marginLeft: 1,
    display: "flex",
    justifyContent: "space-between",
    textAlign: "start",
    padding: theme.spacing(2, 0),
    userSelect: "text",
    cursor: "pointer",
  },
  caretIcon: (props: { expanded?: boolean }) => ({
    transition: theme.transitions.create("transform"),
    transform: props.expanded ? "rotate(180deg)" : "rotate(0deg)",
    height: theme.spacing(3),
    width: theme.spacing(3),
    marginBottom: "auto",
  }),
}));
