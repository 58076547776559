import { useState, useMemo, useEffect, Dispatch, SetStateAction, useCallback } from "react";
import {
  ProcedureCode,
  ServiceRequestResponse,
  useGetClinicalServiceDetermination,
  ProcedureCodeGroupOptions,
  ServiceDeterminationQuestionOption,
  useGetClinicalService,
  ClinicalService,
} from "@coherehealth/core-platform-api";
import ServicesSelect from "./ServicesSelect";
import { formatDateToISODate, withId } from "@coherehealth/common";
import { useSnackbar } from "notistack";
import { createOptionsFromBuckets } from "components/AuthBuilder/ServiceSelectionFlexibleIntake/ServiceSelectionFlexibleIntake";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import { constructProcedureCodes } from "util/clinicalAssessment";

interface Props {
  selectedProcedureCodes: ProcedureCode[];
  setSelectedProcedureCodes: Dispatch<SetStateAction<ProcedureCode[]>>;
  serviceRequest: Partial<ServiceRequestResponse>;
  servicesFormContents: ServiceRequestFormContent[];
  setServiceFormContent: Dispatch<SetStateAction<ServiceRequestFormContent[]>>;
  requestType?: string;
  formContent: ServiceRequestFormContent;
  nonPalProcedureCodes: ProcedureCode[];
}

export default function ServicesSelector({
  selectedProcedureCodes,
  setSelectedProcedureCodes,
  serviceRequest,
  servicesFormContents,
  setServiceFormContent,
  formContent,
  requestType,
  nonPalProcedureCodes,
}: Props) {
  const [pxCodeGroupOptions, setPxCodeGroupOptions] = useState<ProcedureCodeGroupOptions[]>([]);
  const [selectedServices, setSelectedServices] = useState<Record<string, string[]>>({});

  const { enqueueSnackbar } = useSnackbar();
  const { refetch: getClinicalService } = useGetClinicalService({
    lazy: true,
    id: "",
  });
  const {
    mutate: getClinicalServiceDetermination,
    loading: serviceDeterminationLoading,
    error: serviceDeterminationError,
  } = useGetClinicalServiceDetermination({});
  const basePayload = useMemo(
    () => ({
      patientId: serviceRequest.patient?.id,
      encounterType: serviceRequest.encounterType,
      authCategory: serviceRequest.authCategory?.enumName,
      primarySemanticDiagnosisCode: serviceRequest.primaryDiagnosis,
      secondarySemanticDiagnosisCodes: serviceRequest.secondaryDiagnoses || [],
      serviceStartDate: formatDateToISODate(serviceRequest.startDate),
      selectedPlaceOfServiceId: serviceRequest.placeOfService?.id,
      groupProcedureCodes: true,
    }),
    [serviceRequest]
  );

  useEffect(() => {
    if (selectedProcedureCodes && selectedProcedureCodes.length > 0) {
      setSelectedServices((currSelectedServices) =>
        selectedProcedureCodes.reduce((acc, procedureCode) => {
          return {
            ...acc,
            [procedureCode.code]:
              currSelectedServices[procedureCode.code] && currSelectedServices[procedureCode.code].length > 0
                ? currSelectedServices[procedureCode.code]
                : servicesFormContents
                    .filter((content) => {
                      return content.procedureCodes.some((code) => code.code === procedureCode.code);
                    })
                    .map((content) => content.clinicalService?.id || "Uncategorized Service"),
          };
        }, {})
      );
    }
  }, [selectedProcedureCodes, servicesFormContents]);

  useEffect(() => {
    if (serviceDeterminationError) {
      enqueueSnackbar("Failed to get services, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [serviceDeterminationError, enqueueSnackbar]);

  useEffect(() => {
    if (selectedProcedureCodes && selectedProcedureCodes.length > 0) {
      getClinicalServiceDetermination({
        ...basePayload,
        semanticProcedureCodes: selectedProcedureCodes,
      }).then(async (response) => {
        setPxCodeGroupOptions([
          ...(response.procedureCodeGroupOptions || []),
          ...createOptionsFromBuckets(response.buckets),
        ]);
      });
    }
  }, [basePayload, getClinicalServiceDetermination, selectedProcedureCodes]);

  const changeSelectedServices = (code: string) => {
    return (selectedIds: string[]) => {
      setSelectedServices((selectedServices) => {
        return { ...selectedServices, [code]: selectedIds };
      });
    };
  };

  const remove = useCallback(
    (removedCode: string) => {
      return () => {
        setSelectedServices((currSelectedServices) =>
          selectedProcedureCodes.reduce((acc, procedureCode) => {
            if (procedureCode.code === removedCode) {
              return { ...acc, [procedureCode.code]: [] };
            }
            return { ...acc, [procedureCode.code]: currSelectedServices[procedureCode.code] };
          }, {})
        );
        setSelectedProcedureCodes((selectedProcedureCodes) =>
          selectedProcedureCodes.filter((procedureCore) => procedureCore.code !== removedCode)
        );
      };
    },
    [selectedProcedureCodes, setSelectedProcedureCodes]
  );

  const add = useCallback(
    (addedCode: string) => {
      const currentServices = servicesFormContents
        .filter((content) => {
          return content.procedureCodes.some((code) => code.code === addedCode);
        })
        .map((content) => content.clinicalService?.id || "Uncategorized Service");
      const codeSelectedServices = selectedServices[addedCode] || [];
      const servicesToBeDeleted = currentServices.filter((service) => !codeSelectedServices.includes(service));
      const servicesToBeAdded = codeSelectedServices.filter((service) => !currentServices.includes(service));
      return async () => {
        const removedServicesFormContents = servicesFormContents
          .filter(
            //delete unused services
            (serviceFormContent) =>
              !(
                servicesToBeDeleted.includes(serviceFormContent.clinicalService?.id || "Uncategorized Service") &&
                serviceFormContent.procedureCodes.length === 1
              )
          )
          .map((serviceFormContent) => {
            // remove code from services still in use
            if (servicesToBeDeleted.includes(serviceFormContent.clinicalService?.id || "Uncategorized Service")) {
              return {
                ...serviceFormContent,
                procedureCodes: serviceFormContent.procedureCodes.filter((pxcode) => pxcode.code !== addedCode),
              };
            }
            return serviceFormContent;
          });
        const updatedServicesFormContents: ServiceRequestFormContent[] = removedServicesFormContents.map(
          (serviceFormContent) => {
            //add code to a service already in the sr
            if (servicesToBeAdded.includes(serviceFormContent.clinicalService?.id || "Uncategorized Service")) {
              const newProcedureCode = constructProcedureCodes({
                procedureCodes: [
                  selectedProcedureCodes.find((pxCode) => pxCode.code === addedCode) || ({} as ProcedureCode),
                ],
                prevIsInpatient: false,
                isInpatient: serviceFormContent.isInpatient,
                prevServiceLevelUnits: serviceFormContent.units,
                serviceLevelUnits: serviceFormContent.units,
                clinicalService: serviceFormContent.clinicalService,
                isUnitsOnPx: serviceFormContent.clinicalService?.isUnitsOnPx || false,
                isContinuation: requestType === "CONTINUATION",
              }).map(withId)[0];
              return {
                ...serviceFormContent,
                procedureCodes: [...serviceFormContent.procedureCodes, newProcedureCode],
                units: (serviceFormContent.clinicalService?.isUnitsOnPx
                  ? parseInt(serviceFormContent.units) + (newProcedureCode.units || 0)
                  : (newProcedureCode.units || 0) > parseInt(serviceFormContent.units)
                  ? newProcedureCode.units || 0
                  : serviceFormContent.units
                ).toString(),
              };
            }
            return serviceFormContent;
          }
        );
        const newServicesPromises = servicesToBeAdded.reduce((servicePromises, service) => {
          //fetch new clinical services
          if (
            !updatedServicesFormContents.find(
              (formContent) => (formContent.clinicalService?.id || "Uncategorized Service") === service
            )
          ) {
            return [
              ...servicePromises,
              service === "Uncategorized Service"
                ? new Promise<undefined>((resolve) => resolve(undefined))
                : getClinicalService({ pathParams: { id: service } }),
            ];
          }
          return servicePromises;
        }, [] as Promise<ClinicalService | null | undefined>[]);

        const newServices = await Promise.all(newServicesPromises);
        const newServicesFormContents = newServices.reduce((serviceFormContents, clinicalService) => {
          if (clinicalService !== null) {
            const newProcedureCode = constructProcedureCodes({
              procedureCodes: [
                selectedProcedureCodes.find((pxCode) => pxCode.code === addedCode) || ({} as ProcedureCode),
              ],
              prevIsInpatient: false,
              isInpatient: formContent.isInpatient,
              prevServiceLevelUnits: "0",
              serviceLevelUnits: "0",
              clinicalService: clinicalService,
              isUnitsOnPx: clinicalService?.isUnitsOnPx || false,
              isContinuation: requestType === "CONTINUATION",
            }).map(withId)[0];
            const newFormContent: ServiceRequestFormContent = {
              units: (newProcedureCode.units || 0).toString(),
              clinicalService: clinicalService,
              procedureCodes: [newProcedureCode],
              unitType: clinicalService?.defaultUnitType,
              isInpatient: formContent.isInpatient,
              startDate: formContent.startDate,
              approvedUnits: formContent.approvedUnits,
              isExpedited: formContent.isExpedited,
              expeditedReason: formContent.expeditedReason,
              userSelectedNonPalCode: formContent.userSelectedNonPalCode || false,
              userSelectedOONException: formContent.userSelectedOONException || false,
              id: formContent.id,
              cohereId: formContent.cohereId,
            };
            return [...serviceFormContents, newFormContent];
          }
          return serviceFormContents;
        }, updatedServicesFormContents);

        setServiceFormContent(newServicesFormContents);

        setSelectedServices((currSelectedServices) =>
          selectedProcedureCodes.reduce((acc, procedureCode) => {
            if (procedureCode.code === addedCode) {
              return { ...acc, [procedureCode.code]: [] };
            }
            return { ...acc, [procedureCode.code]: currSelectedServices[procedureCode.code] };
          }, {})
        );
        setSelectedProcedureCodes((selectedProcedureCodes) =>
          selectedProcedureCodes.filter((procedureCore) => procedureCore.code !== addedCode)
        );
      };
    },
    [
      formContent.approvedUnits,
      formContent.cohereId,
      formContent.expeditedReason,
      formContent.id,
      formContent.isExpedited,
      formContent.isInpatient,
      formContent.startDate,
      formContent.userSelectedNonPalCode,
      formContent.userSelectedOONException,
      getClinicalService,
      requestType,
      selectedProcedureCodes,
      selectedServices,
      servicesFormContents,
      setSelectedProcedureCodes,
      setServiceFormContent,
    ]
  );
  return (
    <div>
      {selectedProcedureCodes.map((procedureCode) => {
        const serviceOptions = pxCodeGroupOptions.reduce((acc, groupOption) => {
          if (groupOption.codes?.some((code) => code.code === procedureCode.code)) {
            return [...acc, ...(groupOption.serviceOptions || [])];
          }
          return acc;
        }, [] as ServiceDeterminationQuestionOption[]);
        return (
          <ServicesSelect
            procedureCode={procedureCode}
            serviceOptions={serviceOptions}
            selectedServicesIds={selectedServices[procedureCode.code] || []}
            changeSelectedServices={changeSelectedServices(procedureCode.code)}
            onAdd={add(procedureCode.code)}
            onRemove={remove(procedureCode.code)}
            servicesLoading={serviceDeterminationLoading}
            nonPalProcedureCodes={nonPalProcedureCodes}
            isInpatient={formContent.isInpatient}
          />
        );
      })}
    </div>
  );
}
