import {
  AuthStatus,
  MdReview,
  PeerToPeerReview,
  ReviewOutcome,
  ServiceRequestResponse,
  WithdrawRequestor,
} from "@coherehealth/core-platform-api";
import {
  FacilityBasedReviewOutcomeReasonOptions,
  reviewOutcomeReasonOptions,
  claimsReasonLabel,
  insertAtIndex,
} from "components/ClinicalReview/reviewUtils/utils";
import { ReviewOutcomeOption } from "../../util/QueueManagementReviewUtil";

import { GenericDoctorReview } from "components/ClinicalReview/reviewUtils/useReviews";
import { withdrawnReasonOptions } from "util/serviceRequest";
import { withdrawalReasonDropdownOptionChangeHandler } from "components/ServiceRequestStatusDisplay/utils";
import { DisplayReviewOutcome } from "./mdReviewModalTypes";

export const PeerToPeerReviewOutcomeOptionsRR: ReviewOutcomeOption[] = [
  { id: "APPROVED", label: "Approved" },
  { id: "DENIED", label: "Denied" },
];
export const MdReviewOutcomeOptionsRR: ReviewOutcomeOption[] = [
  { id: "APPROVED", label: "Approved" },
  { id: "RECOMMENDED_DENIAL", label: "Recommended denial" },
  { id: "PENDING_MISSING_CLINICAL_INFO", label: "Pending missing info" },
  { id: "PENDING_ADMIN_VOID", label: "Pending admin void" },
];

export const MdReviewOutcomeOptions: ReviewOutcomeOption[] = [
  { id: "APPROVED", label: "Approved" },
  { id: "RECOMMENDED_DENIAL", label: "Recommended denial" },
  { id: "RECOMMENDED_PARTIAL_APPROVAL", label: "Recommended partial approval" },
  { id: "PENDING_MISSING_CLINICAL_INFO", label: "Pending missing info" },
  { id: "PENDING_ADMIN_VOID", label: "Pending admin void" },
  { id: "WITHDRAWN", label: "Withdrawn" },
];

export const PeerToPeerReviewOutcomeOptionsStandard: ReviewOutcomeOption[] = [
  { id: "APPROVED", label: "Approved" },
  { id: "RECOMMENDED_DENIAL", label: "Recommended denial" },
  { id: "RECOMMENDED_PARTIAL_APPROVAL", label: "Recommended partial approval" },
  { id: "WITHDRAWN", label: "Withdrawn" },
  { id: "PENDING_MISSING_CLINICAL_INFO", label: "Pending missing info" },
];

export const NoteLabel: Record<string, string> = {
  APPROVED: "Approval notes",
  UPHOLD_INITIAL_APPROVAL: "Approval notes",
  WITHDRAWN: "Explanation for withdrawal",
  PENDING_MISSING_CLINICAL_INFO: "Provide instructions for the info intake needs to gather",
};

export const NoteDeniedLabel: Record<string, string> = {
  DENIED: "Uphold denial notes",
};

export const ClaimDenialReasons = [
  { id: "MN", label: "The services were not medically necessary" },
  { id: "NS", label: "There is no record that the services were rendered" },
];

export const ReasonLabel: Record<string, string> = {
  APPROVED: "Approval reason",
};
export const mdReviewOutcomeOptionsForHealthPlan = (healthPlanName: string | undefined): ReviewOutcomeOption[] => {
  if (healthPlanName === "Geisinger") {
    return [
      { id: "APPROVED", label: "Approved" },
      { id: "PENDING_SCHEDULING_OUTREACH", label: "Pending peer to peer outreach" },
      { id: "WITHDRAWN", label: "Withdrawn" },
      { id: "PENDING_RN_REVIEW", label: "Pending clinical review" },
      { id: "RECOMMENDED_DENIAL", label: "Recommended denial" },
      { id: "RECOMMENDED_PARTIAL_APPROVAL", label: "Recommended partial approval" },
    ];
  } else if (healthPlanName === "Humana") {
    return [...MdReviewOutcomeOptions, { id: "OUT_OF_SCOPE", label: "Out of scope - send to Humana" }];
  } else {
    return MdReviewOutcomeOptions;
  }
};

export function useMdOutcomeOptions(
  serviceRequest: ServiceRequestResponse,
  isQmCase: boolean,
  lessThan50percentTat: boolean,
  featureFlagData: {
    outOfScopeEnabled: boolean;
  },
  hasRecDeniedOONDecision?: boolean
): ReviewOutcomeOption[] {
  let reviewOutcomeOptions = serviceRequest.reconClaim
    ? MdReviewOutcomeOptionsRR
    : mdReviewOutcomeOptionsForHealthPlan(serviceRequest.healthPlanName);
  if (!featureFlagData.outOfScopeEnabled || lessThan50percentTat) {
    reviewOutcomeOptions = reviewOutcomeOptions.filter((opt) => opt.id !== "OUT_OF_SCOPE");
  }
  if (hasRecDeniedOONDecision) {
    reviewOutcomeOptions = reviewOutcomeOptions.filter((opt) => opt.id !== "APPROVED");
  }
  if (isQmCase) {
    reviewOutcomeOptions = reviewOutcomeOptions.concat([{ id: "CANNOT_WORK", label: "Cannot work" }]);
  }
  return reviewOutcomeOptions;
}

export function usePeerToPeerOutcomeOptions(
  isPostDenialP2P: boolean,
  serviceRequest: ServiceRequestResponse,
  lessThan50percentTat: boolean,
  showOverturn: boolean,
  canSubmitDenial: boolean,
  featureFlagData: {
    outOfScopeEnabled: boolean;
    overturnToPartialApprovalEnabled?: boolean;
  },
  hasRecDeniedOONDecision?: boolean
): ReviewOutcomeOption[] {
  const isMMOPostDenialP2P =
    (isPostDenialP2P || serviceRequest.initialDecisionDisposition === "DENIED") &&
    serviceRequest.healthPlanName === "Medical Mutual of Ohio";

  if (isPostDenialP2P || isMMOPostDenialP2P) {
    const postDenialOptions: ReviewOutcomeOption[] = [];
    if (serviceRequest.initialDecisionDisposition === "DENIED") {
      postDenialOptions.push({ id: "DENIED", label: "Uphold initial denial" });
    } else if (serviceRequest.initialDecisionDisposition === "PARTIALLY_APPROVED") {
      if (!hasRecDeniedOONDecision) {
        postDenialOptions.push({ id: "APPROVED", label: "Overturn to approval" });
      }
      postDenialOptions.push({ id: "PARTIALLY_APPROVED", label: "Uphold initial partial approval" });
    } else if (serviceRequest.initialDecisionDisposition === "APPROVED") {
      postDenialOptions.push({ id: "UPHOLD_INITIAL_APPROVAL", label: "Uphold" });
    }
    if (showOverturn) {
      if (
        serviceRequest.initialDecisionDisposition !== "APPROVED" &&
        !postDenialOptions.find((opt) => opt.label === "Overturn to approval")
      ) {
        postDenialOptions.push({ id: "APPROVED", label: "Overturn to approval" });
      }
      if (featureFlagData.overturnToPartialApprovalEnabled) {
        if (serviceRequest.initialDecisionDisposition === "DENIED") {
          postDenialOptions.push({ id: "OVERTURN_TO_PARTIALLY_APPROVED", label: "Overturn to partial approval" });
        }
        if (serviceRequest.initialDecisionDisposition === "PARTIALLY_APPROVED") {
          postDenialOptions.push({ id: "OVERTURN_TO_PARTIALLY_APPROVED", label: "Update initial partial approval" });
        }
      }
    }
    const alreadyHasOverturnToPartialApproval = postDenialOptions.some(
      (opt) => opt.id === "OVERTURN_TO_PARTIALLY_APPROVED"
    );
    if (isMMOPostDenialP2P && !alreadyHasOverturnToPartialApproval) {
      postDenialOptions.push({ id: "OVERTURN_TO_PARTIALLY_APPROVED", label: "Overturn to partial approval" });
    }
    return postDenialOptions;
  } else if (serviceRequest.reconClaim) {
    return PeerToPeerReviewOutcomeOptionsRR;
  } else {
    let reviewOutcomeOptions = PeerToPeerReviewOutcomeOptionsStandard;
    if (canSubmitDenial) {
      reviewOutcomeOptions = insertAtIndex(
        reviewOutcomeOptions,
        1,
        { id: "PARTIALLY_APPROVED", label: "Partially approved" },
        { id: "DENIED", label: "Denied" }
      );
    }
    if (serviceRequest.healthPlanName === "Humana" && featureFlagData.outOfScopeEnabled && !lessThan50percentTat) {
      reviewOutcomeOptions = [...reviewOutcomeOptions, { id: "OUT_OF_SCOPE", label: "Out of scope - send to Humana" }];
    }
    if (hasRecDeniedOONDecision) {
      reviewOutcomeOptions = reviewOutcomeOptions.filter((outcomeOptions) => outcomeOptions.id !== "APPROVED");
    }
    return reviewOutcomeOptions;
  }
}
export const getTextFieldProps = (
  review: MdReview | PeerToPeerReview | GenericDoctorReview,
  serviceRequest: ServiceRequestResponse,
  isPostDenialP2P?: boolean,
  newDenialsWorkflow?: boolean
) => {
  const { reviewOutcome, serviceStatusCode } = review;
  const isGhpRequest = serviceRequest.healthPlanName === "Geisinger";
  const isDenialOutcome = [
    "RECOMMENDED_DENIAL",
    "RECOMMENDED_PARTIAL_APPROVAL",
    "PARTIALLY_APPROVED",
    "DENIED",
  ].includes(reviewOutcome || "");

  const showNoteInputApprovalInfo =
    reviewOutcome &&
    ((reviewOutcome === "APPROVED" && !isGhpRequest) ||
      (reviewOutcome === "APPROVED" && isGhpRequest && isPostDenialP2P) ||
      reviewOutcome === "PENDING_MISSING_CLINICAL_INFO");
  const showNoteInputDenial =
    reviewOutcome &&
    ((reviewOutcome === "DENIED" && !isGhpRequest) || (reviewOutcome === "DENIED" && isGhpRequest && isPostDenialP2P));
  const showClaimsPaymentInput =
    reviewOutcome === "APPROVED" && isGhpRequest && ["TF00", "6?00", "D900"].includes(review.serviceStatusCode || "");
  const showDenialLanguageInput =
    !newDenialsWorkflow && isDenialOutcome && !isPostDenialP2P && !serviceRequest.reconClaim;
  if (showDenialLanguageInput) {
    return {
      label: "Denial language",
      value: review.denialLanguage,
      fieldName: "denialLanguage",
    };
  }
  if (showNoteInputApprovalInfo) {
    return {
      label: NoteLabel[review.reviewOutcome || ""],
      value: review.authorizationNote,
      fieldName: "authorizationNote",
    };
  }
  if (showNoteInputDenial) {
    return {
      label: NoteDeniedLabel[review.reviewOutcome || ""],
      value: review.authorizationNote,
      fieldName: "authorizationNote",
    };
  }
  if (showClaimsPaymentInput) {
    return {
      label: claimsReasonLabel(serviceStatusCode || ""),
      value: review.claimsPaymentNote,
      fieldName: "claimsPaymentNote",
    };
  }
};

export const withdrawnReasonSelectOptions = (
  canViewAllWithdrawnReasons: boolean,
  serviceRequest: ServiceRequestResponse
) => {
  const { reviewOutcomeWithdrawOptions } = serviceRequest;
  return (
    reviewOutcomeWithdrawOptions ||
    withdrawnReasonOptions(canViewAllWithdrawnReasons, serviceRequest.id, !!serviceRequest.reconClaim)
  );
};

export const getReasonDisplayProps = (
  review: MdReview | PeerToPeerReview | GenericDoctorReview,
  serviceRequest: ServiceRequestResponse,
  canViewAllWithdrawnReasons: boolean,
  setReview: (reviewUpdate: any) => void,
  isPostDenialP2P?: boolean,
  facilityBasedFeatureEnabled?: boolean
) => {
  const isGhpRequest = serviceRequest.healthPlanName === "Geisinger";
  const denialReason =
    ["RECOMMENDED_DENIAL", "RECOMMENDED_PARTIAL_APPROVAL", "PARTIALLY_APPROVED", "DENIED"].includes(
      review.reviewOutcome || ""
    ) &&
    serviceRequest.reconClaim &&
    !isPostDenialP2P;
  const withdrawnReason = review.reviewOutcome === "WITHDRAWN";
  const approvalReason = review.reviewOutcome === "APPROVED" && isGhpRequest;
  const withdrawnOptions = withdrawnReasonSelectOptions(canViewAllWithdrawnReasons, serviceRequest);
  if (denialReason) {
    return {
      label: "Denial reason",
      options: ClaimDenialReasons,
      value: review.denialLanguage,
      onChange: (denialLanguage: any) => setReview(denialLanguage),
    };
  }
  if (withdrawnReason) {
    return {
      label: "Select reason for withdrawal",
      options: withdrawnOptions,
      onChange: (reason: string) => {
        const withdrawnReason: NonNullable<MdReview | PeerToPeerReview>["withdrawnReason"] = reason;
        withdrawalReasonDropdownOptionChangeHandler({
          dropdownEntryId: withdrawnReason,
          setFormState: setReview,
          withdrawalOptions: withdrawnOptions,
          setStateOverride: (id?: string, requestor?: WithdrawRequestor) => {
            setReview({ withdrawRequestor: requestor ?? "", withdrawnReason: id ?? "" });
          },
        });
      },
      value: review.withdrawnReason || "",
    };
  }
  if (approvalReason) {
    return {
      label: ReasonLabel[review.reviewOutcome || ""],
      options:
        (facilityBasedFeatureEnabled ? FacilityBasedReviewOutcomeReasonOptions : reviewOutcomeReasonOptions)?.filter(
          ({ outcome }) => review.reviewOutcome === outcome
        ) || [],
      onChange: (serviceStatusCode: any) => {
        setReview({ serviceStatusCode });
      },
      value: review.serviceStatusCode || "",
    };
  }
};
export const isPartialApprovalOutcome = (reviewOutcome?: string) => {
  if (
    reviewOutcome &&
    ["PARTIALLY_APPROVED", "RECOMMENDED_PARTIAL_APPROVAL", "OVERTURN_TO_PARTIALLY_APPROVED"].includes(reviewOutcome)
  ) {
    return true;
  } else {
    return false;
  }
};

export type ReviewReadmissionOutcomeOption = {
  id: "OVERTURN" | "UPHOLD";
  label: string;
};

export function useReviewReadmissionOutcomeOptionsFor(
  reviewOutcome: string | undefined,
  isReadmission?: boolean
): ReviewReadmissionOutcomeOption[] {
  const postDenialOptions: ReviewReadmissionOutcomeOption[] = [];
  if (isReadmission) {
    if (reviewOutcome === "DENIED" || reviewOutcome === "PARTIALLY_APPROVED") {
      postDenialOptions.push({ id: "UPHOLD", label: "Uphold initial readmission decision" });
      postDenialOptions.push({ id: "OVERTURN", label: "Overturn initial readmission decision" });
    }
    if (reviewOutcome === "APPROVED") {
      postDenialOptions.push({ id: "UPHOLD", label: "Uphold initial readmission decision" });
      postDenialOptions.push({ id: "OVERTURN", label: "Overturn initial readmission decision" });
    }
  }
  return postDenialOptions;
}
export const disableSaveButton = (
  serviceRequest: ServiceRequestResponse,
  review: MdReview | PeerToPeerReview | GenericDoctorReview,
  approvedPxCodesAreInvalid: () => boolean,
  OOSCheck: boolean,
  readmissionIdentificationEnabled: boolean,
  isReadmission: boolean,
  nextState?: AuthStatus,
  modalLoading?: boolean,
  newDenialsWorkflow?: boolean,
  reviewOutcomeSubmenuOptionsAvailable?: boolean
) => {
  const { healthPlanName, units } = serviceRequest;
  const {
    reviewOutcome,
    serviceStatusCode,
    withdrawnReason,
    withdrawRequestor,
    claimsPaymentNote,
    denialLanguage,
    readmissionReason,
    readmissionOutcome,
  } = review;

  const isPartialApproval = isPartialApprovalOutcome(reviewOutcome);
  const isGhpRequest = healthPlanName === "Geisinger";

  if (modalLoading) {
    return true;
  } else if (isPartialApproval && Boolean(units)) {
    return approvedPxCodesAreInvalid();
  } else if (reviewOutcome === "WITHDRAWN" && Boolean(withdrawnReason) && nextState?.includes("WITHDRAWN")) {
    return serviceRequest.withdrawRequestorOption && !withdrawRequestor;
  } else if (reviewOutcome === "WITHDRAWN") {
    return !Boolean(withdrawnReason);
  } else if (reviewOutcome === "OUT_OF_SCOPE" && !OOSCheck) {
    return true;
  } else if (reviewOutcome === "RECOMMENDED_DENIAL" && reviewOutcomeSubmenuOptionsAvailable) {
    return !Boolean(serviceStatusCode);
  } else if (
    !newDenialsWorkflow &&
    (isPartialApproval || reviewOutcome === "DENIED" || reviewOutcome === "RECOMMENDED_DENIAL")
  ) {
    if (reviewOutcome === "RECOMMENDED_PARTIAL_APPROVAL") {
      return !Boolean(denialLanguage);
    }
  } else if (isGhpRequest && reviewOutcome === "APPROVED" && ["TF00", "D900"].includes(serviceStatusCode || "")) {
    return !Boolean(claimsPaymentNote);
  } else if (readmissionIdentificationEnabled && isReadmission) {
    if (readmissionOutcome === "OVERTURN") {
      return false;
    } else if (readmissionOutcome === "UPHOLD" && readmissionReason) {
      return false;
    } else if (readmissionReason) {
      return false;
    }
    return true;
  } else if (
    serviceRequest.healthPlanName === "HealthPartners" && //TODO: config?
    reviewOutcomeSubmenuOptionsAvailable &&
    !Boolean(serviceStatusCode)
  ) {
    return true;
  } else {
    return false;
  }
};

export const getRadioButtonProps = (
  review: MdReview | PeerToPeerReview | GenericDoctorReview,
  serviceRequest: ServiceRequestResponse,
  readmissionIdentificationEnabled: boolean,
  isReadmission: boolean,
  nextState?: AuthStatus
) => {
  const showReadmit =
    (readmissionIdentificationEnabled && isReadmission && !review.readmissionOutcome) ||
    review.readmissionOutcome === "UPHOLD";
  const showWithdrawn =
    review.withdrawnReason &&
    review.reviewOutcome === "WITHDRAWN" &&
    serviceRequest.withdrawRequestorOption &&
    nextState?.includes("WITHDRAWN");

  if (showReadmit) {
    return {
      label: "Readmission reason",
      options: [
        { id: "RELATED", label: "Related" },
        { id: "COMPLICATION", label: "Complication" },
      ],
      name: "readmissionReason",
    };
  }
  if (showWithdrawn) {
    return {
      label: "Select who requested the withdrawal",
      options: [
        { id: "PATIENT", label: "Patient" },
        { id: "PROVIDER", label: "Provider" },
      ],
      name: "withdrawRequestor",
    };
  }
};

export const calculateReviewOutcomeFromDisplayReviewOutcome = (
  displayCaseOutcome: DisplayReviewOutcome
): ReviewOutcome | undefined => {
  switch (displayCaseOutcome) {
    case "CANNOT_WORK":
      return undefined;
    case "UPHOLD_INITIAL_APPROVAL":
      return "APPROVED";
    case "OVERTURN_TO_PARTIALLY_APPROVED":
      return "PARTIALLY_APPROVED";
    default:
      return displayCaseOutcome;
  }
};
