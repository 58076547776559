import {
  CopyPasteInfo,
  OutOfNetworkReview,
  OutOfNetworkReviewSubmitPayload,
  OutreachAttempt,
  SearchInfo,
  ServiceRequestResponse,
  useDiscardOutOfNetworkReview,
  useSubmitOutOfNetworkReview,
  useUpdateOutOfNetworkReview,
  useUserActivityBatchUpdate,
} from "@coherehealth/core-platform-api";
import { useOutOfNetworkReview } from "./useReviews";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import {
  createBatchActivities,
  getEmptyUserClickTracking,
  queueMgmtBaseUrl,
  stripHTMl,
  useFeature,
  UserClickInfoClinicalReviewPage,
} from "@coherehealth/common";
import { redirectToPatientSummaryFromReview, routeBackToOriginFromReview } from "util/routeUtils/routeUtils";
import { useTrackUserInteraction } from "util/userActivityTracker";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCompleteServiceCase } from "@coherehealth/qm-api";
import config from "api/config";
import { ClinicalReviewErrorDetails } from "components/ServiceRequest/ReviewSection/Modals/DetailedHardRedirectClinicalReviewModal";
import {
  defaultClinicalReviewErrorState,
  extractErrorDetails,
} from "components/ServiceRequest/ReviewSection/util/ReviewSectionUtil";
import { useSnackbar } from "notistack";
import { useServiceCaseForReview } from "components/ServiceRequest/ReviewSection/util/useReviewRouting";

export interface useOutOfNetworkReviewManagerProps {
  outOfNetworkReviewId: string;
  validateOutreachForm: () => boolean;
  hasOutreachAttempt: boolean;
  serviceRequest: ServiceRequestResponse;
  isQmUserAudited: boolean;
  saveOutreachAttempt?: () => Promise<OutreachAttempt | undefined>;
  userClickInfoTracking?: UserClickInfoClinicalReviewPage;
  searchInfos?: SearchInfo[];
  pastedAttachmentTexts?: CopyPasteInfo[];
  userId?: string;
  setUserClickInfoTracking?: Dispatch<SetStateAction<UserClickInfoClinicalReviewPage>>;
  setSearchInfos?: Dispatch<React.SetStateAction<SearchInfo[]>>;
  outreachAttemptLoading?: boolean;
  overrideRequired?: boolean;
}

type OONErrorStates = Partial<Record<keyof OutOfNetworkReview, boolean>>;

export interface OutOfNetworkReviewManagerProps {
  onFinishReview: () => void;
  errorStates: OONErrorStates;
  isSubmissionModalOpen: boolean;
  setIsSubmissionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  disableFinishModalButton: () => boolean;
  validateReview: () => boolean;
  submitOutOfNetworkReview: (embededMode?: boolean) => Promise<void>;
  saveReviewDraft: (embededMode?: boolean) => Promise<void>;
  discardReview: (embededMode?: boolean) => Promise<void>;
  duplicateReviewModalOpen: boolean;
  setDuplicateReviewModalOpen: Dispatch<SetStateAction<boolean>>;
  errorDetails: ClinicalReviewErrorDetails;
  openHardRedirectOnSubmitModal: boolean;
  setOpenHardRedirectOnSubmitModal: Dispatch<SetStateAction<boolean>>;
  loadingDiscardOrSave: boolean;
  savingReview: boolean;
  discardingReview: boolean;
  disableFinishButton: () => boolean;
  finishReviewLoading: boolean;
}

export const useOutOfNetworkReviewManager = ({
  outOfNetworkReviewId,
  validateOutreachForm,
  hasOutreachAttempt,
  serviceRequest,
  isQmUserAudited,
  saveOutreachAttempt,
  userClickInfoTracking,
  searchInfos,
  pastedAttachmentTexts,
  userId,
  setUserClickInfoTracking,
  setSearchInfos,
  outreachAttemptLoading,
  overrideRequired,
}: useOutOfNetworkReviewManagerProps): OutOfNetworkReviewManagerProps => {
  const [outOfNetworkReview, setOutOfNetworkReview] = useOutOfNetworkReview({ outOfNetworkReviewId });
  const [attemptedSubmitted, setAttemptedSubmitted] = useState(false);
  const [isSubmissionDisabled, setIsSubmissionDisabled] = useState<boolean>(false);
  const [isSubmissionModalOpen, setIsSubmissionModalOpen] = useState(false);
  const [reviewSubmissionLoading, setReviewSubmissionLoading] = useState<boolean>(false);
  const [duplicateReviewModalOpen, setDuplicateReviewModalOpen] = useState<boolean>(false);
  const [errorDetails, setErrorDetails] = useState<ClinicalReviewErrorDetails>(
    defaultClinicalReviewErrorState(serviceRequest.id, outOfNetworkReview?.id || "")
  );
  const [openHardRedirectOnSubmitModal, setOpenHardRedirectOnSubmitModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [search] = useSearchParams();
  const navigate = useNavigate();
  const getErrorStates = (attemptedSubmit: boolean) => {
    const errorStates = {
      oonExceptionReasoning: !Boolean(stripHTMl(outOfNetworkReview?.oonExceptionReasoning || "")),
      networkStatusOverride:
        Boolean(outOfNetworkReview?.networkStatusOverride?.isOutOfNetworkFacility === undefined && overrideRequired) ||
        Boolean(
          outOfNetworkReview?.networkStatusOverride?.isOutOfNetworkPerformingProvider === undefined && overrideRequired
        ),
    };
    return Object.fromEntries(
      Object.entries(errorStates).map(([key, keyErrorState]) => [key, keyErrorState && attemptedSubmit])
    );
  };

  const { mutate: discard, loading: discarding } = useDiscardOutOfNetworkReview({
    reviewId: outOfNetworkReview?.id || "undefined",
  });

  const { mutate: update, loading: updating } = useUpdateOutOfNetworkReview({
    reviewId: outOfNetworkReview?.id || "undefined",
    serviceRequestId: serviceRequest.id,
  });

  const { mutate: submit, loading: submitLoading } = useSubmitOutOfNetworkReview({
    reviewId: outOfNetworkReview?.id || "undefined",
    queryParams: {
      isUserAudited: isQmUserAudited,
    },
  });
  const shouldUseAppendedRoute = useFeature("shouldUseAppendedRoute");
  const { currentCase } = useServiceCaseForReview(serviceRequest);
  const caseId = shouldUseAppendedRoute && currentCase?.id ? currentCase?.id : search.get("caseId") || "";
  const referredByQM = caseId && document.referrer.includes(caseId);
  const {
    mutate: completeServiceCase,
    loading: completingServiceCase,
    error: completeServiceCaseError,
  } = useCompleteServiceCase({
    id: caseId,
    base: `${config.QM_SERVICE_API_URL}`,
  });
  useEffect(() => {
    if (completeServiceCaseError) {
      enqueueSnackbar(`Failed to complete Service Case: ${caseId}`, { variant: "error" });
    }
  }, [caseId, completeServiceCaseError, enqueueSnackbar]);
  const { mutate: postBatchActivities, loading: postingUserActivities } = useUserActivityBatchUpdate({});
  const trackUserActivityInteraction = useTrackUserInteraction();

  const loadingDiscardOrSave = discarding || updating || postingUserActivities;

  const disableFinishButton = () => {
    if (isSubmissionDisabled || loadingDiscardOrSave) {
      return true;
    }
    return false;
  };

  const validateReview = () => {
    setAttemptedSubmitted(true);
    const isOutreachFormValid = !hasOutreachAttempt || validateOutreachForm?.();
    const isValidOONForm = Object.values(getErrorStates(true)).every((errorState) => errorState === false);
    return Boolean(!disableFinishButton() && isValidOONForm && isOutreachFormValid);
  };
  const finishReviewLoading =
    reviewSubmissionLoading || submitLoading || completingServiceCase || !!outreachAttemptLoading;

  const disableFinishModalButton = () => {
    if (finishReviewLoading) {
      return true;
    } else if (!outOfNetworkReview?.reviewOutcome || !outOfNetworkReview?.outcomeReason) {
      return true;
    }

    return false;
  };

  const onFinishReview = () => {
    if (validateReview()) {
      setIsSubmissionModalOpen(true);
    }
  };

  const submitOutOfNetworkReview = useCallback(
    async (embeddedMode: boolean = false) => {
      setIsSubmissionDisabled(true);
      if (outOfNetworkReview?.reviewOutcome) {
        setReviewSubmissionLoading(true);

        const isStandaloneMode = typeof embeddedMode !== "boolean" || !embeddedMode;
        if (isStandaloneMode) {
          let payload: OutOfNetworkReviewSubmitPayload = {
            ...outOfNetworkReview,
            reviewOutcome: outOfNetworkReview.reviewOutcome,
            outcomeReason: outOfNetworkReview.outcomeReason,
          };
          try {
            await submit(payload, {
              queryParams: {
                isUserAudited: isQmUserAudited,
              },
            });
          } catch (error) {
            const submitError = error as ReturnType<typeof useSubmitOutOfNetworkReview>["error"];
            if (submitError) {
              setIsSubmissionModalOpen(false);
              if (submitError.status === 409) {
                setDuplicateReviewModalOpen(true);
              } else {
                setErrorDetails({
                  ...extractErrorDetails(submitError, serviceRequest.id, outOfNetworkReview.id),
                });
                setOpenHardRedirectOnSubmitModal(true);
              }
            }
          }
        }

        const postedOutreachAttempt = hasOutreachAttempt && (await saveOutreachAttempt?.());

        userClickInfoTracking &&
          (await postBatchActivities(
            createBatchActivities(
              "OUT_OF_NETWORK_REVIEW",
              "REVIEW_SUBMISSION",
              "INTERACTION",
              userClickInfoTracking,
              searchInfos,
              pastedAttachmentTexts,
              userId,
              outOfNetworkReview.id,
              serviceRequest.cohereId,
              serviceRequest.id
            )
          ));
        await trackUserActivityInteraction({
          event: "OUT_OF_NETWORK_REVIEW",
          stage: "REVIEW_SUBMISSION",
          activityContext: { reviewId: outOfNetworkReview.id, serviceRequestId: serviceRequest.id },
        });

        if (isStandaloneMode) {
          setUserClickInfoTracking?.(getEmptyUserClickTracking());
          setSearchInfos?.([]);

          if (Boolean(caseId) || (currentCase?.id && shouldUseAppendedRoute)) {
            try {
              await completeServiceCase({
                reviewOutcome: outOfNetworkReview.reviewOutcome,
                dateCompleted: new Date().toISOString(),
                reviewId: outOfNetworkReview.id,
                ...(postedOutreachAttempt
                  ? { outreachCaseOutcome: { outreachAttemptId: postedOutreachAttempt.id } }
                  : {}),
              });
            } finally {
              setIsSubmissionModalOpen(false);
              shouldUseAppendedRoute && currentCase?.id
                ? routeBackToOriginFromReview(
                    Boolean(referredByQM),
                    currentCase?.id,
                    outOfNetworkReviewId,
                    serviceRequest,
                    navigate
                  )
                : window.location.assign(`${queueMgmtBaseUrl()}/case_complete/${caseId}`);
            }
          } else {
            setIsSubmissionModalOpen(false);
            redirectToPatientSummaryFromReview({ serviceRequest, reviewId: outOfNetworkReview.id, navigate });
          }
        }
        setReviewSubmissionLoading(false);
      } else {
        setReviewSubmissionLoading(false);
      }
    },
    [
      outOfNetworkReview,
      hasOutreachAttempt,
      saveOutreachAttempt,
      userClickInfoTracking,
      postBatchActivities,
      searchInfos,
      pastedAttachmentTexts,
      userId,
      serviceRequest,
      trackUserActivityInteraction,
      submit,
      isQmUserAudited,
      setUserClickInfoTracking,
      setSearchInfos,
      caseId,
      currentCase?.id,
      shouldUseAppendedRoute,
      completeServiceCase,
      referredByQM,
      outOfNetworkReviewId,
      navigate,
    ]
  );

  const saveReviewDraft = useCallback(
    async (embeddedMode: boolean = false) => {
      const isStandaloneMode = typeof embeddedMode !== "boolean" || !embeddedMode;
      if (outOfNetworkReview) {
        try {
          if (isStandaloneMode) {
            try {
              const res = await update({ ...outOfNetworkReview });
              setOutOfNetworkReview?.(res);
            } catch (error) {
              const updateError = error as ReturnType<typeof useUpdateOutOfNetworkReview>["error"];
              enqueueSnackbar(`Error updating OON exception review: ${updateError?.message}`, { variant: "error" });
            }
          }

          userClickInfoTracking &&
            (await postBatchActivities(
              createBatchActivities(
                "OUT_OF_NETWORK_REVIEW",
                "REVIEW_SUBMISSION",
                "INTERACTION",
                userClickInfoTracking,
                searchInfos,
                pastedAttachmentTexts,
                userId,
                outOfNetworkReview.id,
                serviceRequest.cohereId,
                serviceRequest.id
              )
            ));
          if (isStandaloneMode) {
            setUserClickInfoTracking?.(getEmptyUserClickTracking());
            setSearchInfos?.([]);
            redirectToPatientSummaryFromReview({ serviceRequest, caseId, reviewId: outOfNetworkReview.id, navigate });
          }
        } catch {
          setDuplicateReviewModalOpen(true);
        }
      }
    },
    [
      update,
      outOfNetworkReview,
      setOutOfNetworkReview,
      userClickInfoTracking,
      postBatchActivities,
      searchInfos,
      pastedAttachmentTexts,
      userId,
      serviceRequest,
      setUserClickInfoTracking,
      setSearchInfos,
      caseId,
      navigate,
      enqueueSnackbar,
    ]
  );

  const discardReview = useCallback(
    async (embeddedMode: boolean = false) => {
      if (outOfNetworkReview) {
        const isStandaloneMode = typeof embeddedMode !== "boolean" || !embeddedMode;
        if (isStandaloneMode) {
          try {
            const res = await discard({
              ...outOfNetworkReview,
            });
            setOutOfNetworkReview?.(res);
          } catch (error) {
            const discardError = error as ReturnType<typeof useDiscardOutOfNetworkReview>["error"];
            enqueueSnackbar(`Error discarding OON exception review: ${discardError?.message}`, { variant: "error" });
          }
        }
        userClickInfoTracking &&
          (await postBatchActivities(
            createBatchActivities(
              "OUT_OF_NETWORK_REVIEW",
              "REVIEW_SUBMISSION",
              "INTERACTION",
              userClickInfoTracking,
              searchInfos,
              pastedAttachmentTexts,
              userId,
              outOfNetworkReview.id,
              serviceRequest.cohereId,
              serviceRequest.id
            )
          ));
        if (isStandaloneMode) {
          setUserClickInfoTracking?.(getEmptyUserClickTracking());
          setSearchInfos?.([]);
          redirectToPatientSummaryFromReview({ serviceRequest, caseId, reviewId: outOfNetworkReview.id, navigate });
        }
      }
    },
    [
      discard,
      outOfNetworkReview,
      pastedAttachmentTexts,
      postBatchActivities,
      searchInfos,
      setSearchInfos,
      setUserClickInfoTracking,
      userClickInfoTracking,
      userId,
      setOutOfNetworkReview,
      caseId,
      navigate,
      serviceRequest,
      enqueueSnackbar,
    ]
  );

  const errorStates = getErrorStates(attemptedSubmitted);
  return {
    onFinishReview,
    errorStates,
    isSubmissionModalOpen,
    setIsSubmissionModalOpen,
    disableFinishModalButton,
    submitOutOfNetworkReview,
    validateReview,
    saveReviewDraft,
    discardReview,
    duplicateReviewModalOpen,
    errorDetails,
    openHardRedirectOnSubmitModal,
    setOpenHardRedirectOnSubmitModal,
    loadingDiscardOrSave,
    savingReview: updating,
    discardingReview: discarding,
    disableFinishButton,
    finishReviewLoading,
    setDuplicateReviewModalOpen,
  };
};
