import {
  GetCandidateSuggestedValuesResponse,
  FacilityCategory,
  SemanticProcedureCode,
  PlaceOfService,
  Provider,
  Facility,
  BulkUpdateCandidateFeedbackRequestBody,
  ServiceRequestResponse,
  Requestor,
  AdditionalCareParticipant,
  AuthCategoryResponse,
  PatientDischargeStatusResponse,
} from "@coherehealth/core-platform-api";
import { PriorAuthRequirements } from "../components/AuthBuilder/common";
import { parseDateFromISOString, formatDateStr } from "@coherehealth/common";
import { DiagnosisCode, ProcedureCode, SuggestedValue } from "@coherehealth/core-platform-api";
import { ServiceRequestFormContent } from "components/ServiceRequest";

export function initSuggestedRequestor(suggestions: GetCandidateSuggestedValuesResponse | null): SuggestedRequestor {
  return {
    firstName: suggestions?.requestorFirstName
      ? suggestions.requestorFirstName[0]
      : { attachmentId: "", source: "", sourceVersion: "", fieldName: "", predictedValue: "" },
    lastName: suggestions?.requestorLastName
      ? suggestions.requestorLastName[0]
      : { attachmentId: "", source: "", sourceVersion: "", fieldName: "", predictedValue: "" },
    email: suggestions?.requestorEmail
      ? suggestions.requestorEmail[0]
      : { attachmentId: "", source: "", sourceVersion: "", fieldName: "", predictedValue: "" },
    phoneNumber: suggestions?.requestorPhoneNumber
      ? suggestions.requestorPhoneNumber[0]
      : { attachmentId: "", source: "", sourceVersion: "", fieldName: "", predictedValue: undefined },
    faxNumber: suggestions?.requestorFaxNumber
      ? suggestions.requestorFaxNumber[0]
      : { attachmentId: "", source: "", sourceVersion: "", fieldName: "", predictedValue: undefined },
    requestorType: suggestions?.requestorType
      ? suggestions?.requestorType[0]
      : { attachmentId: "", source: "", sourceVersion: "", fieldName: "", predictedValue: "" },
  };
}

export function initSuggestedMember(suggestions: GetCandidateSuggestedValuesResponse | null): SuggestedMember {
  return {
    memberId: suggestions?.patientMemberId
      ? suggestions.patientMemberId[0]
      : { attachmentId: "", source: "", sourceVersion: "", fieldName: "", predictedValue: "" },
    memberDob: suggestions?.patientDateOfBirth
      ? suggestions.patientDateOfBirth[0]
      : { attachmentId: "", source: "", sourceVersion: "", fieldName: "", predictedValue: "" },
    memberLastName: suggestions?.patientLastName
      ? suggestions.patientLastName[0]
      : { attachmentId: "", source: "", sourceVersion: "", fieldName: "", predictedValue: "" },
  };
}

export function initSuggestedAuth(suggestions: GetCandidateSuggestedValuesResponse | null): SuggestedAuth {
  return {
    trackingNumber: suggestions?.trackingNumber
      ? suggestions.trackingNumber[0]
      : { attachmentId: "", sourceVersion: "", fieldName: "", predictedValue: "" },
  };
}

/*
 * Given a list of suggestedValues, creates a Record with a series of value pairs <predictedValue, prediction>
 */
function extractPredictions(suggestedValues: SuggestedValue[] | null): Record<string, SuggestedValue> {
  const predictions: Record<string, SuggestedValue> = {};
  if (!suggestedValues?.length) {
    return predictions;
  }

  suggestedValues.forEach((prediction) => {
    if (prediction?.predictedValue) {
      predictions[prediction.predictedValue] = prediction;
    }
  });

  return predictions;
}

export type ClientSuggestedValue = SuggestedValue & {
  diagnosisCodeEntity?: DiagnosisCode;
  procedureCodeEntity?: ProcedureCode;
  dateEntity?: Date;
  facilityCategoryEntity?: FacilityCategory;
  placeOfServiceEntity?: PlaceOfService;
  providerEntity?: Provider;
  facilityEntity?: Facility;
  stringEntity?: string;
  booleanEntity?: boolean;
  additionalCareParticipantEntity?: AdditionalCareParticipant;
  authCategoryEntity?: AuthCategoryResponse;
  dischargedToEntity?: PatientDischargeStatusResponse;
};

export interface SuggestedRequestor {
  firstName?: SuggestedValue;
  lastName?: SuggestedValue;
  email?: SuggestedValue;
  phoneNumber?: SuggestedValue;
  faxNumber?: SuggestedValue;
  channel?: SuggestedValue;
  user?: SuggestedValue;
  intakeTimestamp?: SuggestedValue;
  requestorType?: SuggestedValue;
}

export interface SuggestedPriorAuthRequirements {
  primaryDiagnosis?: ClientSuggestedValue;
  secondaryDiagnoses?: ClientSuggestedValue[];
  desiredProcedureCodes?: ClientSuggestedValue[];
  startDate?: ClientSuggestedValue;
  encounterType?: ClientSuggestedValue;
  showPxCheckbox: boolean;
  noPxServiceRequired: boolean;
  authCategory?: ClientSuggestedValue;
  authSubcategory?: ClientSuggestedValue;
}

export interface SuggestedFormContent {
  placeOfService?: ClientSuggestedValue;
  orderingProvider?: ClientSuggestedValue;
  orderingProviderSelectedTin?: ClientSuggestedValue;
  performingProvider?: ClientSuggestedValue;
  performingProviderSelectedTin?: ClientSuggestedValue;
  facility?: ClientSuggestedValue;
  facilitySelectedTin?: ClientSuggestedValue;
  performingProviderPractice?: ClientSuggestedValue;
  performingProviderPracticeSelectedTin?: ClientSuggestedValue;
  isExpedited?: ClientSuggestedValue;
  endDate?: ClientSuggestedValue;
  caseType?: ClientSuggestedValue;
  additionalCareParticipant?: ClientSuggestedValue;
  dischargedTo?: ClientSuggestedValue;
  dischargeDate?: ClientSuggestedValue;
  dischargeTime?: ClientSuggestedValue;
}

export interface SuggestedMember {
  memberId?: SuggestedValue;
  memberDob?: SuggestedValue;
  memberLastName?: SuggestedValue;
}

export interface SuggestedAuth {
  trackingNumber?: SuggestedValue;
}

export function isNotNullOrUndefined<T extends SemanticProcedureCode>(input: null | undefined | T): input is T {
  return input != null;
}

export function initSuggestedPriorAuthRequirements(
  suggestions: GetCandidateSuggestedValuesResponse | null,
  fetchedPrimaryDxCode: DiagnosisCode[] | null,
  fetchedSecondaryDxCodes: DiagnosisCode[] | null,
  fetchedPxCodes: (SemanticProcedureCode | null)[] | null,
  fetchedAuthCategories: AuthCategoryResponse[] | null,
  currentSuggestedPAR: SuggestedPriorAuthRequirements
): SuggestedPriorAuthRequirements {
  const primaryDxCodeSuggestions: Record<string, SuggestedValue> = extractPredictions(
    suggestions?.primarySemanticDiagnosisCode ? [suggestions.primarySemanticDiagnosisCode[0]] : []
  );
  const validatedPrimaryDxCodes: DiagnosisCode[] =
    fetchedPrimaryDxCode?.filter((dxCode) => dxCode.code in primaryDxCodeSuggestions) || [];
  const suggestedPrimaryDxCode = !!validatedPrimaryDxCodes.length
    ? Object.assign({}, primaryDxCodeSuggestions[validatedPrimaryDxCodes[0].code], {
        diagnosisCodeEntity: validatedPrimaryDxCodes[0],
      })
    : undefined;

  const secondaryDxCodeSuggestions: Record<string, SuggestedValue> = extractPredictions(
    suggestions?.secondarySemanticDiagnosisCodes
  );
  const validatedSecondaryDxCodes: DiagnosisCode[] =
    fetchedSecondaryDxCodes?.filter((dxCode) => dxCode.code in secondaryDxCodeSuggestions) || [];
  const suggestedSecondaryDxCodes: SuggestedValue[] | undefined = !!validatedSecondaryDxCodes.length
    ? validatedSecondaryDxCodes.map((dxCode) =>
        Object.assign({}, secondaryDxCodeSuggestions[dxCode.code], { diagnosisCodeEntity: dxCode })
      )
    : undefined;

  const nonNullPxCodes: SemanticProcedureCode[] | undefined = fetchedPxCodes?.filter(isNotNullOrUndefined);
  const pxCodeSuggestions = extractPredictions(suggestions?.semanticProcedureCodes);
  const validatedPxCodes: ProcedureCode[] = nonNullPxCodes?.filter((pxCode) => pxCode.code in pxCodeSuggestions) || [];
  const suggestedPxCodes = !!validatedPxCodes.length
    ? validatedPxCodes.map((pxCode) =>
        Object.assign({}, pxCodeSuggestions[pxCode.code], { procedureCodeEntity: pxCode })
      )
    : undefined;

  const suggestedStartDate = suggestions?.startDate
    ? Object.assign({}, suggestions.startDate[0], {
        dateEntity: parseDateFromISOString(suggestions.startDate[0].predictedValue),
      })
    : undefined;

  const suggestedEncounterType = suggestions?.encounterType
    ? Object.assign({}, suggestions.encounterType[0], {
        facilityCategoryEntity: suggestions.encounterType[0].predictedValue as FacilityCategory,
      })
    : undefined;

  // authCategory
  const authCategorySuggestions: Record<string, SuggestedValue> = extractPredictions(
    suggestions?.authCategory ? [suggestions.authCategory[0]] : []
  );
  const validatedAuthCategory: AuthCategoryResponse[] =
    fetchedAuthCategories?.filter((authCategory) => authCategory.enumName in authCategorySuggestions) || [];
  const suggestedAuthCategory = !!validatedAuthCategory.length
    ? Object.assign({}, authCategorySuggestions[validatedAuthCategory[0].enumName], {
        authCategoryEntity: validatedAuthCategory[0],
      })
    : undefined;

  // authSubCategory
  const suggestedAuthCategoryHasSubCategory =
    !!validatedAuthCategory.length &&
    validatedAuthCategory[0]?.subcategories &&
    validatedAuthCategory[0].subcategories.length > 0;
  const suggestedAuthSubcategory =
    suggestions?.authSubcategory &&
    suggestedAuthCategoryHasSubCategory &&
    validatedAuthCategory[0]?.subcategories?.includes(suggestions?.authSubcategory[0].predictedValue)
      ? Object.assign({}, suggestions.authSubcategory[0], {
          stringEntity: suggestions.authSubcategory[0].predictedValue,
        })
      : undefined;

  return {
    ...currentSuggestedPAR,
    primaryDiagnosis: suggestedPrimaryDxCode || currentSuggestedPAR.primaryDiagnosis,
    secondaryDiagnoses: suggestedSecondaryDxCodes || currentSuggestedPAR.secondaryDiagnoses,
    desiredProcedureCodes: suggestedPxCodes || currentSuggestedPAR.desiredProcedureCodes,
    startDate: suggestedStartDate || currentSuggestedPAR.startDate,
    encounterType: suggestedEncounterType || currentSuggestedPAR.encounterType,
    authCategory: suggestedAuthCategory || currentSuggestedPAR.authCategory,
    authSubcategory: suggestedAuthSubcategory || currentSuggestedPAR.authSubcategory,
  };
}

export function generateInitialPriorAuthRequirementsWithSuggestions(
  suggestedPriorAuthRequirements?: SuggestedPriorAuthRequirements,
  currentPAR?: PriorAuthRequirements,
  suggestionsReady?: boolean
): PriorAuthRequirements {
  const defaultSecondaryDiagnosesCodes: DiagnosisCode[] = [];
  const defaultProcedureCodes: ProcedureCode[] = [];
  const defaultEncounterType = "OUTPATIENT";

  return {
    ...(currentPAR || { showPxCheckbox: false, noPxServiceRequired: false }),
    primaryDiagnosis:
      currentPAR?.primaryDiagnosis || suggestedPriorAuthRequirements?.primaryDiagnosis?.diagnosisCodeEntity,
    secondaryDiagnoses: currentPAR?.secondaryDiagnoses?.length
      ? currentPAR.secondaryDiagnoses
      : suggestedPriorAuthRequirements?.secondaryDiagnoses
          ?.map((clientSV) => clientSV.diagnosisCodeEntity)
          .filter(isNotNullOrUndefined) || defaultSecondaryDiagnosesCodes,
    desiredProcedureCodes: currentPAR?.desiredProcedureCodes?.length
      ? currentPAR.desiredProcedureCodes
      : suggestedPriorAuthRequirements?.desiredProcedureCodes
          ?.map((clientSV) => clientSV.procedureCodeEntity)
          .filter(isNotNullOrUndefined) || defaultProcedureCodes,
    startDate:
      currentPAR?.startDate && !suggestionsReady
        ? currentPAR.startDate
        : suggestedPriorAuthRequirements?.startDate?.dateEntity,
    encounterType:
      currentPAR?.encounterType && !suggestionsReady
        ? currentPAR.encounterType
        : suggestedPriorAuthRequirements?.encounterType?.facilityCategoryEntity || defaultEncounterType,
    authCategory:
      suggestionsReady && suggestedPriorAuthRequirements?.encounterType?.facilityCategoryEntity === "INPATIENT"
        ? suggestedPriorAuthRequirements?.authCategory?.authCategoryEntity
        : currentPAR?.authCategory,
    authSubcategory:
      suggestionsReady && suggestedPriorAuthRequirements?.encounterType?.facilityCategoryEntity === "INPATIENT"
        ? suggestedPriorAuthRequirements?.authSubcategory?.stringEntity
        : currentPAR?.authSubcategory,
  };
}

// Takes in two lists, A and B, of Diagnosis/Procedure codes and checks that the code values are equivalent
export function compareCodeListEqualityNoOrder(
  arr1: DiagnosisCode[] | ProcedureCode[] | undefined,
  arr2: DiagnosisCode[] | ProcedureCode[] | undefined
): boolean {
  if (!arr1 || !arr2 || arr1.length === 0 || arr2.length === 0) {
    return false;
  }
  if (arr1.length !== arr2.length) {
    return false;
  }
  const counts = new Map();
  arr1.forEach((value) => counts.set(value?.code, (counts.get(value?.code) ?? 0) + 1));
  arr2.forEach((value) => counts.set(value?.code, (counts.get(value?.code) ?? 0) - 1));
  return Array.from(counts.values()).every((count) => count === 0);
}

function filterValidatedFetchedProvidersOrFacilities(
  predictions: Record<string, SuggestedValue>,
  fetchedProviders: Provider[] | null,
  fetchedFacilities: Facility[] | null,
  suggestedNpi: string | undefined,
  suggestedTin: string | undefined,
  suggestedName: string | undefined
): Provider[] | Facility[] {
  if (
    Object.keys(predictions).length === 0 ||
    (fetchedProviders && fetchedFacilities) ||
    (!suggestedNpi && !suggestedTin && !suggestedName)
  ) {
    return [];
  }

  // If we have an suggested NPI, filter for fetched entities that have the suggested npi
  // If we don't have a suggested NPI, but have a suggested selectedTIN, then filter for the fetched
  //    entities that have the suggested TIN
  // Otherwise, return default []
  if (fetchedProviders) {
    if (suggestedNpi) {
      return fetchedProviders.filter((provider: Provider) => provider?.npi || "" in predictions);
    } else {
      return fetchedProviders.filter((provider: Provider) => {
        let externallyValid: boolean = false;
        if (provider?.tinList?.length) {
          provider?.tinList.forEach((tin) => {
            if (tin in predictions) {
              externallyValid = true;
            }
          });
        }
        return externallyValid;
      });
    }
  } else if (fetchedFacilities) {
    if (suggestedNpi) {
      return fetchedFacilities.filter((facility: Facility) => facility?.npi || "" in predictions);
    } else if (suggestedName) {
      return fetchedFacilities.filter((facility: Facility) => facility?.name || "" in predictions);
    } else {
      return fetchedFacilities.filter((facility: Facility) => {
        let externallyValid: boolean = false;
        if (facility?.tinList?.length) {
          facility?.tinList.forEach((tin) => {
            if (tin in predictions) {
              externallyValid = true;
            }
          });
        }
        return externallyValid;
      });
    }
  } else {
    return [];
  }
}

export function initSuggestedFormContent(
  suggestions: GetCandidateSuggestedValuesResponse | null,
  fetchedPlaceOfServices: PlaceOfService[] | null,
  fetchedOrderingProviders: Provider[] | null,
  fetchedPerformingProviders: Provider[] | null,
  fetchedFacilities: Facility[] | null,
  fetchedPerformingProviderPractices: Facility[] | null,
  fetchSuggestedDischargedToByEnum: PatientDischargeStatusResponse[] | null,
  currentSuggestedFormContent: SuggestedFormContent
): SuggestedFormContent {
  // Place of Service
  const posSuggestions: Record<string, SuggestedValue> = extractPredictions(
    suggestions?.placeOfServiceCode ? [suggestions.placeOfServiceCode[0]] : []
  );
  const validatedPos: PlaceOfService[] = fetchedPlaceOfServices?.filter((pos) => pos.code in posSuggestions) || [];
  const suggestedPos = !!validatedPos.length
    ? Object.assign({}, posSuggestions[validatedPos[0].code], {
        placeOfServiceEntity: validatedPos[0],
      })
    : undefined;

  // Ordering Provider
  const suggestedOrderingProvider = getSuggestedProviderFacility(
    fetchedOrderingProviders,
    true,
    suggestions?.orderingProviderNpi,
    suggestions?.orderingProviderSelectedTin,
    suggestions?.orderingProviderName
  );

  // Performing Provider
  const suggestedPerformingProvider = getSuggestedProviderFacility(
    fetchedPerformingProviders,
    true,
    suggestions?.performingProviderNpi,
    suggestions?.performingProviderSelectedTin,
    suggestions?.performingProviderName
  );

  // Facility
  const suggestedFacility = getSuggestedProviderFacility(
    fetchedFacilities,
    false,
    suggestions?.facilityNpi,
    suggestions?.facilitySelectedTin,
    suggestions?.facilityName
  );

  // Performing Provider Practice
  const suggestedPerformingProviderPractice = getSuggestedProviderFacility(
    fetchedPerformingProviderPractices,
    false,
    suggestions?.performingProviderPracticeNpi,
    suggestions?.performingProviderPracticeSelectedTin,
    suggestions?.performingProviderPracticeName
  );

  const suggestedOrderingProviderSelectedTin = suggestions?.orderingProviderSelectedTin
    ? Object.assign({}, suggestions.orderingProviderSelectedTin[0], {
        stringEntity: suggestions.orderingProviderSelectedTin[0].predictedValue,
      })
    : undefined;

  const suggestedPerformingProviderSelectedTin = suggestions?.performingProviderSelectedTin
    ? Object.assign({}, suggestions.performingProviderSelectedTin[0], {
        stringEntity: suggestions.performingProviderSelectedTin[0].predictedValue,
      })
    : undefined;
  const suggestedFacilitySelectedTin = suggestions?.facilitySelectedTin
    ? Object.assign({}, suggestions.facilitySelectedTin[0], {
        stringEntity: suggestions.facilitySelectedTin[0].predictedValue,
      })
    : undefined;
  const suggestedPerformingProviderPracticeTin = suggestions?.performingProviderPracticeSelectedTin
    ? Object.assign({}, suggestions.performingProviderPracticeSelectedTin[0], {
        stringEntity: suggestions.performingProviderPracticeSelectedTin[0].predictedValue,
      })
    : undefined;

  // End Date
  const suggestedEndDate = suggestions?.endDate
    ? Object.assign({}, suggestions.endDate[0], {
        dateEntity: parseDateFromISOString(suggestions.endDate[0].predictedValue),
      })
    : undefined;

  // isExpedited
  const suggestedIsExpedited = suggestions?.isExpedited
    ? Object.assign({}, suggestions.isExpedited[0], {
        booleanEntity: suggestions.isExpedited[0].predictedValue?.toLowerCase() === "true",
      })
    : undefined;

  // caseType
  const suggestedCaseType = suggestions?.caseType
    ? Object.assign({}, suggestions.caseType[0], {
        stringEntity: suggestions.caseType[0].predictedValue,
      })
    : undefined;

  // dischargedTo
  const dischargedToSuggestions: Record<string, SuggestedValue> = extractPredictions(
    suggestions?.dischargedTo ? [suggestions.dischargedTo[0]] : []
  );
  const validatedDischargedToPlace: PatientDischargeStatusResponse[] =
    fetchSuggestedDischargedToByEnum?.filter((dischargedTo) =>
      dischargedTo.enumName ? dischargedTo.enumName in dischargedToSuggestions : []
    ) || [];
  const suggestedDischargedToPlace =
    !!validatedDischargedToPlace.length && validatedDischargedToPlace
      ? Object.assign({}, dischargedToSuggestions[validatedDischargedToPlace[0].enumName], {
          dischargedToEntity: validatedDischargedToPlace[0],
        })
      : undefined;

  // Discharged date
  const suggestedDischargeDate = suggestions?.dischargeDate
    ? Object.assign({}, suggestions.dischargeDate[0], {
        dateEntity: parseDateFromISOString(suggestions.dischargeDate[0].predictedValue),
      })
    : undefined;

  // Discharge Time
  const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

  // Test if the dischargeTime is in correct format
  const isValidSuggestedDischargeTime =
    suggestions?.dischargeDateTime && timeRegex.test(suggestions.dischargeDateTime[0].predictedValue);
  const suggestedDischargeTime =
    suggestions?.dischargeDateTime && isValidSuggestedDischargeTime
      ? Object.assign({}, suggestions.dischargeDateTime[0], {
          stringEntity: suggestions.dischargeDateTime[0].predictedValue,
        })
      : undefined;

  return {
    ...currentSuggestedFormContent,
    placeOfService: suggestedPos || undefined,
    orderingProvider: suggestedOrderingProvider,
    orderingProviderSelectedTin: suggestedOrderingProviderSelectedTin,
    performingProvider: suggestedPerformingProvider,
    performingProviderSelectedTin: suggestedPerformingProviderSelectedTin,
    performingProviderPractice: suggestedPerformingProviderPractice,
    performingProviderPracticeSelectedTin: suggestedPerformingProviderPracticeTin,
    facility: suggestedFacility,
    facilitySelectedTin: suggestedFacilitySelectedTin,
    endDate: suggestedEndDate,
    isExpedited: suggestedIsExpedited,
    caseType: suggestedCaseType,
    dischargedTo: suggestedDischargedToPlace,
    dischargeDate: suggestedDischargeDate,
    dischargeTime: suggestedDischargeTime,
  };
}

function getSuggestedProviderFacility(
  fetchedProvidersFacilities: Provider[] | null,
  isProvider: boolean,
  providerFacilityNip: any,
  providerFacilitySelectedTin: any,
  providerFacilityName: any
) {
  const providerFacilitySuggestions: Record<string, SuggestedValue> = getProviderFacilitySuggestionsByType(
    providerFacilityNip,
    providerFacilityName,
    providerFacilitySelectedTin
  );

  const validatedProvidersFacilities: Provider[] = filterValidatedFetchedProvidersOrFacilities(
    providerFacilitySuggestions,
    isProvider ? fetchedProvidersFacilities : null,
    isProvider ? null : fetchedProvidersFacilities,
    providerFacilityNip,
    providerFacilitySelectedTin,
    providerFacilityName
  );

  if (validatedProvidersFacilities?.length) {
    const entity = getEntity(isProvider, validatedProvidersFacilities);
    if (providerFacilityNip && providerFacilitySuggestions[providerFacilityNip[0].predictedValue]) {
      return Object.assign({}, providerFacilitySuggestions[providerFacilityNip[0].predictedValue], entity);
    } else if (providerFacilityName && providerFacilitySuggestions[providerFacilityName[0].predictedValue]) {
      return Object.assign({}, providerFacilitySuggestions[providerFacilityName[0].predictedValue], entity);
    } else if (
      providerFacilitySelectedTin &&
      providerFacilitySuggestions[providerFacilitySelectedTin[0].predictedValue]
    ) {
      return Object.assign({}, providerFacilitySuggestions[providerFacilitySelectedTin[0].predictedValue], entity);
    }
  }
  return undefined;
}

//Extract predictions depending on the type of suggestion
function getProviderFacilitySuggestionsByType(
  providerFacilityNip: any,
  providerFacilityName: any,
  providerFacilitySelectedTin: any
) {
  if (providerFacilityNip) {
    return extractPredictions([providerFacilityNip[0]]);
  } else if (providerFacilityName) {
    return extractPredictions(providerFacilityName ? [providerFacilityName[0]] : []);
  } else {
    return extractPredictions(providerFacilitySelectedTin ? [providerFacilitySelectedTin[0]] : []);
  }
}

//Depending on the providerFacility type it returns the corresponding entity
function getEntity(isProvider: boolean, validatedProvidersFacilities: Provider[]) {
  if (isProvider) {
    return {
      providerEntity: validatedProvidersFacilities[0],
    };
  } else {
    return {
      facilityEntity: validatedProvidersFacilities[0],
    };
  }
}

export function generateFormContentFromSuggestions(
  suggestedFormContent: SuggestedFormContent,
  currentFormContent: ServiceRequestFormContent
): ServiceRequestFormContent {
  return {
    ...currentFormContent,
    placeOfService: suggestedFormContent?.placeOfService?.placeOfServiceEntity || currentFormContent.placeOfService,
    orderingProvider: suggestedFormContent?.orderingProvider?.providerEntity || currentFormContent?.orderingProvider,
    orderingProviderSelectedTin:
      suggestedFormContent?.orderingProviderSelectedTin?.stringEntity &&
      suggestedFormContent?.orderingProvider?.providerEntity?.tinList &&
      suggestedFormContent.orderingProvider?.providerEntity?.tinList.includes(
        suggestedFormContent.orderingProviderSelectedTin.stringEntity
      )
        ? suggestedFormContent.orderingProviderSelectedTin.stringEntity
        : suggestedFormContent?.orderingProvider?.providerEntity?.tinList?.length === 1
        ? suggestedFormContent.orderingProvider.providerEntity?.tinList[0]
        : currentFormContent?.orderingProviderSelectedTin,
    performingProvider:
      suggestedFormContent?.performingProvider?.providerEntity || currentFormContent?.performingProvider,
    performingProviderSelectedTin:
      suggestedFormContent?.performingProviderSelectedTin?.stringEntity &&
      suggestedFormContent?.performingProvider?.providerEntity?.tinList &&
      suggestedFormContent.performingProvider?.providerEntity.tinList.includes(
        suggestedFormContent.performingProviderSelectedTin.stringEntity
      )
        ? suggestedFormContent.performingProviderSelectedTin.stringEntity
        : suggestedFormContent?.performingProvider?.providerEntity?.tinList?.length === 1
        ? suggestedFormContent.performingProvider.providerEntity.tinList[0]
        : currentFormContent?.performingProviderSelectedTin,
    facility: suggestedFormContent?.facility?.facilityEntity || currentFormContent?.facility,
    facilitySelectedTin:
      suggestedFormContent?.facilitySelectedTin?.stringEntity &&
      suggestedFormContent?.facility?.facilityEntity?.tinList &&
      suggestedFormContent.facility?.facilityEntity.tinList.includes(
        suggestedFormContent.facilitySelectedTin.stringEntity
      )
        ? suggestedFormContent.facilitySelectedTin.stringEntity
        : suggestedFormContent?.facility?.facilityEntity?.tinList?.length === 1
        ? suggestedFormContent.facility.facilityEntity.tinList[0]
        : currentFormContent?.facilitySelectedTin,
    endDate: suggestedFormContent?.endDate?.dateEntity || currentFormContent?.endDate,
    isExpedited: suggestedFormContent?.isExpedited?.booleanEntity || currentFormContent.isExpedited,
    dischargedTo:
      currentFormContent.isInpatient && suggestedFormContent?.dischargedTo
        ? suggestedFormContent?.dischargedTo?.dischargedToEntity
        : currentFormContent.dischargedTo,
    dischargeDate:
      currentFormContent.isInpatient && suggestedFormContent?.dischargeDate
        ? suggestedFormContent?.dischargeDate?.dateEntity
        : currentFormContent.dischargeDate,
    dischargeTime:
      currentFormContent.isInpatient && suggestedFormContent?.dischargeTime
        ? suggestedFormContent?.dischargeTime?.stringEntity
        : currentFormContent.dischargeTime,
  };
}

export function compareSuggestionsToFinalSR(
  sr: ServiceRequestResponse,
  requestor: Requestor | undefined,
  suggestedRequestor: SuggestedRequestor | undefined,
  suggestedPriorAuthRequirements: SuggestedPriorAuthRequirements | undefined,
  suggestedFormContent: SuggestedFormContent | undefined
): BulkUpdateCandidateFeedbackRequestBody {
  const accepted: string[] = [];
  const rejected: string[] = [];

  if (suggestedRequestor?.firstName && suggestedRequestor?.firstName?.id) {
    suggestedRequestor.firstName.predictedValue === requestor?.firstName
      ? accepted.push(suggestedRequestor.firstName.id)
      : rejected.push(suggestedRequestor.firstName.id);
  }

  if (suggestedRequestor?.lastName && suggestedRequestor?.lastName?.id) {
    suggestedRequestor.lastName.predictedValue === requestor?.lastName
      ? accepted.push(suggestedRequestor.lastName.id)
      : rejected.push(suggestedRequestor.lastName.id);
  }

  if (suggestedRequestor?.email && suggestedRequestor?.email?.id) {
    suggestedRequestor.email.predictedValue === requestor?.email
      ? accepted.push(suggestedRequestor.email.id)
      : rejected.push(suggestedRequestor.email.id);
  }

  if (suggestedRequestor?.phoneNumber && suggestedRequestor?.phoneNumber?.id) {
    suggestedRequestor.phoneNumber.predictedValue === requestor?.phoneNumber?.number
      ? accepted.push(suggestedRequestor.phoneNumber.id)
      : rejected.push(suggestedRequestor.phoneNumber.id);
  }

  if (suggestedRequestor?.faxNumber && suggestedRequestor?.faxNumber?.id) {
    suggestedRequestor.faxNumber.predictedValue === requestor?.faxNumber?.number
      ? accepted.push(suggestedRequestor.faxNumber.id)
      : rejected.push(suggestedRequestor.faxNumber.id);
  }

  if (suggestedRequestor?.requestorType && suggestedRequestor?.requestorType?.id) {
    suggestedRequestor?.requestorType.predictedValue === requestor?.requestorType
      ? accepted.push(suggestedRequestor.requestorType.id)
      : rejected.push(suggestedRequestor.requestorType.id);
  }

  if (
    suggestedPriorAuthRequirements?.primaryDiagnosis?.id &&
    suggestedPriorAuthRequirements?.primaryDiagnosis?.diagnosisCodeEntity
  ) {
    sr.primarySemanticDiagnosisCode?.code === suggestedPriorAuthRequirements.primaryDiagnosis.diagnosisCodeEntity.code
      ? accepted.push(suggestedPriorAuthRequirements.primaryDiagnosis.id)
      : rejected.push(suggestedPriorAuthRequirements.primaryDiagnosis.id);
  }

  if (!!suggestedPriorAuthRequirements?.secondaryDiagnoses?.length) {
    suggestedPriorAuthRequirements.secondaryDiagnoses.forEach((dxCodeSuggestion) => {
      if (dxCodeSuggestion.id) {
        sr.secondarySemanticDiagnosisCodes
          ?.map((dxCode) => dxCode.code)
          .includes(dxCodeSuggestion?.diagnosisCodeEntity?.code || "")
          ? accepted.push(dxCodeSuggestion.id)
          : rejected.push(dxCodeSuggestion.id);
      }
    });
  }

  if (!!suggestedPriorAuthRequirements?.desiredProcedureCodes?.length) {
    suggestedPriorAuthRequirements.desiredProcedureCodes.forEach((pxCodeSuggestion) => {
      if (pxCodeSuggestion.id) {
        sr.semanticProcedureCodes
          ?.map((pxCode) => pxCode.code)
          .includes(pxCodeSuggestion?.procedureCodeEntity?.code || "")
          ? accepted.push(pxCodeSuggestion.id)
          : rejected.push(pxCodeSuggestion.id);
      }
    });
  }

  if (suggestedPriorAuthRequirements?.startDate?.id && suggestedPriorAuthRequirements?.startDate?.dateEntity) {
    sr.startDate === suggestedPriorAuthRequirements.startDate.predictedValue
      ? accepted.push(suggestedPriorAuthRequirements.startDate.id)
      : rejected.push(suggestedPriorAuthRequirements.startDate.id);
  }

  if (
    suggestedPriorAuthRequirements?.encounterType?.id &&
    suggestedPriorAuthRequirements?.encounterType?.facilityCategoryEntity
  ) {
    sr.encounterType?.toString() === suggestedPriorAuthRequirements.encounterType.facilityCategoryEntity.toString()
      ? accepted.push(suggestedPriorAuthRequirements.encounterType.id)
      : rejected.push(suggestedPriorAuthRequirements.encounterType.id);
  }

  if (
    suggestedPriorAuthRequirements?.authCategory?.id &&
    suggestedPriorAuthRequirements?.authCategory?.authCategoryEntity
  ) {
    sr.authCategory?.enumName === suggestedPriorAuthRequirements.authCategory.authCategoryEntity.enumName
      ? accepted.push(suggestedPriorAuthRequirements.authCategory.id)
      : rejected.push(suggestedPriorAuthRequirements.authCategory.id);
  }

  if (
    suggestedPriorAuthRequirements?.authSubcategory?.id &&
    suggestedPriorAuthRequirements?.authSubcategory?.stringEntity
  ) {
    sr.authSubcategory === suggestedPriorAuthRequirements.authSubcategory.stringEntity
      ? accepted.push(suggestedPriorAuthRequirements.authSubcategory.id)
      : rejected.push(suggestedPriorAuthRequirements.authSubcategory.id);
  }

  if (suggestedFormContent?.placeOfService?.id && suggestedFormContent.placeOfService.placeOfServiceEntity) {
    sr.placeOfService?.id === suggestedFormContent.placeOfService.placeOfServiceEntity.id
      ? accepted.push(suggestedFormContent.placeOfService.id)
      : rejected.push(suggestedFormContent.placeOfService.id);
  }

  if (suggestedFormContent?.orderingProvider?.id && suggestedFormContent.orderingProvider.providerEntity) {
    sr.orderingProvider?.id === suggestedFormContent.orderingProvider.providerEntity.id
      ? accepted.push(suggestedFormContent.orderingProvider.id)
      : rejected.push(suggestedFormContent.orderingProvider.id);
  }

  if (
    suggestedFormContent?.orderingProviderSelectedTin?.id &&
    suggestedFormContent.orderingProviderSelectedTin.stringEntity
  ) {
    sr.orderingProviderSelectedTin === suggestedFormContent.orderingProviderSelectedTin.stringEntity
      ? accepted.push(suggestedFormContent.orderingProviderSelectedTin.id)
      : rejected.push(suggestedFormContent.orderingProviderSelectedTin.id);
  }

  if (suggestedFormContent?.performingProvider?.id && suggestedFormContent.performingProvider.providerEntity) {
    sr.performingProvider?.id === suggestedFormContent.performingProvider.providerEntity.id
      ? accepted.push(suggestedFormContent.performingProvider.id)
      : rejected.push(suggestedFormContent.performingProvider.id);
  }
  if (
    suggestedFormContent?.performingProviderSelectedTin?.id &&
    suggestedFormContent.performingProviderSelectedTin.stringEntity
  ) {
    sr.performingProviderSelectedTin === suggestedFormContent.performingProviderSelectedTin.stringEntity
      ? accepted.push(suggestedFormContent.performingProviderSelectedTin.id)
      : rejected.push(suggestedFormContent.performingProviderSelectedTin.id);
  }

  if (
    suggestedFormContent?.performingProviderPractice?.id &&
    suggestedFormContent?.performingProviderPractice?.facilityEntity
  ) {
    sr.selectedPerformingProviderPractice?.id === suggestedFormContent.performingProviderPractice.facilityEntity.id
      ? accepted.push(suggestedFormContent.performingProviderPractice.id)
      : rejected.push(suggestedFormContent.performingProviderPractice.id);
  }

  if (suggestedFormContent?.facility?.id && suggestedFormContent.facility.facilityEntity) {
    sr.facility?.id === suggestedFormContent.facility.facilityEntity.id
      ? accepted.push(suggestedFormContent.facility.id)
      : rejected.push(suggestedFormContent.facility.id);
  }
  if (suggestedFormContent?.facilitySelectedTin?.id && suggestedFormContent.facilitySelectedTin.stringEntity) {
    sr.facilitySelectedTin === suggestedFormContent.facilitySelectedTin.stringEntity
      ? accepted.push(suggestedFormContent.facilitySelectedTin.id)
      : rejected.push(suggestedFormContent.facilitySelectedTin.id);
  }

  if (suggestedFormContent?.endDate?.id && suggestedFormContent?.endDate?.dateEntity) {
    sr.endDate === suggestedFormContent.endDate.predictedValue
      ? accepted.push(suggestedFormContent.endDate.id)
      : rejected.push(suggestedFormContent.endDate.id);
  }

  if (suggestedFormContent?.isExpedited?.id && suggestedFormContent?.isExpedited?.booleanEntity !== undefined) {
    sr.urgency?.isExpedited === suggestedFormContent.isExpedited.booleanEntity
      ? accepted.push(suggestedFormContent.isExpedited.id)
      : rejected.push(suggestedFormContent.isExpedited.id);
  }

  if (suggestedFormContent?.caseType?.id && suggestedFormContent?.caseType?.stringEntity) {
    sr.authorizationType?.toUpperCase() === suggestedFormContent.caseType.stringEntity.toUpperCase()
      ? accepted.push(suggestedFormContent.caseType.id)
      : rejected.push(suggestedFormContent.caseType.id);
  }

  if (suggestedFormContent?.dischargeDate?.id && suggestedFormContent?.dischargeDate?.dateEntity) {
    sr.dischargeDate === suggestedFormContent.dischargeDate.predictedValue
      ? accepted.push(suggestedFormContent.dischargeDate.id)
      : rejected.push(suggestedFormContent.dischargeDate.id);
  }

  if (suggestedFormContent?.dischargeTime?.id && suggestedFormContent?.dischargeTime?.stringEntity) {
    sr.dischargeTime?.substring(0, 5) === suggestedFormContent.dischargeTime.stringEntity
      ? accepted.push(suggestedFormContent.dischargeTime.id)
      : rejected.push(suggestedFormContent.dischargeTime.id);
  }

  if (suggestedFormContent?.dischargedTo?.id && suggestedFormContent?.dischargedTo?.dischargedToEntity) {
    sr.dischargedTo?.enumName === suggestedFormContent.dischargedTo.dischargedToEntity.enumName
      ? accepted.push(suggestedFormContent.dischargedTo.id)
      : rejected.push(suggestedFormContent.dischargedTo.id);
  }

  return {
    accepted: accepted,
    rejected: rejected,
  };
}

export function compareMemberSuggestionsToSearch(
  memberId: string,
  memberDob: string,
  memberLastName: string,
  suggestedMember: SuggestedMember | undefined
): BulkUpdateCandidateFeedbackRequestBody {
  const accepted: string[] = [];
  const rejected: string[] = [];

  if (suggestedMember?.memberId && suggestedMember?.memberId?.id) {
    suggestedMember.memberId.predictedValue === memberId
      ? accepted.push(suggestedMember.memberId.id)
      : rejected.push(suggestedMember.memberId.id);
  }

  if (suggestedMember?.memberDob && suggestedMember?.memberDob?.id) {
    formatDateStr(suggestedMember.memberDob.predictedValue) === memberDob
      ? accepted.push(suggestedMember.memberDob.id)
      : rejected.push(suggestedMember.memberDob.id);
  }

  if (suggestedMember?.memberLastName && suggestedMember?.memberLastName?.id) {
    suggestedMember.memberLastName.predictedValue === memberLastName
      ? accepted.push(suggestedMember.memberLastName.id)
      : rejected.push(suggestedMember.memberLastName.id);
  }

  return {
    accepted: accepted,
    rejected: rejected,
  };
}

export function compareAuthSuggestionsToSearch(
  trackingNumber: string,
  suggestedAuth: SuggestedAuth | undefined
): BulkUpdateCandidateFeedbackRequestBody {
  const accepted: string[] = [];
  const rejected: string[] = [];

  if (suggestedAuth?.trackingNumber && suggestedAuth?.trackingNumber?.id) {
    suggestedAuth.trackingNumber.predictedValue === trackingNumber
      ? accepted.push(suggestedAuth.trackingNumber.id)
      : rejected.push(suggestedAuth.trackingNumber.id);
  }
  return {
    accepted: accepted,
    rejected: rejected,
  };
}
