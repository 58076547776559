import { Dispatch, useCallback, useContext, useEffect } from "react";

import {
  ServiceRequestSelectionCardView,
  formatDateToISODate,
  plusDays,
  HEALTH_HELP_V2_NAME,
} from "@coherehealth/common";
import {
  BaseReview,
  ServiceRequestCommandPayload,
  ServiceRequestResponse,
  useAddServiceRequestOnAuthorization,
  useCreateServiceRequestAttachment,
  useGetAllReviews,
  useGetAuthValidityWindow,
} from "@coherehealth/core-platform-api";
import { useSnackbar } from "notistack";
import { isAuthorizationValidForContinuations, getLatestServiceRequest } from "util/authorization";
import { generatePath, useNavigate } from "react-router";
import { sideBySideContinuationWorkflowPath } from "util/serviceRequest";
import { FaxAttachmentContext } from "../FaxAttachmentContext";
import useGetFacilityBasedRequestConfigurationByPayer from "hooks/useGetFeatureConfigurations";
import { ServiceCase } from "@coherehealth/qm-api";
import routes from "routes";
import { useCalculateInitializedEndDate } from "components/AuthBuilder/common";
import { getParametersFaxIntake, FAX_INTAKE, QUEUE_FAX_INTAKE, setParams } from "util/queryParams";
import { useGetAuthorizationByIdWithFallback } from "@coherehealth/common";
import { getWorkFlowStep } from "util/workflowUtils";

interface ViewProps {
  serviceRequest: ServiceRequestResponse | null;
  mostRecentReview?: BaseReview;
  loadingReview?: boolean;
  loadingServiceRequest?: boolean;
  onSelect?: (serviceRequestId: string) => void;
  onUnselect?: (serviceRequestId: string) => void;
  onRemove?: (serviceRequestId: string) => void;
  /**
   * This prop is for the actual remove attachment action, not just "unselecting" it
   */
  showRemoveButton?: boolean;
  isSelected?: boolean;
  showSecondaryButtonForAttach?: boolean;
  attachingFile?: boolean;
  isAttached?: boolean;
  showPatientAlert?: boolean;
  setSelectedServiceId: Dispatch<string>;
  disableAttachmentCta?: boolean;
}

interface UnattachedServiceRequestProps extends Omit<ViewProps, "mostRecentReview" | "loadingReview"> {
  serviceRequest: ServiceRequestResponse;
  /**
   * A value of true will run an effect that attaches the given fax id to this service request
   */
  triggerFaxUpload: boolean;
  /**
   * The fax id to upload
   */
  faxCohereId: string | undefined;
  faxId: string | undefined;
  serviceCase: ServiceCase | undefined | null;
  healthPlanName: string | undefined;
  externalReferenceId: string | undefined;
  disableAttachmentCta?: boolean;
  /**
   * Callback for after the fax is successfully attached to the service request
   */
  onAttachmentSuccess: (id: string) => void;
  /**
   * Callback for if the fax did not successfully attach to the service request
   */
  onAttachmentFailed: (id: string) => void;
  uploadingAttachment: boolean;
}

export default function UnattachedServiceRequestCard({
  triggerFaxUpload,
  faxCohereId,
  faxId,
  serviceRequest,
  isAttached,
  isSelected,
  onAttachmentSuccess,
  onAttachmentFailed,
  serviceCase,
  healthPlanName,
  externalReferenceId,
  setSelectedServiceId,
  uploadingAttachment,
  disableAttachmentCta,
  ...passthroughProps
}: UnattachedServiceRequestProps) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const {
    data: existingReviews,
    error: existingReviewsError,
    loading: loadingReviews,
  } = useGetAllReviews({
    serviceRequestId: serviceRequest.id,
    queryParams: { max: 20 },
  });

  const {
    mutate: uploadFax,
    loading: uploadingFax,
    error: faxUploadError,
  } = useCreateServiceRequestAttachment({
    id: serviceRequest.id,
    requestOptions: { headers: { Accept: "application/json" } },
  });

  const { facilityBasedFeatureEnabled } = useGetFacilityBasedRequestConfigurationByPayer({
    healthPlanName: serviceRequest?.healthPlanName || undefined,
    encounterType: serviceRequest?.encounterType,
    skipRequestTimingCheck: true,
    delegatedVendorName: serviceRequest?.delegatedVendor,
    srContent: serviceRequest,
  });

  const {
    error: getAuthorizationError,
    data: authorization,
    loading: authorizationLoading,
  } = useGetAuthorizationByIdWithFallback({
    id: serviceRequest?.authorization?.id || "",
    queryParams: {
      view: "expanded",
    },
  });

  useEffect(() => {
    if (existingReviewsError) {
      enqueueSnackbar(`Failed to load reviews for service request: ${existingReviewsError.message}`, {
        variant: "error",
      });
    }
    if (faxUploadError) {
      const errorMessage =
        typeof faxUploadError.data === "object"
          ? faxUploadError.data.message || faxUploadError.message
          : faxUploadError.message;
      enqueueSnackbar(`Failed to attach fax to service request: ${errorMessage}`, {
        variant: "error",
      });
    }
    if (getAuthorizationError) {
      enqueueSnackbar(`Failed to authorization for service request: ${getAuthorizationError.message}`, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, existingReviewsError, faxUploadError, getAuthorizationError]);

  const { caseId, queueId } = useContext(FaxAttachmentContext);

  const latestSr = authorization && getLatestServiceRequest(authorization)?.[1];
  const showCreateContinuation =
    !authorizationLoading &&
    !serviceRequest.clinicalService?.hasUnitsOnPxChanged &&
    serviceRequest.delegatedVendor !== HEALTH_HELP_V2_NAME &&
    serviceRequest?.allowContinuations &&
    isAuthorizationValidForContinuations(serviceRequest, latestSr);

  const { data: authValidityWindow, refetch: refetchAuthValidityWindow } = useGetAuthValidityWindow({ lazy: true });
  const {
    mutate: createNewServiceRequestOnAuth,
    loading: loadingContinuation,
    error: createContinuationError,
  } = useAddServiceRequestOnAuthorization({
    id: serviceRequest.authorization?.id || "",
    requestOptions: { headers: { Accept: "application/json" } },
  });

  useEffect(() => {
    if (createContinuationError) {
      enqueueSnackbar(
        typeof createContinuationError.data === "object"
          ? createContinuationError.data?.message
          : createContinuationError.message,
        { variant: "error" }
      );
    }
  }, [createContinuationError, enqueueSnackbar]);

  const navigateToContinuationWorkflowStep = useCallback(
    (serviceRequestId: string, patientId: string, caseId?: string) => {
      const { pathname, search } = sideBySideContinuationWorkflowPath(faxId || "", patientId, serviceRequestId);

      setSelectedServiceId(serviceRequestId);
      const updatedSearchParams = getParametersFaxIntake(search, faxId, caseId, queueId);
      const workflowStep = getWorkFlowStep() || "";
      const url = `${pathname}?${setParams("faxStep", updatedSearchParams, workflowStep)}`;

      navigate(url);
    },
    [faxId, navigate, queueId, setSelectedServiceId]
  );

  const calculateInitializedEndDate = useCalculateInitializedEndDate(healthPlanName);

  const onContinuationClick = useCallback(async () => {
    // creates a new empty draft SR on the authorization and directs the user to the fill forms continuation page for the newly created draft
    const payload: ServiceRequestCommandPayload = {
      authStatus: "DRAFT",
      urgency: { isExpedited: false },
      units: 0,
      requestType: "CONTINUATION",
      workflowStep: "FILL_FORMS_CONTINUATION",
      encounterType: serviceRequest.encounterType,
      continuationGeneratedFromServiceRequest: {
        id: serviceRequest.id,
      },
    };
    const today = new Date();
    let endDate;
    const initializedEndDate = calculateInitializedEndDate(
      today,
      serviceRequest.clinicalServices || [],
      serviceRequest.patient?.coverages || []
    );
    if (initializedEndDate) {
      endDate = initializedEndDate;
    }
    const maxDuration = Math.max(...(serviceRequest.clinicalServices?.map((cs) => cs?.defaultDuration || 0) || [0]));
    if (maxDuration > 0) {
      endDate = plusDays(maxDuration, today);
    }
    if (serviceRequest?.patient?.id && serviceRequest?.startDate) {
      await refetchAuthValidityWindow({
        queryParams: { patient: serviceRequest.patient.id, startDate: serviceRequest.startDate },
      });
    }
    const lastValidDate = authValidityWindow?.validityWindowEndDate
      ? new Date(authValidityWindow.validityWindowEndDate)
      : endDate;
    payload.endDate = formatDateToISODate(lastValidDate);
    const addDraftSRResponse = await createNewServiceRequestOnAuth(payload);
    if (faxCohereId) {
      const formData = new FormData();
      formData.set("faxId", faxCohereId);
      await uploadFax(formData as unknown as void, { pathParams: { id: addDraftSRResponse.id } });
    }
    navigateToContinuationWorkflowStep(addDraftSRResponse?.id, addDraftSRResponse?.patient?.id || "", caseId);
  }, [
    calculateInitializedEndDate,
    serviceRequest.id,
    serviceRequest.encounterType,
    serviceRequest?.patient?.id,
    serviceRequest.patient?.coverages,
    serviceRequest.startDate,
    serviceRequest.clinicalServices,
    authValidityWindow?.validityWindowEndDate,
    createNewServiceRequestOnAuth,
    faxCohereId,
    navigateToContinuationWorkflowStep,
    caseId,
    refetchAuthValidityWindow,
    uploadFax,
  ]);

  const onEditClick = useCallback(() => {
    if (serviceRequest?.id && serviceRequest?.id !== "") {
      setSelectedServiceId(serviceRequest.id);
      navigate(
        generatePath(routes.EDIT_SR_SIDE_BY_SIDE, {
          faxId: faxId,
          serviceRequestId: serviceRequest.id,
          caseId: caseId,
        })
          .concat(`?${FAX_INTAKE}=${faxId}&${QUEUE_FAX_INTAKE}=${queueId}`)
          .concat(`&faxStep=EDIT_VIEW`)
      );
    }
  }, [serviceRequest.id, setSelectedServiceId, navigate, caseId, faxId, queueId]);

  const showEditRequest = latestSr?.authStatus !== "DRAFT";

  return (
    <>
      <ServiceRequestSelectionCardView
        serviceRequest={serviceRequest}
        isAttached={isAttached}
        isSelected={isSelected}
        mostRecentReview={existingReviews ? existingReviews[existingReviews.length - 1] : undefined}
        loadingReview={loadingReviews}
        attachingFile={uploadingAttachment}
        showCreateContinuation={showCreateContinuation}
        onContinuationClick={onContinuationClick}
        loadingContinuation={loadingContinuation || uploadingFax}
        authorizationLoading={authorizationLoading}
        isFacilityBasedServiceRequest={!!facilityBasedFeatureEnabled}
        showEditRequest={showEditRequest}
        onEditClick={onEditClick}
        disableAttachmentCta={disableAttachmentCta}
        {...passthroughProps}
      />
    </>
  );
}
