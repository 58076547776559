import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import {
  NurseReview,
  useDiscardNurseReview,
  useSubmitNurseReview,
  useUpdateNurseReview,
  useUserActivityBatchUpdate,
  ReviewOutcome,
  ServiceRequestResponse,
  AuthorizationResponse,
  ServiceRequestUpdatePayload,
  AuthStatus,
  NurseReviewSubmitPayload,
  ReviewType,
} from "@coherehealth/core-platform-api";
import Collapse from "@material-ui/core/Collapse";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, useTheme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import GuidelineReview from "../GuidelineReview/GuidelineReview";
import NurseReviewEdit from "./NurseReviewEdit";
import NurseReviewReadOnly from "./NurseReviewReadonly";
import NurseReviewSubmissionModal from "./NurseReviewSubmissionModal";
import ReviewHeader from "../ReviewHeader/ReviewHeader";
import {
  Alert,
  colorsLight,
  InformativeModal,
  useFeature,
  createBatchActivities,
  getEmptyUserClickTracking,
  queueMgmtBaseUrl,
  InlineButton,
  useConfiguration,
  getNoTimezoneDateTimeString,
  formatDateToISODate,
} from "@coherehealth/common";
import { useTrackUserInteraction } from "util/userActivityTracker";
import { guidelineSnapshotFromReview } from "../index";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import routes from "routes";
import {
  useNurseReview,
  NurseReviewUpdate,
  useOutOfNetworkReview,
} from "components/ClinicalReview/reviewUtils/useReviews";
import { useCompleteServiceCase } from "@coherehealth/qm-api";
import config from "api/config";
import { ExtendedRnReviewOutcome } from "../util/QueueManagementReviewUtil";
import { Box, Grid } from "@material-ui/core";
import { OutreachAttemptProps } from "../useOutreachAttempt";
import {
  ReviewCommonProps,
  ReviewReadOnlyCommonProps,
  useContinueServiceRequestTillNextReviewDate,
  useGetUser,
} from "components/ClinicalReview/reviewUtils/utils";
import { CREATE_CPP_REVIEW_PARAM } from "util/queryParams";
import { last } from "lodash";
import DetailedHardRedirectClinicalReviewModal, {
  ClinicalReviewErrorDetails,
} from "../Modals/DetailedHardRedirectClinicalReviewModal";
import { defaultClinicalReviewErrorState, extractErrorDetails } from "../util/ReviewSectionUtil";
import { useProcedureCodesState } from "../util/useProcedureCodesState";
import usePersistAttachmentHighlights from "components/ClinicalReview/Review/hooks/usePersistAttachmentHighlights";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import {
  redirectToPatientSummaryFromReview,
  routeBackToOriginFromReview,
  routeToPatientSummaryFromReview,
} from "util/routeUtils/routeUtils";
import { patientAdmittedOrDischarged, patientNotYetAdmittedForInpatient } from "util/serviceRequest";
import { UNSAVED_OON } from "components/ClinicalReview/reviewUtils/useEmbeddedOonReview";
import { error as logError, stringifyError } from "logger";
import { useServiceCaseForReview } from "../util/useReviewRouting";

/**
 * Please put common props to reviews inside ReviewCommon Props
 */
export interface NurseReviewComponentProps extends ReviewCommonProps, Partial<OutreachAttemptProps> {
  serviceRequest: ServiceRequestResponse;
  nurseReview: NurseReview;
  existingReviews?: ReviewType[] | null;
  willAutoApprove?: boolean;
  isQmUserAudited?: boolean;
  hasOutreachAttempt?: boolean;
  setHasOutreachAttempt?: React.Dispatch<React.SetStateAction<boolean>>;
  sendOptionalDecisionLetter?: boolean;
  setSendOptionalDecisionLetter?: Dispatch<SetStateAction<boolean>>;
  automatedReviewNoteEditorState?: any;
  setAutomatedReviewNoteEditorState?: (state: any) => void;
}

interface NurseReviewEditableProps extends Omit<NurseReviewComponentProps, "setNurseReview" | "nurseReview"> {
  nurseReviewId: string;
}
interface NurseReviewEditorProps extends Omit<NurseReviewComponentProps, "setNurseReview"> {
  setNurseReview: NurseReviewUpdate;
}

interface NurseReviewReadonlyProps extends ReviewReadOnlyCommonProps {
  serviceRequest: ServiceRequestResponse;
  nurseReview: NurseReview;
  clinicalReviewView?: boolean;
}

const NurseReviewComponent = ({ nurseReview, ...otherProps }: NurseReviewComponentProps) => {
  if (nurseReview?.reviewStatus === "DRAFT") {
    return <NurseReviewEditable {...otherProps} nurseReviewId={nurseReview.id} />;
  } else if (nurseReview?.reviewStatus === "COMPLETE" || nurseReview?.reviewStatus === "DISCARDED") {
    return <NurseReviewReadonly nurseReview={nurseReview} {...otherProps} />;
  } else {
    return null;
  }
};

const NurseReviewEditable = ({ nurseReviewId, ...otherProps }: NurseReviewEditableProps) => {
  const [nurseReview, setNurseReview] = useNurseReview({ nurseReviewId });
  if (!nurseReview || !setNurseReview) {
    return null;
  }
  return <NurseReviewEditor {...otherProps} nurseReview={nurseReview} setNurseReview={setNurseReview} />;
};

const NurseReviewEditor = ({
  serviceRequest,
  nurseReview,
  setNurseReview,
  expanded,
  toggleExpanded,
  onFinishEditing,
  willAutoApprove,
  clinicalReviewView,
  userClickInfoTracking,
  setUserClickInfoTracking,
  searchInfos,
  setSearchInfos,
  reviewRef,
  expandableGuidelines,
  copiedAttachmentText,
  pastedAttachmentTexts,
  setPastedAttachmentTexts,
  submitHighlights,
  submittingHighlights,
  getHighlightPayload,
  attachmentGuidelineTextHighlightsSnapshot,
  isQmUserAudited = false,
  patientStayDateRanges,
  setPatientStayDateRanges,
  patientStaysErrorStates,
  isStayDatesValid,
  isDischargeDetailsValid,
  isDischarged,
  setIsDischarged,
  dischargeDetails,
  setDischargeDetails,
  buildAuthorizationDischargeDetails,
  facilityBasedFeatureEnabled,
  outreachAttempt,
  setOutreachAttempt,
  saveOutreachAttempt,
  outreachAttemptLoading,
  outreachFormConfig,
  outreachFormErrors,
  setOutreachFormErrors,
  validateOutreachForm,
  hasOutreachAttempt,
  setHasOutreachAttempt,
  existingReviews,
  authorization,
  checkEmptyRequestedLoc,
  continuationConfiguration,
  patientStayManager,
  sendOptionalDecisionLetter,
  setSendOptionalDecisionLetter,
  canShowExtraOONTab,
  embeddedOonReviewId,
  disableFinishOonModalButton,
  needsOonReview,
  submitEmbeddedOonReview,
  validateOONReview,
  saveOutOfNetworkReviewDraft,
  discardOutOfNetworkReview,
  oonAllowedReviewOutcomes,
  automatedReviewNoteEditorState,
  setAutomatedReviewNoteEditorState,
}: NurseReviewEditorProps) => {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const { currentCase } = useServiceCaseForReview(serviceRequest, "RN_REVIEW");
  const shouldUseAppendedRoute = useFeature("shouldUseAppendedRoute");
  const caseId = shouldUseAppendedRoute && currentCase?.id ? currentCase?.id : search.get("caseId") || "";
  const referredByQM = caseId && document.referrer.includes(caseId);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const navigate = useNavigate();
  const [isSubmissionDisabled, setIsSubmissionDisabled] = useState<boolean>(false);
  const user = useGetUser();
  const userId = user?.sub;
  const isPreService = serviceRequest.requestTiming === "PRE_SERVICE";
  const [openHardRedirectOnSubmitModal, setOpenHardRedirectOnSubmitModal] = useState(false);
  const [duplicateReviewModalOpen, setDuplicateReviewModalOpen] = useState<boolean>(false);
  const [errorDetails, setErrorDetails] = useState<ClinicalReviewErrorDetails>(
    defaultClinicalReviewErrorState(serviceRequest.id, nurseReview.id)
  );
  const [reviewSubmissionLoading, setReviewSubmissionLoading] = useState<boolean>(false);
  const { submitDraftHighlights, submittingDraftHighlights } = usePersistAttachmentHighlights();

  const lettersExpansionConfig = useConfiguration("lettersExpansionConfiguration", serviceRequest?.healthPlanName);

  const trackUserActivityInteraction = useTrackUserInteraction();

  const isSrReadmission = serviceRequest.isReadmission;
  const srAnchorAuthNumber = serviceRequest.anchorAuthNumber;
  const [isReadmission, setIsReadmission] = useState<boolean>(isSrReadmission === undefined ? false : isSrReadmission);
  const [anchorAuthNumber, setAnchorAuthNumber] = useState<string>(
    srAnchorAuthNumber !== undefined ? srAnchorAuthNumber : ""
  );
  const [hasErrorWithAnchorAuth, setHasErrorWithAnchorAuth] = useState<boolean>(false);
  const isReadmissionFeatureEnabled = useFeature("readmissionIdentification");

  const onFinishEditingInternal = useCallback(() => {
    toggleExpanded?.();
    onFinishEditing?.();
  }, [toggleExpanded, onFinishEditing]);

  const { mutate: postBatchActivities } = useUserActivityBatchUpdate({});

  // Hooks & callbacks to save a draft
  const {
    mutate: update,
    error: updateError,
    loading: updating,
  } = useUpdateNurseReview({
    reviewId: nurseReview.id,
    serviceRequestId: serviceRequest.id,
  });
  useEffect(() => {
    if (updateError) {
      enqueueSnackbar(`Error updating clinical review: ${updateError.message}`, { variant: "error" });
    }
  }, [updateError, enqueueSnackbar]);

  const shouldSubmitEmbeddedOonReview = canShowExtraOONTab && needsOonReview && !!embeddedOonReviewId;
  const [oonReview, setOonReview] = useOutOfNetworkReview({ outOfNetworkReviewId: embeddedOonReviewId || "" });

  const saveNurseReviewDraftWithRedirect = useCallback(async () => {
    try {
      let payload: NurseReview = { ...nurseReview };
      if (shouldSubmitEmbeddedOonReview) {
        payload = { ...payload, outOfNetworkReview: oonReview };
      }
      const res = await update(payload);
      setNurseReview(res);
      if (submitDraftHighlights) {
        await submitDraftHighlights();
      }
      if (shouldSubmitEmbeddedOonReview) {
        await saveOutOfNetworkReviewDraft?.(true);
      }
      userClickInfoTracking &&
        (await postBatchActivities(
          createBatchActivities(
            "NURSE_REVIEW",
            "REVIEW_SUBMISSION",
            "INTERACTION",
            userClickInfoTracking,
            searchInfos,
            pastedAttachmentTexts,
            userId,
            res.id,
            serviceRequest.cohereId,
            serviceRequest.id
          )
        ));
      setUserClickInfoTracking?.(getEmptyUserClickTracking());
      setSearchInfos?.([]);
      onFinishEditingInternal();
      redirectToPatientSummaryFromReview({ serviceRequest, caseId, reviewId: nurseReview.id, navigate });
    } catch {
      setDuplicateReviewModalOpen(true);
    }
  }, [
    caseId,
    navigate,
    nurseReview,
    onFinishEditingInternal,
    pastedAttachmentTexts,
    postBatchActivities,
    searchInfos,
    serviceRequest,
    setNurseReview,
    setSearchInfos,
    setUserClickInfoTracking,
    submitDraftHighlights,
    update,
    userClickInfoTracking,
    userId,
    shouldSubmitEmbeddedOonReview,
    saveOutOfNetworkReviewDraft,
    oonReview,
  ]);

  // Hooks & callbacks to submit a review
  const [isSubmissionModalOpen, setIsSubmissionModalOpen] = useState(false);
  const [displayCaseOutcome, setDisplayCaseOutcome] = useState<ExtendedRnReviewOutcome>(nurseReview.reviewOutcome);
  const isClinicalPreprocessingReview = search.has(CREATE_CPP_REVIEW_PARAM);

  // For partial approval
  const {
    approvedProcedureCodes,
    updateSinglePxCodeUnit,
    requestedProcedureCodes,
    approvedUnits,
    approvedPxCodesAreInvalid,
  } = useProcedureCodesState({
    serviceRequest,
    existingReviews,
    groupedByClinicalServices: false,
    currentReviewId: nurseReview.id,
  });

  const {
    mutate: completeServiceCase,
    loading: completingServiceCase,
    error: completeServiceCaseError,
  } = useCompleteServiceCase({
    id: caseId,
    base: `${config.QM_SERVICE_API_URL}`,
    onMutate: () =>
      shouldUseAppendedRoute && currentCase?.id
        ? routeBackToOriginFromReview(Boolean(referredByQM), currentCase?.id, nurseReview.id, serviceRequest, navigate)
        : window.location.assign(`${queueMgmtBaseUrl()}/case_complete/${caseId}`),
  });
  const {
    mutate: submit,
    error: submitError,
    loading: submitLoading,
  } = useSubmitNurseReview({
    reviewId: nurseReview.id,
    queryParams: {
      isUserAudited: isQmUserAudited,
    },
  });
  useEffect(() => {
    if (completeServiceCaseError) {
      enqueueSnackbar(`Failed to complete Service Case: ${caseId}`, { variant: "error" });
    }
  }, [caseId, completeServiceCaseError, enqueueSnackbar]);
  useEffect(() => {
    if (submitError) {
      setIsSubmissionModalOpen(false);
      if (submitError.status === 409) {
        setDuplicateReviewModalOpen(true);
      } else {
        setErrorDetails({
          ...extractErrorDetails(submitError, serviceRequest.id, nurseReview.id),
        });
        setOpenHardRedirectOnSubmitModal(true);
      }
    }
  }, [
    submitError,
    serviceRequest.id,
    nurseReview.id,
    setOpenHardRedirectOnSubmitModal,
    setIsSubmissionModalOpen,
    setErrorDetails,
  ]);

  const { continueServiceRequestTillNextReviewDate, loadingContinuation } = useContinueServiceRequestTillNextReviewDate(
    serviceRequest?.authorization?.id || "",
    facilityBasedFeatureEnabled
  );

  const isPatientNotYetAdmittedForInpatient = patientNotYetAdmittedForInpatient(serviceRequest);

  const facilityBasedConfig = useConfiguration(
    "facilityBasedRequestConfiguration",
    serviceRequest?.healthPlanName,
    serviceRequest?.delegatedVendor
  );

  const submitNurseReview = useCallback(
    async (
      event: React.FormEvent<HTMLFormElement>,
      authorization?: Partial<AuthorizationResponse>,
      serviceRequestUpdates?: Partial<ServiceRequestUpdatePayload>,
      addTatExtension?: boolean,
      reviewerCreateContinuationAcknowledgement?: boolean
    ) => {
      event.preventDefault();
      setIsSubmissionDisabled(true);
      if (nurseReview.reviewOutcome) {
        setReviewSubmissionLoading(true);
        if (submitHighlights) {
          await submitHighlights();
        }
        // outreach attempts must be saved before review submissions to fetch details to case on qm

        let payload: NurseReviewSubmitPayload = {
          ...nurseReview,
          isClinicalPreprocessingReview: isClinicalPreprocessingReview,
          nudgeAttempted: nurseReview.nudgeAttempted || false,
          // Why not just submit nurseReview? Typescript tomfoolery to convince
          // the compiler that reviewOutcome is not null (which it isn't because of the above)
          reviewOutcome: nurseReview.reviewOutcome,
          denialStatus: "NOT_STARTED",
          guidelines: nurseReview.guidelines || [],
          approvedUnits: approvedUnits,
          approvedSemanticProcedureCodes:
            nurseReview.reviewOutcome === "RECOMMENDED_PARTIAL_APPROVAL" ? approvedProcedureCodes : undefined,
          reviewerCreateContinuationAcknowledgement: reviewerCreateContinuationAcknowledgement,
        };

        if (isReadmissionFeatureEnabled) {
          payload = {
            ...payload,
            isReadmission: isReadmission,
            anchorAuthNumber: anchorAuthNumber,
          };
        }

        if (shouldSubmitEmbeddedOonReview && oonReview?.reviewOutcome) {
          payload = {
            ...payload,
            outOfNetworkReview: oonReview,
          };
        }
        if (sendOptionalDecisionLetter && serviceRequest.isMemberRequestedAuth) {
          payload = {
            ...payload,
            additionalRecipients: ["PROVIDER"],
          };
        }

        if (facilityBasedFeatureEnabled) {
          const dischargeDetails = buildAuthorizationDischargeDetails?.() || {};

          payload = {
            ...payload,
            nextReviewDate: authorization?.nextReviewDate,
          };

          if (!isPreService && !isPatientNotYetAdmittedForInpatient) {
            payload = {
              ...payload,
              actualDischargeDateTime: dischargeDetails?.actualDischargeDateTime,
              dischargedTo: dischargeDetails?.dischargedTo?.enumName,
              patientStatus: isDischarged ? "DISCHARGED" : serviceRequest.patientStatus,
              patientStayDates: serviceRequestUpdates?.patientStayDates,
            };
          }

          if (patientStayManager?.reviewStayDateEditsFlag) {
            const isPatientAdmittedOrDischarged = patientAdmittedOrDischarged(
              patientStayManager?.formContent?.patientStatus
            );
            payload = {
              ...payload,
              actualDischargeDateTime: patientStayManager.getCombinedDischargedDateTime(),
              admissionDateTime: getNoTimezoneDateTimeString(
                patientStayManager?.formContent?.admissionDate,
                patientStayManager?.formContent?.admissionTime
              ),
              admissionSource: patientStayManager?.formContent?.admissionSource?.enumName,
              dischargedTo: patientStayManager.formContent.dischargedTo?.enumName,
              patientStatus: patientStayManager?.formContent?.patientStatus,
              expectedAdmissionDate: formatDateToISODate(patientStayManager?.formContent?.expectedAdmissionDate),
              patientStayDates:
                facilityBasedConfig?.includePatientStayDatesOnPlannedAdmission || isPatientAdmittedOrDischarged
                  ? serviceRequestUpdates?.patientStayDates
                  : undefined,
            };
          }
        }
        const res = await submit(payload, {
          queryParams: {
            isUserAudited: isQmUserAudited,
            tatExtension: addTatExtension,
          },
        });

        // Note: posting the outreach attempt after the nurse review because both update the SF integration status.
        // The outreach attempt post is faster than nurse review submission, though, so less likely to run into an
        // OptimisticLockingException
        const postedOutreachAttempt = nurseReview.nudgeAttempted && (await saveOutreachAttempt?.());
        userClickInfoTracking &&
          (await postBatchActivities(
            createBatchActivities(
              "NURSE_REVIEW",
              "REVIEW_SUBMISSION",
              "INTERACTION",
              userClickInfoTracking,
              searchInfos,
              pastedAttachmentTexts,
              userId,
              res.id,
              serviceRequest.cohereId,
              serviceRequest.id
            )
          ));

        const snap = res.guidelines ? guidelineSnapshotFromReview(res) : undefined;
        await trackUserActivityInteraction({
          event: "NURSE_REVIEW",
          stage: "REVIEW_SUBMISSION",
          activityContext: { reviewId: res.id, serviceRequestId: serviceRequest.id },
          afterGuidelineSnapshot: snap,
          attachmentGuidelineTextHighlightsSnapshot: {
            ...attachmentGuidelineTextHighlightsSnapshot,
            reviewer: res.createdByName,
            dateCompleted: res.dateCompleted,
            reviewOutcome: res.reviewOutcome as ReviewOutcome,
            textHighlights: getHighlightPayload?.() || undefined,
          },
        });

        if (shouldSubmitEmbeddedOonReview) {
          await submitEmbeddedOonReview?.(true);
        }

        setUserClickInfoTracking?.(getEmptyUserClickTracking());
        setSearchInfos?.([]);
        onFinishEditingInternal();

        const lastPatientStayDateRange = last(patientStayDateRanges);
        // will not create continuation on FE when autoCreateFinalDecisionContinuations is ON
        if (!continuationConfiguration?.autoCreateFinalDecisionContinuations) {
          await continueServiceRequestTillNextReviewDate({
            serviceRequestId: serviceRequest.id,
            nextReviewDate: authorization?.nextReviewDate?.toString(),
            isDischarged,
            reviewOutcome: nurseReview.reviewOutcome as AuthStatus,
            lastStayDateRange: lastPatientStayDateRange,
            triggerStatuses: ["APPROVED", "DENIED"],
          });
        }

        if (caseId || (currentCase?.id && shouldUseAppendedRoute)) {
          await completeServiceCase({
            reviewOutcome: nurseReview.reviewOutcome as ReviewOutcome | undefined,
            dateCompleted: new Date().toISOString(),
            caseNotes: nurseReview.authorizationNote,
            reviewId: nurseReview.id,
            ...(postedOutreachAttempt ? { outreachCaseOutcome: { outreachAttemptId: postedOutreachAttempt.id } } : {}),
          });
          setIsSubmissionModalOpen(false);
        } else if (clinicalReviewView) {
          setIsSubmissionModalOpen(false);
          redirectToPatientSummaryFromReview({ serviceRequest, reviewId: nurseReview.id, navigate });
        } else {
          setNurseReview(res);
        }
        setReviewSubmissionLoading(false);
      } else {
        setReviewSubmissionLoading(false);
        throw new Error("Review outcome must not be empty to submit");
      }
    },
    [
      nurseReview,
      submitHighlights,
      isClinicalPreprocessingReview,
      approvedUnits,
      approvedProcedureCodes,
      isReadmissionFeatureEnabled,
      shouldSubmitEmbeddedOonReview,
      oonReview,
      sendOptionalDecisionLetter,
      serviceRequest,
      facilityBasedFeatureEnabled,
      submit,
      isQmUserAudited,
      saveOutreachAttempt,
      userClickInfoTracking,
      postBatchActivities,
      searchInfos,
      pastedAttachmentTexts,
      userId,
      trackUserActivityInteraction,
      attachmentGuidelineTextHighlightsSnapshot,
      getHighlightPayload,
      setUserClickInfoTracking,
      setSearchInfos,
      onFinishEditingInternal,
      patientStayDateRanges,
      continuationConfiguration?.autoCreateFinalDecisionContinuations,
      caseId,
      currentCase?.id,
      shouldUseAppendedRoute,
      clinicalReviewView,
      isReadmission,
      anchorAuthNumber,
      buildAuthorizationDischargeDetails,
      isPreService,
      isPatientNotYetAdmittedForInpatient,
      patientStayManager,
      isDischarged,
      facilityBasedConfig?.includePatientStayDatesOnPlannedAdmission,
      submitEmbeddedOonReview,
      continueServiceRequestTillNextReviewDate,
      completeServiceCase,
      navigate,
      setNurseReview,
    ]
  );

  // Hooks & callbacks to discard a review
  const {
    mutate: discard,
    error: discardError,
    loading: discarding,
  } = useDiscardNurseReview({
    reviewId: nurseReview.id,
  });
  useEffect(() => {
    if (discardError) {
      const errorAny: any = discardError;
      const message = errorAny?.data?.message;
      const embeddedError = errorAny?.data?._embedded?.errors?.map((error: any) => error?.message).join("\n");
      const errorMessage = (
        <Box>
          <Box>Error discarding clinical review</Box>
          {embeddedError ? <Box>{embeddedError}</Box> : message && <Box>{message}</Box>}
        </Box>
      );
      enqueueSnackbar(errorMessage, {
        variant: "error",
      });
      logError(`Error discarding clinical review ${stringifyError(discardError.data)}`);
    }
  }, [discardError, enqueueSnackbar]);

  const discardNurseReview = useCallback(async () => {
    let payload: NurseReview = { ...nurseReview };
    const shouldDiscardEmbeddedOonReview = canShowExtraOONTab && oonReview?.id !== UNSAVED_OON;
    if (shouldDiscardEmbeddedOonReview) {
      payload = { ...payload, outOfNetworkReview: oonReview };
    }
    const res = await discard({
      ...payload,
    });
    userClickInfoTracking &&
      (await postBatchActivities(
        createBatchActivities(
          "NURSE_REVIEW",
          "REVIEW_SUBMISSION",
          "INTERACTION",
          userClickInfoTracking,
          searchInfos,
          pastedAttachmentTexts,
          userId,
          res.id,
          serviceRequest.cohereId,
          serviceRequest.id
        )
      ));
    if (canShowExtraOONTab && oonReview?.id !== UNSAVED_OON) {
      await discardOutOfNetworkReview?.(true);
    }
    setUserClickInfoTracking?.(getEmptyUserClickTracking());
    setSearchInfos?.([]);
    onFinishEditingInternal();
    return res;
  }, [
    discard,
    nurseReview,
    onFinishEditingInternal,
    pastedAttachmentTexts,
    postBatchActivities,
    searchInfos,
    serviceRequest.cohereId,
    serviceRequest.id,
    setSearchInfos,
    setUserClickInfoTracking,
    userClickInfoTracking,
    userId,
    canShowExtraOONTab,
    oonReview,
    discardOutOfNetworkReview,
  ]);

  const discardNurseReviewWithRedirects = useCallback(async () => {
    const res = await discardNurseReview();
    setNurseReview(res);
    if (clinicalReviewView) {
      redirectToPatientSummaryFromReview({ serviceRequest, caseId, reviewId: nurseReview.id, navigate });
    }
  }, [discardNurseReview, setNurseReview, clinicalReviewView, serviceRequest, nurseReview.id, caseId, navigate]);

  const finishReviewLoading =
    reviewSubmissionLoading ||
    submitLoading ||
    !!submittingHighlights ||
    !!submittingDraftHighlights ||
    completingServiceCase ||
    loadingContinuation ||
    !!outreachAttemptLoading;

  return (
    <div
      style={{ backgroundColor: clinicalReviewView ? theme.palette.background.paper : "#F5F5F5", paddingBottom: 8 }}
      ref={reviewRef}
      data-testid="nurse-review-container"
    >
      {willAutoApprove && (
        <Alert style={{ backgroundColor: "#F5F5F5", paddingTop: 28 }} severity="success">
          <div style={{ color: colorsLight.success.main }}>This request met auto-approval criteria</div>
        </Alert>
      )}
      <Grid container wrap="nowrap" direction="row" justifyContent="space-between" alignItems="center" spacing={0}>
        <Grid item xs={lettersExpansionConfig?.adhocLetter ? 9 : 12}>
          <ReviewHeader
            toggleExpanded={toggleExpanded}
            expanded={expanded}
            reviewDateCreated={nurseReview.dateCreated}
            reviewLastUpdated={nurseReview.lastUpdated}
            reviewStatus={nurseReview.reviewStatus}
            reviewId={nurseReview.id}
            reviewType={nurseReview.reviewType}
            reviewCreatedByName={nurseReview.createdByName}
            reviewDateCompleted={nurseReview.dateCompleted}
            clinicalReviewView={clinicalReviewView}
            coverageLevelDetails={nurseReview.coverageLevelDetails}
          />
        </Grid>

        {lettersExpansionConfig?.adhocLetter && (
          <Grid item xs={3} style={{ display: "flex", justifyContent: "flex-end", marginRight: "24px" }}>
            <InlineButton
              startIcon={<OpenInNewIcon />}
              onClick={() => {
                window.open(generatePath(routes.ADHOC_LETTER, { serviceRequestId: serviceRequest.id }));
              }}
            >
              Send a letter
            </InlineButton>
          </Grid>
        )}
      </Grid>
      <ReviewDetailsCollapse
        in={expanded}
        timeout={100}
        unmountOnExit
        style={clinicalReviewView ? { marginLeft: 16 } : {}}
      >
        {!clinicalReviewView && (
          <GuidelineReview
            id="tabpanel-0"
            review={nurseReview}
            noClinicalInformationProvided={nurseReview?.noClinicalInformationProvided}
            serviceRequest={serviceRequest}
            expandable={expandableGuidelines}
          />
        )}
        <NurseReviewEdit
          serviceRequest={serviceRequest}
          nurseReview={nurseReview}
          setNurseReview={setNurseReview}
          openSubmissionModal={() => {
            setIsSubmissionModalOpen(true);
          }}
          onDiscard={discardNurseReviewWithRedirects}
          discarding={discarding}
          willAutoApprove={willAutoApprove}
          copiedAttachmentText={copiedAttachmentText}
          setPastedAttachmentTexts={setPastedAttachmentTexts}
          outreachAttempt={outreachAttempt}
          setOutreachAttempt={setOutreachAttempt}
          isSubmissionDisabled={isSubmissionDisabled}
          patientStayDateRanges={patientStayDateRanges}
          setPatientStayDateRanges={setPatientStayDateRanges}
          patientStaysErrorStates={patientStaysErrorStates}
          dischargeDetails={dischargeDetails}
          setDischargeDetails={setDischargeDetails}
          isDischarged={isDischarged}
          setIsDischarged={setIsDischarged}
          facilityBasedFeatureEnabled={facilityBasedFeatureEnabled}
          outreachFormConfig={outreachFormConfig}
          outreachFormErrors={outreachFormErrors}
          setOutreachFormErrors={setOutreachFormErrors}
          validateOutreachForm={validateOutreachForm}
          hasOutreachAttempt={hasOutreachAttempt}
          setHasOutreachAttempt={setHasOutreachAttempt}
          updating={updating}
          saveDraft={saveNurseReviewDraftWithRedirect}
          isReadmission={isReadmission}
          setIsReadmission={setIsReadmission}
          anchorAuthNumber={anchorAuthNumber}
          setAnchorAuthNumber={setAnchorAuthNumber}
          hasErrorWithAnchorAuth={hasErrorWithAnchorAuth}
          setHasErrorWithAnchorAuth={setHasErrorWithAnchorAuth}
          isReadmissionFeatureEnabled={isReadmissionFeatureEnabled}
          authorization={authorization}
          isStayDatesValid={isStayDatesValid}
          isDischargeDetailsValid={isDischargeDetailsValid || true}
          patientStayManager={patientStayManager}
          validateOONReview={validateOONReview}
          shouldSubmitEmbededOonReview={shouldSubmitEmbeddedOonReview}
          discardAndExitDisabled={finishReviewLoading || isSubmissionModalOpen}
          automatedReviewNoteEditorState={automatedReviewNoteEditorState}
          setAutomatedReviewNoteEditorState={setAutomatedReviewNoteEditorState}
        />
        <NurseReviewSubmissionModal
          loading={finishReviewLoading}
          isOpen={isSubmissionModalOpen || false}
          setIsOpen={setIsSubmissionModalOpen}
          onSubmit={submitNurseReview}
          displayCaseOutcome={displayCaseOutcome}
          setDisplayCaseOutcome={setDisplayCaseOutcome}
          onDiscard={discardNurseReview}
          discarding={discarding}
          isSubmissionDisabled={isSubmissionDisabled}
          serviceRequest={serviceRequest}
          initialPatientStayDateRanges={patientStayDateRanges}
          approvedUnits={approvedUnits}
          procedureCodes={requestedProcedureCodes}
          review={nurseReview}
          setReview={setNurseReview}
          isDischarged={isDischarged}
          facilityBasedFeatureEnabled={facilityBasedFeatureEnabled}
          updateSinglePxCodeUnit={updateSinglePxCodeUnit}
          approvedPxCodesAreInvalid={approvedPxCodesAreInvalid}
          isReadmission={isReadmission}
          checkEmptyRequestedLoc={checkEmptyRequestedLoc}
          sendOptionalDecisionLetters={sendOptionalDecisionLetter}
          setSendOptionalDecisionLetter={setSendOptionalDecisionLetter}
          shouldSubmitEmbeddedOonReview={shouldSubmitEmbeddedOonReview}
          disableFinishOonModalButton={disableFinishOonModalButton}
          oonReview={oonReview}
          setOonReview={setOonReview}
          oonAllowedReviewOutcomes={oonAllowedReviewOutcomes}
          patientStayManager={patientStayManager}
        />
        <DetailedHardRedirectClinicalReviewModal
          isOpen={openHardRedirectOnSubmitModal}
          setIsOpen={setOpenHardRedirectOnSubmitModal}
          errorDetails={errorDetails}
        />
        <InformativeModal
          open={duplicateReviewModalOpen}
          onClose={() => {
            setDuplicateReviewModalOpen(false);
          }}
          headerText={"Review completed by another user"}
          additionalInfoText={"This review was completed by another user. Your review and notes will not be saved."}
          primaryButtonText={"Go to patient summary"}
          primaryButtonRoute={routeToPatientSummaryFromReview({
            serviceRequestId: serviceRequest.id,
            patientId: serviceRequest?.patient?.id,
          })}
        />
      </ReviewDetailsCollapse>
    </div>
  );
};

const NurseReviewReadonly = React.memo(
  ({
    serviceRequest,
    nurseReview,
    expanded,
    toggleExpanded,
    clinicalReviewView,
    reviewRef,
    expandableGuidelines,
    unexpandable,
    reviewDetailsCollapseStyle,
    ...otherProps
  }: NurseReviewReadonlyProps) => {
    return (
      <div ref={reviewRef}>
        <ReviewHeader
          toggleExpanded={toggleExpanded}
          expanded={expanded}
          reviewDateCreated={nurseReview.dateCreated}
          reviewLastUpdated={nurseReview.lastUpdated}
          reviewStatus={nurseReview.reviewStatus}
          reviewOutcome={nurseReview.reviewOutcome}
          reviewType={nurseReview.reviewType}
          reviewId={nurseReview.id}
          reviewCreatedByName={nurseReview.createdByName}
          reviewDateCompleted={nurseReview.dateCompleted}
          reviewText={nurseReview.decisionReasoning}
          reviewCompletedByName={nurseReview?.reviewerDetails?.reviewerName || nurseReview.completedByName}
          clinicalReviewView={clinicalReviewView}
          unexpandable={unexpandable}
          coverageLevelDetails={nurseReview.coverageLevelDetails}
          reviewerDetails={nurseReview?.reviewerDetails}
          {...otherProps}
        />
        <ReviewDetailsCollapse
          in={expanded}
          timeout={100}
          unmountOnExit
          style={reviewDetailsCollapseStyle ? reviewDetailsCollapseStyle : clinicalReviewView ? { marginLeft: 16 } : {}}
        >
          <NurseReviewReadOnly nurseReview={nurseReview} hideDecisionReasoning={otherProps.authorizationReviewView} />
          <GuidelineReview
            id="tabpanel-0"
            review={nurseReview}
            noClinicalInformationProvided={nurseReview?.noClinicalInformationProvided}
            serviceRequest={serviceRequest}
            expandable={expandableGuidelines}
            authorizationReviewView={otherProps.authorizationReviewView}
          />
        </ReviewDetailsCollapse>
      </div>
    );
  }
);

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ReviewDetailsCollapse = styled(Collapse)(({ theme }) => ({
  margin: theme.spacing(0, 3, 3),
}));

export default NurseReviewComponent;
