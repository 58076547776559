import PatientStayCard from "../FillFormsGeneralAuthSubmission/PatientStayCard";
import { Box, Button, Container, Grid, Divider } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Alert,
  Chip,
  FullPageBanner,
  H6,
  InformativeModal,
  InlineButton,
  MAX_DATE_SELECT_DATE,
  Sanitized,
  TextField,
  colorsLight,
  today,
  useFeature,
  useMuiContainerStyles,
} from "@coherehealth/common";
import { validatePatientStayRange } from "util/authorization";
import { CohereCard, H2 } from "@coherehealth/design-system";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import { PriorAuthRequirements, formContentFromAuthBuilderContext, ServiceRequestFormStateSetters } from "../common";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ServicesAndProceduresCard, {
  ProcedureCodeBucketsState,
} from "../FillFormsGeneralAuthSubmission/ServicesAndProceduresCard";
import {
  Coverage,
  AuthStatus,
  Patient,
  ProcedureCode,
  CardExtensionProcedureCode,
  CardExtensionDetails,
  ServiceRequestResponse,
} from "@coherehealth/core-platform-api";
import { ServiceRequestFormContent } from "components/ServiceRequest";
import RequestorCard, { Props as RequestorProps } from "components/Requestor/RequestorCard";
import { useServiceRequestConfigSpec } from "components/ServiceRequest/ConfigurableServiceRequestForm";
import { useServiceRequestFormRedirectDispatch } from "components/ServiceRequest/ServiceRequestForm/ServiceRequestFormRedirectModal";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import useGetFacilityBasedRequestConfigurationByPayer, {
  useGetNonPalCheckboxConfigurationByPayer,
  useGetServiceRequestStateConfigurationByPayerAndAuthStatus,
} from "hooks/useGetFeatureConfigurations";
import { checkOnetimeRequestCoverage } from "util/patientUtils";
import ErrorIcon from "@material-ui/icons/Error";
import PriorAuthResultCard from "../GatherRequirements/PriorAuthResultCard";
import { useGetReturnToUrl } from "util/queryParams";
import { Error, OpenInNew } from "@material-ui/icons";
import { ServiceRequestFieldName } from "components/ServiceRequest/ConfigurableServiceRequestForm/serviceRequestFormConfiguration";
import listReplace from "util/listReplace";
import * as datefns from "date-fns";
import { MIN_START_DATE, timePattern } from "util/dateUtils";
import { useAuthorized } from "authorization";
import { RequestExpedited } from "components/ServiceRequest/ServiceRequestForm/Expedited";
import DateRangeSelect from "../FillFormsGeneralAuthSubmission/DateRangeSelect";
import { useCalculateDateSelectionMessageDisplay } from "components/AuthBuilder/FillFormsGeneralAuthSubmission/utils";
import NonPalProcedureCodeTableWrapper from "./NonPalProcedureCodeTableWrapper";
import PriorAuthCheckBanner from "./PriorAuthCheckBanner";
import { useLocation } from "react-router";
import { useIsFaxAuthBuilderWorkflow } from "util/attachmentUtil";
import { fieldIsValid, SRFormConfigFieldWrapper } from "common/FormConfigUtils";
import PurpleClipboard from "components/images/PurpleClipboard";
import { validatePatientStayDates } from "util/serviceRequest";
import { NonPalReasonModal } from "../modals/NonPalReasonModal";

interface Props extends ProcedureCodeBucketsState, RequestorProps, ServiceRequestFormStateSetters {
  setServiceRequestFormContents: Dispatch<SetStateAction<ServiceRequestFormContent[]>>;
  openNonPalConfirmationModal?: boolean;
  setOpenNonPalConfirmationModal?: Dispatch<SetStateAction<boolean>>;
  setUserSelectedNonPalCode?: (value: boolean) => void;
  priorAuthRequirements: PriorAuthRequirements;
  activeCoverage: Coverage | undefined;
  formContent: ServiceRequestFormContent;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  attemptedSubmit: boolean;
  patientHealthPlanName: string;
  patient: Patient | null;
  workflowId?: string;
  authStatus: AuthStatus;
  delegatedVendorName?: string;
  isContinuation?: boolean;
  requestorProps?: RequestorProps;
  showRequestorCard?: boolean;
  allowMutablePxCodesWithNonPal?: boolean;
  setServiceDetailsFormsCanBeSubmitted: Dispatch<SetStateAction<boolean[]>>;
  setPriorAuthRequirements: Dispatch<SetStateAction<PriorAuthRequirements>>;
  hideExpeditedRequestCheckbox?: boolean;
  crdProcedureCodeResults: CardExtensionProcedureCode[] | undefined;
  crdExtensionCardResults?: CardExtensionDetails | undefined;
  allowedPxCodePerPayer?: number;
  serviceRequests?: ServiceRequestResponse[];
}

export default function PatientStayAndServicesCard({
  openNonPalConfirmationModal,
  setOpenNonPalConfirmationModal,
  setUserSelectedNonPalCode,
  priorAuthRequirements,
  activeCoverage,
  formContent,
  setFormContent,
  attemptedSubmit,
  patientHealthPlanName,
  patient,
  workflowId,
  authStatus,
  delegatedVendorName,
  isContinuation,
  /** Procedure Code Buckets */
  palProcedureCodes,
  nonPalProcedureCodes,
  nonCohereProcedureCodes,
  setPalProcedureCodes,
  setNonCohereProcedureCodes,
  setNonPalProcedureCodes,
  setServiceRequestFormContents,
  setServiceDetailsFormsCanBeSubmitted,
  requestorProps,
  showRequestorCard,
  setPriorAuthRequirements,
  allowMutablePxCodesWithNonPal,
  hideExpeditedRequestCheckbox,
  crdProcedureCodeResults,
  crdExtensionCardResults,
  allowedPxCodePerPayer,
  serviceRequests,
}: Props) {
  const location = useLocation();
  const isFaxAuthBuilderFlow = useIsFaxAuthBuilderWorkflow(location);

  const { data: nonPalCheckboxConfiguration } = useGetNonPalCheckboxConfigurationByPayer(patientHealthPlanName ?? "");
  const nonPalAvailableReasons =
    nonPalCheckboxConfiguration &&
    nonPalCheckboxConfiguration.reasonsToIncludeNonPalCodes &&
    nonPalCheckboxConfiguration.reasonsToIncludeNonPalCodes.length > 0
      ? nonPalCheckboxConfiguration.reasonsToIncludeNonPalCodes
      : undefined;

  const showLearnMoreButton = nonPalCheckboxConfiguration?.enableLearnMoreButton;

  const { facilityBasedFeatureEnabled, includePatientStayDatesOnPlannedAdmission } =
    useGetFacilityBasedRequestConfigurationByPayer({
      healthPlanName: patientHealthPlanName,
      encounterType: formContent.isInpatient ? "INPATIENT" : "OUTPATIENT",
      priorAuthRequirements,
      skipRequestTimingCheck: true,
      srContent: formContent,
    });
  const { formFieldConfigurations, pxCodeAttributeConfiguration } = useServiceRequestConfigSpec({
    ...formContent,
    patientId: patient?.id || "",
    healthPlanName: patientHealthPlanName,
  });

  const nonPalRequiredReason = useFeature("nonPalRequiredReasonFeature");

  // Default to "Cohere" if delegatedVendorName is null or an empty string
  const srDelegatedVendorName = serviceRequests?.[0]?.delegatedVendor || "Cohere";

  // Determine the delegated vendor name, defaulting to "Cohere" if it's an empty string
  const configuredDelegatedVendorName =
    pxCodeAttributeConfiguration?.delegatedVendorName == null ||
    pxCodeAttributeConfiguration?.delegatedVendorName === ""
      ? "Cohere"
      : pxCodeAttributeConfiguration?.delegatedVendorName;

  // Check if the Px Code Attributed Field should be shown based on configuration and vendor name
  const showPxBasedAttributedField =
    formFieldConfigurations?.selectedDetails?.fieldSpec !== "OMIT" &&
    pxCodeAttributeConfiguration?.showPxCodeAttributeField &&
    configuredDelegatedVendorName === srDelegatedVendorName;

  const hasUnits = Boolean(formContent.units);
  const allPxsHaveUnits = Boolean(formContent.procedureCodes.every((px) => !!px.units));
  const hasPxs = formContent.procedureCodes.length > 0;
  const setCanBeSubmitted = useCallback(
    (canBeSubmitted: boolean) => setServiceDetailsFormsCanBeSubmitted((prev) => listReplace(prev, 0, canBeSubmitted)),
    [setServiceDetailsFormsCanBeSubmitted]
  );
  const showEndDate = Number(formContent.units) !== 1;

  const endDateCoverage = useMemo(
    () =>
      patient?.coverages && formContent?.endDate
        ? checkOnetimeRequestCoverage(
            patient.coverages,
            formContent?.endDate || "",
            formFieldConfigurations.blockUserIfLessThanRecommendedEndDate?.fieldSpec === "REQUIRED"
          )
        : undefined,
    [patient?.coverages, formContent?.endDate, formFieldConfigurations.blockUserIfLessThanRecommendedEndDate?.fieldSpec]
  );
  const startDateCoverage = useMemo(() => {
    if (patient?.coverages) {
      if (formContent?.admissionDate) {
        return checkOnetimeRequestCoverage(patient?.coverages, formContent?.admissionDate);
      } else if (formContent?.startDate) {
        return checkOnetimeRequestCoverage(patient?.coverages, formContent?.startDate);
      }
    }
    return undefined;
  }, [patient?.coverages, formContent?.admissionDate, formContent?.startDate]);
  // StartEndDate restrictions
  const startDateExceedsMinStartDate = formContent.startDate >= MIN_START_DATE;
  const hasStartDate = Boolean(formContent.startDate);
  const hasEndDateIfApplicable = Boolean(formContent.endDate);
  const hasAllowedProcedureCodePerPayer = Boolean(
    allowedPxCodePerPayer && formContent?.procedureCodes.filter((code) => code.groupId).length <= allowedPxCodePerPayer
  );

  const hasValidStartDate = (formContent.startDate ?? MAX_DATE_SELECT_DATE) <= MAX_DATE_SELECT_DATE;
  const hasValidEndDate = (formContent.endDate ?? MAX_DATE_SELECT_DATE) <= MAX_DATE_SELECT_DATE;
  const endDateExceedsStartDate = formContent.endDate ? formContent.endDate >= formContent.startDate : true;
  const validatePatientStayDateRangesEndDate = validatePatientStayRange(formContent.patientStayDateRanges ?? []);
  const hasMinStayDays =
    formContent.patientStayDateRanges?.length && formContent.patientStayDateRanges.length > 0
      ? formContent.patientStayDateRanges[0].rangeEndDate !== null
      : false;
  const hasValidStayDateRange = validatePatientStayDateRangesEndDate.filter((error) => error.isRowInValid)?.length;
  const isPatientStayDatesValid = hasMinStayDays && !hasValidStayDateRange;

  const isAdmissionTimeValid = timePattern.test(formContent?.admissionTime || "");
  const isDischargeTimeValid = timePattern.test(formContent?.dischargeTime || "");

  const hasAdmissionDate =
    Boolean(formContent?.admissionDate) &&
    !datefns.isAfter(new Date(formContent?.admissionDate ?? ""), new Date()) &&
    startDateCoverage?.inRange;

  const hasAdmissionTime = Boolean(formContent?.admissionTime) && isAdmissionTimeValid;
  const hasDischargeTime = Boolean(formContent?.dischargeTime) && isDischargeTimeValid;
  const hasDischargedTo = Boolean(formContent?.dischargedTo);

  const hasDischargeDate =
    formContent?.dischargeDate !== undefined &&
    datefns.isValid(formContent?.dischargeDate) &&
    !datefns.isBefore(new Date(formContent?.dischargeDate ?? ""), new Date(formContent?.admissionDate ?? "")) &&
    !datefns.isAfter(new Date(formContent?.dischargeDate ?? ""), new Date());

  // If the form is empty we invalidate `!isExpectedAdmissionDateEdited` check
  const [isExpectedAdmissionDateEdited, setExpectedAdmissionDateEdited] = useState(!formContent.expectedAdmissionDate);

  const hasExpectedAdmissionDate =
    Boolean(formContent?.expectedAdmissionDate) &&
    ((datefns.isValid(formContent?.expectedAdmissionDate) &&
      datefns.isAfter(formContent?.expectedAdmissionDate ?? new Date(), datefns.subDays(new Date(), 1))) ||
      !isExpectedAdmissionDateEdited);

  const hasExpectedAdmissionDateForServiceBasedSR = facilityBasedFeatureEnabled
    ? Boolean(formContent?.expectedAdmissionDate) &&
      datefns.isValid(formContent?.expectedAdmissionDate) &&
      (startDateCoverage?.inRange || !isExpectedAdmissionDateEdited)
    : Boolean(formContent?.startDate) &&
      datefns.isValid(formContent?.startDate) &&
      (startDateCoverage?.inRange || !isExpectedAdmissionDateEdited);

  const expectedAdmissionDateValueForPlannedAdmission =
    formContent?.admissionDate || formContent?.expectedAdmissionDate;

  const dischargeDate = new Date(formContent?.expectedDischargeDate ?? "");
  const admissionDate = new Date(expectedAdmissionDateValueForPlannedAdmission ?? "");

  const hasExpectedDischargeDate =
    (formContent?.expectedDischargeDate !== undefined &&
      datefns.isValid(formContent?.expectedDischargeDate) &&
      !datefns.isBefore(dischargeDate, admissionDate)) ||
    datefns.isSameDay(dischargeDate, admissionDate);

  const inPatientValidatePx =
    formContent.isInpatient &&
    facilityBasedFeatureEnabled &&
    (!formContent.procedureCodes || formContent.procedureCodes.length === 0);

  const { showExceededDurationLimitMessageErrorState, showBelowDurationLimitMessageErrorState } =
    useCalculateDateSelectionMessageDisplay(formContent, patient?.coverages || [], patient, formFieldConfigurations);

  // Flattening nonCohereProcedureCodes to a single list of procedure codes
  const nonCohereCodesList = nonCohereProcedureCodes.flatMap((group) => group.procedureCode);

  // Filtering formContent's procedureCodes based on absence in nonCohereCodesList
  const filteredSemanticProcedureCodes =
    formContent?.procedureCodes?.filter(
      (pxCode) => !nonCohereCodesList.some((nonCohereCode) => pxCode.code === nonCohereCode.code)
    ) || [];

  // Helper function to filter by matching codes
  const filterByCodeMatch = (procedureCodes: ProcedureCode[], referenceCodes: ProcedureCode[]) =>
    procedureCodes.filter((pxCode) => referenceCodes.some((refCode) => pxCode.code === refCode.code));

  // Filtering PAL and Non-PAL codes
  const filteredOnlyPalCodes = filterByCodeMatch(filteredSemanticProcedureCodes, palProcedureCodes);
  const filteredOnlyNonPalCodes = filterByCodeMatch(filteredSemanticProcedureCodes, nonPalProcedureCodes);

  // Helper function to filter codes with empty selectedDetails and an attributeType
  const filterWithEmptySelectedDetails = (procedureCodes: ProcedureCode[]) =>
    procedureCodes.filter((pxCode) => pxCode.attributeType && !pxCode.selectedDetails?.length);

  // Filtering PAL and Non-PAL codes with empty selected details
  const filteredPalCodesWithEmptyDetails = filterWithEmptySelectedDetails(filteredOnlyPalCodes);
  const filteredNonPalCodesWithEmptyDetails = filterWithEmptySelectedDetails(filteredOnlyNonPalCodes);

  // Determining which procedure codes can continue
  const canContinueSemanticProcedureCodes = priorAuthRequirements.userSelectedNonPalCode
    ? [...filteredPalCodesWithEmptyDetails, ...filteredNonPalCodesWithEmptyDetails]
    : filteredOnlyPalCodes;

  // Final filtered procedure codes with attributeType and empty selectedDetails
  const filteredProcedureCodes = filterWithEmptySelectedDetails(canContinueSemanticProcedureCodes);

  // Check if there are required procedures with selected details
  const hasProceduresWithSelectedDetail =
    !window.location.href.includes("fax_auth_builder") &&
    formFieldConfigurations?.selectedDetails?.fieldSpec === "REQUIRED" &&
    pxCodeAttributeConfiguration?.showPxCodeAttributeField
      ? filteredProcedureCodes.length === 0
      : true;

  const VALIDATION_MAP: Record<ServiceRequestFieldName, boolean> = {
    selectedDetails: fieldIsValid({ fieldSpec: "REQUIRED" }, hasProceduresWithSelectedDetail),
    expeditedTatUpdateTimestamp: true,
    placeOfService: true,
    orderingProvider: true,
    orderingProviderTIN: true,
    orderingProviderNPI: true,
    orderingProviderAddress: true,
    performingProvider: true,
    performingProviderNPI: true,
    performingProviderTIN: true,
    performingProviderAddress: true,
    facility: true,
    facilityNPI: true,
    facilityTIN: true,
    facilityAddress: true,
    facilityOutOfNetworkExceptionReason: true,
    facilityOutOfNetworkExceptionComment: true,
    performingProviderOutOfNetworkExceptionComment: true,
    performingProviderOutOfNetworkExceptionReason: true,
    orderingProviderOutOfNetworkExceptionReason: true,
    orderingProviderOutOfNetworkExceptionComment: true,
    careParticipantOutOfNetworkExceptionReason: true,
    careParticipantOutOfNetworkExceptionComment: true,
    singleClinicalService: true,
    encounterType: true,
    primaryDiagnosis: true,
    secondaryDiagnoses: true,
    performingProviderPracticeSelectedTIN: true,
    performingProviderPracticeSelectedAddress: true,
    performingProviderPractice: true,
    performingProviderPracticeSelectedNPI: true,
    procedureCodes:
      allowedPxCodePerPayer && allowedPxCodePerPayer > 0
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasAllowedProcedureCodePerPayer)
        : true,
    recurring: true,
    startEndDate: fieldIsValid(
      formContent.isInpatient ? { fieldSpec: "OMIT" } : formFieldConfigurations.startEndDate,
      hasStartDate && hasEndDateIfApplicable,
      startDateExceedsMinStartDate &&
        endDateExceedsStartDate &&
        hasValidStartDate &&
        hasValidEndDate &&
        endDateCoverage?.inRange
    ),
    units: fieldIsValid(formFieldConfigurations?.units, hasUnits && allPxsHaveUnits),
    urgency: true,
    behavioralHealthReviewType: true,
    behavioralHealthAdmissionType: true,
    admissionDischargeDate: true,
    faxInputField: true,
    procedureCodeWithUnits: fieldIsValid(
      inPatientValidatePx ? { fieldSpec: "OMIT" } : formFieldConfigurations.procedureCodes,
      allPxsHaveUnits && hasPxs
    ),
    patientStayDateRanges: validatePatientStayDates(
      facilityBasedFeatureEnabled,
      formContent?.isInpatient,
      isPatientStayDatesValid,
      includePatientStayDatesOnPlannedAdmission,
      formContent?.patientStatus
    ),
    authCategory: true,
    authSubCategory: true,
    prescribedDrug: true,
    outOfNetworkCheck: true,
    facilityOutOfNetworkStatusDisplay: true,
    performingProviderOutOfNetworkStatusDisplay: true,
    orderingProviderOutOfNetworkStatusDisplay: true,
    careParticipantOutOfNetworkStatusDisplay: true,
    outOfNetworkOrderingProvider: true,
    userDeclaredOONException: true,
    admissionDate:
      facilityBasedFeatureEnabled && formContent?.isInpatient && formContent?.patientStatus !== "NOT_YET_ADMITTED"
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasAdmissionDate)
        : fieldIsValid(formFieldConfigurations.admissionDate, hasAdmissionDate),
    admissionTime:
      facilityBasedFeatureEnabled && formContent?.isInpatient && formContent?.patientStatus !== "NOT_YET_ADMITTED"
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasAdmissionTime)
        : fieldIsValid(formFieldConfigurations.admissionDate, hasAdmissionTime),
    dischargeDate:
      facilityBasedFeatureEnabled && formContent?.isInpatient && formContent?.patientStatus === "DISCHARGED"
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasDischargeDate)
        : fieldIsValid(formFieldConfigurations.dischargeDate, hasDischargeDate),
    dischargeTime:
      facilityBasedFeatureEnabled && formContent?.isInpatient && formContent?.patientStatus === "DISCHARGED"
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasDischargeTime)
        : fieldIsValid(formFieldConfigurations.dischargeTime, hasDischargeTime),
    dischargedTo:
      facilityBasedFeatureEnabled && formContent?.isInpatient && formContent?.patientStatus === "DISCHARGED"
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasDischargedTo)
        : fieldIsValid(formFieldConfigurations.dischargedTo, hasDischargedTo),
    expectedAdmissionDate: facilityBasedFeatureEnabled
      ? formContent?.isInpatient && formContent?.patientStatus === "NOT_YET_ADMITTED"
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasExpectedAdmissionDate)
        : fieldIsValid(formFieldConfigurations.expectedAdmissionDate, hasExpectedAdmissionDate)
      : formContent?.isInpatient
      ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasExpectedAdmissionDateForServiceBasedSR)
      : fieldIsValid(formFieldConfigurations.expectedAdmissionDate, hasExpectedAdmissionDateForServiceBasedSR),
    expectedDischargeDate:
      !facilityBasedFeatureEnabled && formContent?.isInpatient
        ? fieldIsValid({ fieldSpec: "REQUIRED" }, hasExpectedDischargeDate)
        : fieldIsValid(formFieldConfigurations.expectedDischargeDate, hasExpectedDischargeDate),
    additionalCareParticipants: true,
    nonPalCheckbox: true,
    blockUserIfExceedsRecommendedEndDate: fieldIsValid(
      formFieldConfigurations.blockUserIfExceedsRecommendedEndDate,
      !showExceededDurationLimitMessageErrorState
    ),
    blockUserIfLessThanRecommendedEndDate: fieldIsValid(
      formFieldConfigurations.blockUserIfLessThanRecommendedEndDate,
      !showBelowDurationLimitMessageErrorState
    ),
    userSelectedOONException: true,
    performingProviderPracticeOutOfNetworkStatusDisplay: true, // TODO
    performingProviderPracticeOutOfNetworkExceptionReason: true, // TODO
    performingProviderPracticeOutOfNetworkExceptionComment: true, // TODO
    admissionSource: true,
    patientStatus: true,
  };

  const validateFields = () => {
    const formConfig = formFieldConfigurations;
    if (formConfig) {
      const formConfigKeys = Object.keys(formConfig) as ServiceRequestFieldName[];
      for (const key of formConfigKeys) {
        if (!VALIDATION_MAP[key]) {
          return false;
        }
      }
      return true;
    }
    //should never happen, but if it does we should block the user from trying to submit
    return false;
  };

  const isValid = validateFields();
  const handleFormValidation = useCallback(
    (isFormValid: boolean) => {
      setCanBeSubmitted(isFormValid);
    },
    [setCanBeSubmitted]
  );

  useEffect(() => {
    handleFormValidation(isValid);
  }, [isValid, handleFormValidation]);

  const [showNonCohereFullList, setShowNonCohereFullList] = useState(false);
  const classes = useStyles({ isRotated: showNonCohereFullList });
  const { dispatchRedirectRuleCheck, dispatchUrgencyRuleCheck } = useServiceRequestFormRedirectDispatch({
    workflowId,
    formContent,
    clinicalService: formContent.clinicalServices?.[0],
    healthPlanName: patientHealthPlanName,
    patient,
  });

  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  useEffect(() => {
    setFormContent((prev) => ({
      ...prev,
      userSelectedNonPalCode: priorAuthRequirements?.userSelectedNonPalCode || false,
      nonPalReason: priorAuthRequirements?.nonPalReason || "",
    }));
  }, [priorAuthRequirements?.nonPalReason, priorAuthRequirements?.userSelectedNonPalCode, setFormContent]);

  const returnToUrl = useGetReturnToUrl();
  const continueGeneralAuthSubmissionFlow = useCallback(() => {
    if (patient) {
      setServiceRequestFormContents((prev) => [
        formContentFromAuthBuilderContext({
          priorAuthRequirements,
          patient,
          procedureCodes: ProcedureCodesFromPalAndNonPalCodes(
            prev[0].procedureCodes,
            palProcedureCodes,
            nonPalProcedureCodes
          ),
          previousFormContent: prev[0],
          facilityBasedFeatureEnabled,
          clinicalServices: prev[0].clinicalServices,
          useDefaultDurationAsMinimumEndDate:
            formFieldConfigurations.blockUserIfLessThanRecommendedEndDate.fieldSpec === "REQUIRED",
        }),
      ]);
    }
  }, [
    facilityBasedFeatureEnabled,
    formFieldConfigurations.blockUserIfLessThanRecommendedEndDate.fieldSpec,
    nonPalProcedureCodes,
    palProcedureCodes,
    patient,
    priorAuthRequirements,
    setServiceRequestFormContents,
  ]);

  const ProcedureCodesFromPalAndNonPalCodes = (
    existingProcedureCodes: ProcedureCode[],
    palProcedureCodes: ProcedureCode[],
    nonPalProcedureCodes: ProcedureCode[]
  ): ProcedureCode[] => {
    const allPxCodes = existingProcedureCodes.filter((pxCode) => {
      return !(
        palProcedureCodes.find((palProcedure) => palProcedure.code === pxCode.code) ||
        nonPalProcedureCodes.find((nonPalProcedure) => nonPalProcedure.code === pxCode.code)
      );
    });

    allPxCodes.push(...palProcedureCodes);
    allPxCodes.push(...nonPalProcedureCodes);

    return allPxCodes;
  };

  const confirmNonPalAddition = useCallback(() => {
    setUserSelectedNonPalCode?.(true);
    setOpenNonPalConfirmationModal?.(false);

    nonPalProcedureCodes?.forEach((procedure) => {
      procedure.userAddedNonPal = true;
    });
    continueGeneralAuthSubmissionFlow();
  }, [
    continueGeneralAuthSubmissionFlow,
    nonPalProcedureCodes,
    setOpenNonPalConfirmationModal,
    setUserSelectedNonPalCode,
  ]);

  const nonCohereServiceCard = () => {
    return (
      <CohereCard noBoxShadow={isFaxAuthBuilderFlow} darkBorder={isFaxAuthBuilderFlow}>
        <Grid item xs={12} className={classes.relative}>
          <AlertContainer>
            <Button
              className={classes.carrotIcon}
              onClick={() => {
                setShowNonCohereFullList(!showNonCohereFullList);
              }}
            >
              <ExpandMoreIcon className={classes.expandIcon} />
            </Button>
            <Alert
              severity="warning"
              message="Requires submission through another organization"
              className={classes.transparentBackground}
            />
          </AlertContainer>
          {!showNonCohereFullList ? (
            nonCohereProcedureCodes.map((code) =>
              code.procedureCode.map((cpt) => (
                <div key={cpt.code} className={classes.nonCohereCodeContainer}>
                  <Chip label={cpt.code} type="warning" className={classes.nonCoherecodeItemMargin} size="small" />
                  <div className={classes.pxDescription}>{`${cpt.description}`}</div>
                </div>
              ))
            )
          ) : (
            <PriorAuthResultCard
              formContent={formContent}
              procedureCodes={[]}
              type="AUTH_REQUIRED_BY_VENDOR"
              patient={patient || undefined}
              nonCohereCodes={nonCohereProcedureCodes}
              crdExtensionCardResults={crdExtensionCardResults}
              primaryActiveCoverage={activeCoverage}
              isUsedInPACheckRedesign={true}
              isFacilityAuthCategoryEligibleForSubmission={isFacilityAuthCategoryEligibleForSubmission}
              isCrdExtensionDetailsDelegatedTo={isCrdExtensionDetailsDelegatedTo}
              isEligibleForSubmission={isEligibleForSubmission}
              handleNonPalCheckbox={handleNonPalCheckbox}
            />
          )}
          {formContent?.isInpatient && (
            <div className={classes.authCategoryContainer}>
              <Chip label="Inpatient stay" type="warning" className={classes.nonCoherecodeItemMargin} size="small" />
              <div className={classes.pxDescription}>
                {formContent?.authSubcategory
                  ? `${formContent?.authCategory?.name} - ${formContent?.authSubcategory}`
                  : formContent?.authCategory?.name}
              </div>
            </div>
          )}
        </Grid>
      </CohereCard>
    );
  };
  const { isFieldDisabled } =
    useGetServiceRequestStateConfigurationByPayerAndAuthStatus({
      healthPlanName: patientHealthPlanName,
      authStatus: authStatus,
      delegatedVendorName,
      requestType: isContinuation ? "CONTINUATION" : "INITIAL",
    }) || {};
  const showDateRangeSelectSection =
    palProcedureCodes.length === 0 && priorAuthRequirements.userSelectedNonPalCode && !facilityBasedFeatureEnabled;

  const [selectedNonPalReason, setSelectedNonPalReason] = useState(formContent.nonPalReason);
  const [nonPalJustification, setNonPalJustification] = useState(formContent.nonPalJustification);

  const nonPalJustificationLabel = useMemo(() => {
    return nonPalJustification
      ? "Reason (optional)"
      : "Why did you include this care? Please be as specific as possible. (optional)";
  }, [nonPalJustification]);

  const handleSubmitNonPalReason = useCallback(
    (reason: string) => {
      if (setUserSelectedNonPalCode) {
        setUserSelectedNonPalCode(true);
      }

      setSelectedNonPalReason(reason);

      setPriorAuthRequirements((currentAuthReqs) => {
        return { ...currentAuthReqs, nonPalReason: reason };
      });

      nonPalProcedureCodes?.forEach((procedure) => {
        procedure.userAddedNonPal = true;
      });

      continueGeneralAuthSubmissionFlow();
    },
    [continueGeneralAuthSubmissionFlow, nonPalProcedureCodes, setPriorAuthRequirements, setUserSelectedNonPalCode]
  );

  const handleNonPalJustificationChange = useCallback(
    (value: string) => {
      if (authStatus === "DRAFT") {
        setPriorAuthRequirements((currentAuthReqs) => {
          return { ...currentAuthReqs, nonPalJustification: value };
        });

        setFormContent((prev) => ({
          ...prev,
          nonPalJustification: value,
        }));

        setNonPalJustification(value);
      }
    },
    [authStatus, setFormContent, setPriorAuthRequirements]
  );

  const handleNonPalCheckbox = useCallback(
    (checked: boolean) => {
      setFormContent((prev) => ({
        ...prev,
        userSelectedNonPalCode: checked,
      }));

      if (!checked) {
        setSelectedNonPalReason(undefined);
        setNonPalJustification(undefined);

        setUserSelectedNonPalCode?.(false);

        setPriorAuthRequirements((currentAuthReqs) => {
          return { ...currentAuthReqs, nonPalReason: undefined, nonPalJustification: undefined };
        });

        nonPalProcedureCodes?.forEach((procedure) => {
          procedure.userAddedNonPal = true;
        });
        handleNonPalJustificationChange("");
      } else {
        setOpenNonPalConfirmationModal?.(true);
      }
    },
    [
      handleNonPalJustificationChange,
      nonPalProcedureCodes,
      setFormContent,
      setOpenNonPalConfirmationModal,
      setPriorAuthRequirements,
      setUserSelectedNonPalCode,
    ]
  );

  const handleCloseModal = useCallback(() => {
    setCancelModalOpen(false);
  }, [setCancelModalOpen]);

  const handleCloseNonPalModal = useCallback(() => {
    setOpenNonPalConfirmationModal?.(false);
  }, [setOpenNonPalConfirmationModal]);

  const handleDismissNonPalWarning = useCallback(() => {
    setOpenNonPalConfirmationModal?.(false);
    setUserSelectedNonPalCode?.(false);
  }, [setOpenNonPalConfirmationModal, setUserSelectedNonPalCode]);

  const nonPalServiceCard = () => {
    return (
      <CohereCard className={classes.relative} darkBorder={isFaxAuthBuilderFlow} noBoxShadow={isFaxAuthBuilderFlow}>
        <Grid item xs={12}>
          <PriorAuthResultCard
            formContent={formContent}
            procedureCodes={nonPalProcedureCodes}
            type="AUTH_NOT_REQUIRED"
            patient={patient || undefined}
            primaryActiveCoverage={activeCoverage}
            userSelectedNonPalCode={priorAuthRequirements.userSelectedNonPalCode || false}
            nonPalSubmissionIsEnabled={formFieldConfigurations.nonPalCheckbox.fieldSpec !== "OMIT"}
            setOpenNonPalConfirmationModal={setOpenNonPalConfirmationModal}
            setUserSelectedNonPalCode={setUserSelectedNonPalCode}
            isUsedInPACheckRedesign={true}
            isFacilityAuthCategoryEligibleForSubmission={isFacilityAuthCategoryEligibleForSubmission}
            isCrdExtensionDetailsDelegatedTo={isCrdExtensionDetailsDelegatedTo}
            isEligibleForSubmission={isEligibleForSubmission}
            handleNonPalCheckbox={handleNonPalCheckbox}
          >
            <div className={classes.alertContainer}>
              <Alert
                severity="draft"
                icon={<ErrorIcon className={classes.clearMarginRight} />}
                message="Doesn’t require submission in most cases"
                className={classes.alertIcon}
              />
            </div>
          </PriorAuthResultCard>
          {showNonPalJustificationField && (
            <TextField
              className={classes.nonPalJustificationField}
              label={nonPalJustificationLabel}
              required={false}
              value={nonPalJustification}
              onChangeValue={handleNonPalJustificationChange}
              disabled={authStatus !== "DRAFT"}
              data-testid="nonPalJustificationField"
            ></TextField>
          )}
        </Grid>
        <InformativeModal
          open={cancelModalOpen}
          onClose={handleCloseModal}
          icon={<HelpOutlineIcon className={classes.helpOutlineIcon} />}
          headerText="Are you sure you want to cancel this authorization?"
          subHeaderText="Your progress will not be saved."
          primaryButtonText="Yes, cancel"
          primaryButtonRoute={returnToUrl}
          tertiaryButtonText="Continue with authorization"
          tertiaryButtonAction={handleCloseModal}
        />

        {nonPalRequiredReason && nonPalAvailableReasons && !isFaxAuthBuilderFlow ? (
          <NonPalReasonModal
            open={!!openNonPalConfirmationModal}
            setOpenNonPalConfirmationModal={setOpenNonPalConfirmationModal}
            showLearnMoreButton={showLearnMoreButton}
            availableReasons={nonPalAvailableReasons}
            selectedNonPalReason={selectedNonPalReason}
            setSelectedNonPalReason={setSelectedNonPalReason}
            handleSubmitNonPalReason={handleSubmitNonPalReason}
            onClose={handleCloseNonPalModal}
          />
        ) : (
          <InformativeModal
            open={openNonPalConfirmationModal || false}
            onClose={handleCloseNonPalModal}
            headerText={"This care doesn't require submission in most cases"}
            subHeaderElement={
              <div className={classes.subHeader}>
                Including it is only necessary if a review is needed to ensure coverage (e.g., an organizational
                determination or a program exception). Otherwise, please continue without it.
                <InlineButton
                  startIcon={<OpenInNew />}
                  onClick={() => {
                    window.open("https://coherehealth.zendesk.com/hc/en-us/articles/14198266768279");
                  }}
                  className={classes.learnMoreButton}
                >
                  Learn more
                </InlineButton>
              </div>
            }
            primaryButtonText={`Continue without it`}
            primaryButtonDisabled={nonPalProcedureCodes.length < 1}
            tertiaryButtonText={`Include it (request will pend for review)`}
            primaryButtonAction={handleDismissNonPalWarning}
            tertiaryButtonAction={confirmNonPalAddition}
            icon={<Error className={classes.errorIcon} />}
            iconSize="48px"
          />
        )}

        {showDateRangeSelectSection && (
          <DateRangeSelectContainer>
            <DateRangeSelect
              formConfiguration={formFieldConfigurations}
              formContent={formContent}
              setFormContent={setFormContent}
              patient={patient}
              attemptedSubmit={attemptedSubmit}
              showEndDate={showEndDate}
              facilityBasedFeatureEnabled={facilityBasedFeatureEnabled}
              hasValidStartDate={hasValidStartDate}
            />
          </DateRangeSelectContainer>
        )}
        {priorAuthRequirements.userSelectedNonPalCode && (
          <>
            <NonPalProcedureCodeTableWrapper
              priorAuthRequirements={priorAuthRequirements}
              setPriorAuthRequirements={setPriorAuthRequirements}
              setNonPalProcedureCodes={setNonPalProcedureCodes}
              attemptedSubmit={attemptedSubmit}
              formContent={formContent}
              setFormContent={setFormContent}
              nonPalProcedureCodes={nonPalProcedureCodes}
              showPxBasedAttributedField={showPxBasedAttributedField}
              showPxAttributeValidation={formFieldConfigurations?.selectedDetails?.fieldSpec === "REQUIRED"}
            />
            {(formContent.isInpatient || formContent?.startDate >= today()) && palProcedureCodes.length === 0 && (
              <>
                <Grid item xs={12}>
                  <Divider className={classes.dividerMargin} />
                </Grid>
                <SRFormConfigFieldWrapper {...formFieldConfigurations.urgency}>
                  <Grid item xs={8}>
                    <div className={classes.expediteContainer}>
                      {authStatus === "DRAFT" && (
                        <RequestExpedited
                          isExpedited={formContent?.isExpedited}
                          setIsExpedited={(isExpedited: boolean, reason: string) => {
                            dispatchUrgencyRuleCheck(isExpedited);
                            setFormContent((current) => ({
                              ...current,
                              isExpedited: isExpedited,
                              expeditedReason: reason,
                            }));
                          }}
                          authStatus={authStatus}
                          startDate={formContent?.startDate}
                          patientId={patient?.id}
                          clinicalServiceId={formContent?.clinicalService?.id}
                          patientHealthPlanName={patientHealthPlanName || undefined}
                          disabled={isFieldDisabled("urgency")}
                        />
                      )}
                    </div>
                  </Grid>
                </SRFormConfigFieldWrapper>
              </>
            )}
          </>
        )}
      </CohereCard>
    );
  };

  const containerClasses = useMuiContainerStyles();
  const isFacilityBasedAuth = formContent.isInpatient && facilityBasedFeatureEnabled;
  const hasPalProcedureCodes = palProcedureCodes.length > 0;
  // Checks if any CRD procedure code results indicate delegation to a vendor other than Cohere
  // and if authorization is required for that procedure code.
  const isCrdCodeResultsDelegated = crdProcedureCodeResults?.some(
    (result) =>
      (result.authSubmissionVendor !== "Cohere" || result.delegatedVendor !== "Cohere") && result.isAuthRequired
  );

  // Checks if the CRD extension card results indicate delegation to a vendor other than Cohere
  // for either the delegatedVendor or authSubmissionVendor and if authorization is required.
  const isCrdExtensionDetailsDelegatedTo = !!(
    crdExtensionCardResults &&
    crdExtensionCardResults?.authSubmissionVendor &&
    crdExtensionCardResults?.authSubmissionVendor !== "Cohere" &&
    crdExtensionCardResults?.isAuthRequired
  );

  // Determines if the facility-based authorization category qualifies for submission
  // by checking if the request is facility-based and the CRD extension does not require authorization.
  const isFacilityAuthCategoryEligibleForSubmission =
    isFacilityBasedAuth && crdExtensionCardResults && !crdExtensionCardResults?.isAuthRequired;

  // Checks if the facility authorization category is eligible for submission
  // and ensures that the user has not selected a non-PAL (Prior Authorization List) code.
  const isEligibleForSubmission =
    isFacilityAuthCategoryEligibleForSubmission &&
    // Ensure no user-selected non-PAL code exists in prior authorization requirements
    !priorAuthRequirements?.userSelectedNonPalCode;

  const isDelegatedVendor =
    isCrdCodeResultsDelegated ||
    (isFacilityAuthCategoryEligibleForSubmission && !priorAuthRequirements?.userSelectedNonPalCode) ||
    isCrdExtensionDetailsDelegatedTo;
  const showAuthRequiredCard = (isFacilityBasedAuth && !isDelegatedVendor) || hasPalProcedureCodes;

  const showNonPalJustificationField =
    nonPalRequiredReason &&
    nonPalAvailableReasons &&
    !isFaxAuthBuilderFlow &&
    priorAuthRequirements.userSelectedNonPalCode;

  const isAuthorizedForRequestorForm = useAuthorized("REQUESTOR_FORM");
  return (
    <Container classes={containerClasses} maxWidth={"lg"}>
      <Box paddingTop={isFaxAuthBuilderFlow ? 3 : 5}>
        {isAuthorizedForRequestorForm && showRequestorCard && requestorProps && <RequestorCard {...requestorProps} />}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PriorAuthCheckBanner
              semanticProcedureCodes={formContent.procedureCodes}
              crdProcedureCodeResults={crdProcedureCodeResults}
            />
            {crdExtensionCardResults?.palCheckMessage && crdProcedureCodeResults?.length === 0 && (
              <FullPageBanner bannerIcon={<PurpleClipboard />} backgroundColor="#EAE9F4" padding={0}>
                <H2 className={classes.chipTitle}>Information about your requested care</H2>
                <div className={classes.messageAndChipContainer}>
                  <Chip label="Inpatient stay" type="warning" size="small" />
                  <H6 className={classes.chipMessage}>
                    <Sanitized
                      __html={crdExtensionCardResults?.palCheckMessage
                        ?.replaceAll("<p", "<div")
                        ?.replaceAll("</p>", "</div>")}
                    />
                  </H6>
                </div>
              </FullPageBanner>
            )}
            {showAuthRequiredCard && (
              <CohereCard noBoxShadow={isFaxAuthBuilderFlow} darkBorder={isFaxAuthBuilderFlow}>
                {patientHealthPlanName !== "Highmark" && (
                  <>
                    {isFacilityAuthCategoryEligibleForSubmission &&
                    priorAuthRequirements?.userSelectedNonPalCode &&
                    formContent?.procedureCodes?.length === 0 ? (
                      <>
                        <PriorAuthResultCard
                          procedureCodes={nonPalProcedureCodes}
                          type="AUTH_NOT_REQUIRED"
                          patient={patient || undefined}
                          primaryActiveCoverage={activeCoverage}
                          userSelectedNonPalCode={priorAuthRequirements.userSelectedNonPalCode || false}
                          nonPalSubmissionIsEnabled={true}
                          setOpenNonPalConfirmationModal={setOpenNonPalConfirmationModal}
                          setUserSelectedNonPalCode={setUserSelectedNonPalCode}
                          isUsedInPACheckRedesign={true}
                          formContent={formContent}
                          isFacilityAuthCategoryEligibleForSubmission={isFacilityAuthCategoryEligibleForSubmission}
                          isCrdExtensionDetailsDelegatedTo={isCrdExtensionDetailsDelegatedTo}
                          isEligibleForSubmission={isEligibleForSubmission}
                          handleNonPalCheckbox={handleNonPalCheckbox}
                        >
                          <div className={classes.alertContainer}>
                            <Alert
                              severity="draft"
                              icon={<ErrorIcon className={classes.clearMarginRight} />}
                              message="Doesn’t require submission in most cases"
                              className={classes.alertIcon}
                            />
                          </div>
                        </PriorAuthResultCard>
                        {showNonPalJustificationField && (
                          <TextField
                            className={classes.nonPalJustificationField}
                            label={nonPalJustificationLabel}
                            required={false}
                            value={nonPalJustification}
                            onChangeValue={handleNonPalJustificationChange}
                            disabled={authStatus !== "DRAFT"}
                          ></TextField>
                        )}
                      </>
                    ) : (
                      <AlertContainer>
                        <Alert message="Requires submission through Cohere" className={classes.transparentBackground} />
                      </AlertContainer>
                    )}
                  </>
                )}
                {facilityBasedFeatureEnabled && (
                  <PatientStayContainer>
                    <PatientStayCard
                      formContent={formContent}
                      setFormContent={setFormContent}
                      healthPlanName={patientHealthPlanName}
                      attemptedSubmit={attemptedSubmit}
                      timePattern={timePattern}
                      startDateCoverage={startDateCoverage}
                      formConfiguration={formFieldConfigurations}
                      authStatus={authStatus}
                      patient={patient}
                      dispatchUrgencyRuleCheck={dispatchUrgencyRuleCheck}
                      hideExpeditedRequestCheckbox={hideExpeditedRequestCheckbox}
                      disablePatientStatusChange={true}
                      shouldNotWaitForAuth={true}
                      encounterType={priorAuthRequirements?.encounterType}
                      delegatedVendorName={delegatedVendorName}
                      isContinuation={isContinuation}
                      isExpectedAdmissionDateEdited={isExpectedAdmissionDateEdited}
                      setExpectedAdmissionDateEdited={setExpectedAdmissionDateEdited}
                    />
                  </PatientStayContainer>
                )}
                {hasPalProcedureCodes && (
                  <ServicesAndProceduresCard
                    formConfiguration={formFieldConfigurations}
                    formContent={formContent}
                    setFormContent={setFormContent}
                    attemptedSubmit={attemptedSubmit}
                    patient={patient}
                    authStatus={authStatus}
                    workflowId={workflowId}
                    showEndDate={showEndDate}
                    dispatchRedirectRuleCheck={dispatchRedirectRuleCheck}
                    dispatchUrgencyRuleCheck={dispatchUrgencyRuleCheck}
                    palProcedureCodes={palProcedureCodes}
                    setPalProcedureCodes={setPalProcedureCodes}
                    nonPalProcedureCodes={nonPalProcedureCodes}
                    setNonPalProcedureCodes={setNonPalProcedureCodes}
                    nonCohereProcedureCodes={nonCohereProcedureCodes}
                    setNonCohereProcedureCodes={setNonCohereProcedureCodes}
                    facilityBasedFeatureEnabled={facilityBasedFeatureEnabled}
                    isUsedInPACheckRedesign={true}
                    allowMutablePxCodesWithNonPal={allowMutablePxCodesWithNonPal}
                    hideExpeditedRequestCheckbox={hideExpeditedRequestCheckbox}
                    allowedPxCodePerPayer={allowedPxCodePerPayer}
                    showPxBasedAttributedField={showPxBasedAttributedField}
                    showPxAttributeValidation={!window.location.href.includes("fax_auth_builder")}
                  />
                )}
              </CohereCard>
            )}
          </Grid>
          {(nonCohereProcedureCodes?.length > 0 ||
            (nonCohereProcedureCodes?.length === 0 && isCrdExtensionDetailsDelegatedTo)) && (
            <Grid item xs={12}>
              {nonCohereServiceCard()}
            </Grid>
          )}

          {(nonPalProcedureCodes?.length > 0 ||
            (formContent?.procedureCodes?.length === 0 &&
              nonCohereProcedureCodes.length === 0 &&
              nonPalProcedureCodes?.length === 0 &&
              isEligibleForSubmission)) && (
            <Grid item xs={12}>
              {nonPalServiceCard()}
            </Grid>
          )}
        </Grid>
      </Box>
    </Container>
  );
}

type StyleProps = {
  isRotated: boolean;
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const AlertContainer = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(-2),
  marginLeft: theme.spacing(-2),
}));
// eslint-disable-next-line cohere-react/no-mui-styled-import
const DateRangeSelectContainer = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(1),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PatientStayContainer = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const useStyles = makeStyles((theme) => ({
  expandIcon: {
    color: theme.palette.primary.main,
    transition: theme.transitions.create("transform"),
    transform: (props: StyleProps) => (props.isRotated ? "rotate(180deg)" : "rotate(0deg)"),
    "&:hover, &:focus": {
      boxShadow: "none",
      backgroundColor: colorsLight.action.hoverBackground,
    },
  },
  carrotIcon: {
    position: "absolute",
    top: theme.spacing(1.25),
    right: theme.spacing(-3),
    "&:hover, &:focus": {
      boxShadow: "none",
      backgroundColor: "transparent",
    },
  },
  errorIcon: {
    color: theme.palette.warning.dark,
  },
  subHeader: {
    display: "flex",
    flexDirection: "column",
  },
  learnMoreButton: {
    paddingTop: "24px",
  },
  alertContainer: {
    marginTop: theme.spacing(-2),
    marginLeft: theme.spacing(-2),
    marginBottom: theme.spacing(-1),
  },
  alertIcon: { background: "transparent", paddingBottom: 0, alignItems: "center" },
  clearMarginRight: { marginRight: 0 },
  pxDescription: {
    color: "var(--Font-Primary, #212936)",
    fontFamily: "Gilroy-Regular",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "15px",
    letterSpacing: "0.15px",
    marginLeft: "8px", // Space between Chip and description
  },
  messageAndChipContainer: {
    marginBottom: 24,
    "&:last-child": {
      marginBottom: 0,
    },
  },
  nonPalJustificationField: { width: "100%", marginBottom: "16px" },
  transparentBackground: { background: "transparent" },
  nonCohereCodeContainer: { display: "flex", alignItems: "center", marginBottom: "12px" },
  nonCoherecodeItemMargin: { marginLeft: "15px" },
  relative: { position: "relative" },
  helpOutlineIcon: { color: theme.palette.warning.dark },
  authCategoryContainer: { display: "flex", alignItems: "center" },
  dividerMargin: { marginTop: theme.spacing(1.5) },
  expediteContainer: { marginTop: theme.spacing(1.5), marginBottom: theme.spacing(-2) },
  chipTitle: { marginBottom: theme.spacing(1) },
  chipMessage: { marginTop: theme.spacing(1) },
}));
