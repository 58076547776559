import { useConfiguration } from "@coherehealth/common";
import { ServiceRequestResponse } from "@coherehealth/core-platform-api";
import useGetFacilityBasedRequestConfigurationByPayer, {
  useGetContinuationConfigurationByPayer,
} from "hooks/useGetFeatureConfigurations";

export const useClinicalReviewPageConfigurations = (serviceRequest: ServiceRequestResponse | null) => {
  const facilityBasedRequestConfiguration = useConfiguration(
    "facilityBasedRequestConfiguration",
    serviceRequest?.healthPlanName,
    serviceRequest?.delegatedVendor
  );
  const { data: continuationConfiguration } = useGetContinuationConfigurationByPayer(
    serviceRequest?.healthPlanName ?? ""
  );
  const { facilityBasedFeatureEnabled } = useGetFacilityBasedRequestConfigurationByPayer({
    healthPlanName: serviceRequest?.healthPlanName,
    encounterType: serviceRequest?.encounterType,
    skipRequestTimingCheck: true,
    delegatedVendorName: serviceRequest?.delegatedVendor,
    srContent: serviceRequest,
  });

  return {
    facilityBasedRequestConfiguration,
    continuationConfiguration,
    facilityBasedFeatureEnabled,
  };
};
