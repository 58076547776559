import { Alert, AutoFillIcon, Body1, H6, RadioGroup, TextField, Tooltip, useFeature } from "@coherehealth/common";
import Grid from "@material-ui/core/Grid";
import { InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PhoneInput from "common/PhoneInput";
import { Dispatch, SetStateAction } from "react";
import { isPhoneNumberValid } from "util/phoneUtils";
import {
  AvailityInfo,
  AVAILITY_AUTO_FILLED_TOOLTIP,
  emailToDomain,
  FormSection,
  FormTitleDivider,
  StyledEndAdornmentContainer,
  UserInfoState,
  useStyles,
} from "./shared";
import OnboardingQuestionCard from "./OnboardingQuestionCard";

export interface Props {
  userInfo: UserInfoState;
  availityInfo: AvailityInfo;
  setUserInfo: Dispatch<SetStateAction<UserInfoState>>;
  canEnableAutoVerification: boolean;
  autoVerificationEnabled?: boolean;
  setAutoVerificationEnabled: Dispatch<boolean>;
  highlightInfoFormErrors: boolean;
}

export default function UserInfoForm({
  userInfo,
  availityInfo,
  setUserInfo,
  autoVerificationEnabled,
  setAutoVerificationEnabled,
  canEnableAutoVerification,
  highlightInfoFormErrors,
}: Props) {
  const setUserInfoField = (field: keyof UserInfoState) => (newVal: UserInfoState[typeof field]) => {
    setUserInfo({ ...userInfo, [field]: newVal });
  };
  const onboardingRedesign = useFeature("onboardingRedesign");
  const { toolTip, icon, formGroupDivider } = useStyles();

  /*
   * Checks if a field is auto filled from Availity
   */
  const isAutoFilled = (availityField: keyof AvailityInfo, userField: keyof UserInfoState) => {
    if (userField === "phoneNumber" || userField === "faxNumber") {
      return (
        !!availityInfo[availityField] &&
        userInfo[userField] &&
        availityInfo[availityField] === userInfo[userField]?.number
      );
    }

    return !!availityInfo[availityField] && availityInfo[availityField] === userInfo[userField];
  };

  /*
   * If auto filled from Availity return autoFilled endAdornment
   */
  const autoFilledEndAdornment = (availityField: keyof AvailityInfo, userField: keyof UserInfoState) => {
    if (isAutoFilled(availityField, userField)) {
      return {
        endAdornment: (
          <StyledEndAdornmentContainer>
            <Tooltip className={toolTip} title={AVAILITY_AUTO_FILLED_TOOLTIP} placement="top-end">
              <InputAdornment className={icon} position="end">
                <AutoFillIcon />
              </InputAdornment>
            </Tooltip>
          </StyledEndAdornmentContainer>
        ),
      };
    }
  };

  const organizationDomain = emailToDomain(userInfo.email);
  const radioGroupLabelClasses = useRadioGroupLabelStyles();
  const nameTitle = onboardingRedesign ? "Your name" : "Your name and title";

  return (
    <OnboardingQuestionCard titleText={"About you"}>
      <FormTitleDivider className={formGroupDivider} />
      <FormSection style={{ paddingBottom: !canEnableAutoVerification ? 0 : undefined }}>
        <Grid item xs={12}>
          <H6>{nameTitle}</H6>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="First name"
            value={userInfo.firstName}
            onChangeValue={setUserInfoField("firstName")}
            fullWidth
            InputProps={autoFilledEndAdornment("AvailityUserFirstName", "firstName")}
            error={highlightInfoFormErrors && !userInfo.firstName}
            helperText={highlightInfoFormErrors && !userInfo.firstName && "Required"}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Last name"
            value={userInfo.lastName}
            onChangeValue={setUserInfoField("lastName")}
            fullWidth
            InputProps={autoFilledEndAdornment("AvailityUserLastName", "lastName")}
            error={highlightInfoFormErrors && !userInfo.lastName}
            helperText={highlightInfoFormErrors && !userInfo.lastName && "Required"}
          />
        </Grid>
        {!onboardingRedesign && (
          <Grid item xs={12}>
            <TextField
              label="Your title (e.g. Pre-cert manager)"
              value={userInfo.title}
              onChangeValue={setUserInfoField("title")}
              fullWidth
              error={highlightInfoFormErrors && !userInfo.title}
              helperText={highlightInfoFormErrors && !userInfo.title && "Required"}
            />
          </Grid>
        )}
      </FormSection>
      <FormSection>
        <Grid item xs={12}>
          <H6>Your contact information</H6>
        </Grid>
        <Grid item xs={6}>
          <PhoneInput
            label="Phone number"
            value={userInfo.phoneNumber}
            onChange={setUserInfoField("phoneNumber")}
            InputProps={autoFilledEndAdornment("AvailityUserPhone", "phoneNumber")}
            error={highlightInfoFormErrors && !isPhoneNumberValid(userInfo.phoneNumber.number)}
            helperText={
              highlightInfoFormErrors &&
              !isPhoneNumberValid(userInfo.phoneNumber.number) &&
              "You must provide a valid 10-digit phone number (Ex. (123) 456-7890)"
            }
          />
        </Grid>
        <Grid item xs={6}>
          <PhoneInput
            label="Fax number (optional)"
            value={userInfo.faxNumber && userInfo.faxNumber}
            onChange={setUserInfoField("faxNumber")}
            InputProps={autoFilledEndAdornment("AvailityCustomerFax", "faxNumber")}
            hideExtensionField
          />
        </Grid>
      </FormSection>
      {canEnableAutoVerification && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <RadioGroup<"YES" | "NO" | "">
              label={
                <>
                  <H6 color="textPrimary">
                    Automatically grant access to my organization for users with an email address ending in:
                  </H6>
                  <Body1 style={{ paddingTop: "16px" }} color="textSecondary">
                    email@{organizationDomain}
                  </Body1>
                </>
              }
              labelClasses={radioGroupLabelClasses}
              options={[
                { id: "YES", label: "Yes" },
                { id: "NO", label: "No" },
              ]}
              value={autoVerificationEnabled ? "YES" : "NO"}
              onChange={(value) => setAutoVerificationEnabled(value === "YES")}
              row
            />
          </Grid>
          {autoVerificationEnabled && (
            <Grid item xs={12}>
              <Alert severity="warning" message="Important information">
                By selecting yes, you are confirming that other members from your organization using the same email
                domain (email@{organizationDomain}) can join your account. Anyone who requests an account with this
                email domain will able to join your organization.
              </Alert>
            </Grid>
          )}
        </Grid>
      )}
    </OnboardingQuestionCard>
  );
}

const useRadioGroupLabelStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(1),
  },
}));
