import { useEffect } from "react";
import { useFeature } from "@coherehealth/common";

// Defining a global interface for the Salesforce chatbot
declare global {
  interface Window {
    embeddedservice_bootstrap: any;
  }
}

const orgId = process.env.REACT_APP_SALESFORCE_PROVIDER_ORG_ID;
const domain = process.env.REACT_APP_SALESFORCE_PROVIDER_DOMAIN;
const scrt2URL = process.env.REACT_APP_SALESFORCE_PROVIDER_SCRT2_URL;
const scriptUrl = process.env.REACT_APP_SALESFORCE_PROVIDER_SCRIPT;
const chatbotEnv = process.env.REACT_APP_SALESFORCE_PROVIDER_CHATBOT_ENV;

export const useChatWidgetForPHI = (): void => {
  const isSalesForceBotEnabled = useFeature("enableSalesForceBotForPHI");

  useEffect(() => {
    let script: HTMLScriptElement | null = null;

    async function fetchFinalScriptUrl(initialUrl: string): Promise<string> {
      try {
        const response = await fetch(initialUrl, { method: "GET", redirect: "follow" });
        return response.url; // This will give the final URL after following redirects
      } catch (err) {
        return initialUrl; // Fallback to the original URL if resolution fails
      }
    }

    function initEmbeddedMessaging() {
      try {
        window.embeddedservice_bootstrap.settings.language = "en_US";
        window.embeddedservice_bootstrap.init(orgId, chatbotEnv, domain, { scrt2URL: scrt2URL });

        const interval = setInterval(() => {
          const iframe = document.querySelector('iframe[title="Web_Chat"]') as HTMLIFrameElement | null;
          if (iframe) {
            iframe.style.position = "relative";
            iframe.style.zIndex = "10000";
            iframe.style.width = "100%";
            iframe.style.height = "100%";
            iframe.style.background = "#EA1D63";
            clearInterval(interval);
          }
        }, 100);
      } catch (err) {}
    }

    if (isSalesForceBotEnabled) {
      const initialScriptUrl = scriptUrl || "";

      if (orgId && domain && scrt2URL && scriptUrl && chatbotEnv) {
        fetchFinalScriptUrl(initialScriptUrl).then((finalScriptUrl) => {
          script = document.createElement("script");
          script.src = finalScriptUrl;
          script.type = "text/javascript";
          script.onload = () => {
            // Salesforce Embedded Messaging script loaded successfully
            initEmbeddedMessaging();
          };
          document.body.appendChild(script);
        });
      }
    }

    return () => {
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [isSalesForceBotEnabled]);
};
