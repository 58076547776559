import React from "react";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { Body1, colorsLight, Sanitized } from "@coherehealth/common";
import { NurseReview } from "@coherehealth/core-platform-api";
import { ReviewOutcomes } from "components/ClinicalReview/reviewUtils/utils";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core";
import { Label } from "components/ServiceRequest/ReadonlyDetail/FormElements";

interface Props {
  nurseReview: NurseReview;
  hideDecisionReasoning?: boolean;
}

interface Nudge {
  nudgeType?: string;
  nudgeResultedInChange?: boolean;
  nudgeReachedCorrectContact?: boolean;
  nudgeDescription?: string;
}

export default function NurseReviewReadOnly({ nurseReview, hideDecisionReasoning }: Props) {
  const nudgeTypes = nurseReview?.nurseReviewNudges
    ?.map((nudge: Nudge) => NudgeTypes[nudge.nudgeType || ""])
    ?.join(", ");
  const urlInfo: URLInfo = getURLInfo(nurseReview?.guidelineLink || "");

  return (
    <Grid container spacing={2}>
      {nurseReview.criteriaDecision || nurseReview?.decisionReasoning ? (
        <>
          {nurseReview.criteriaDecision && (
            <Grid item xs={12}>
              <Label>Did the request meet guideline criteria?</Label>
              <Body1>{CriteriaDecisionOptions[nurseReview?.criteriaDecision || ""]}</Body1>
            </Grid>
          )}
          {!hideDecisionReasoning && (
            <Grid item xs={12}>
              <Label>Reviewer notes</Label>
              <Body1 style={{ marginTop: -16, marginBottom: -16 }} component="div">
                <Sanitized __html={nurseReview?.decisionReasoning || "<p>- -</p>"} />
              </Body1>
            </Grid>
          )}
        </>
      ) : (
        <>
          <Grid item xs={4}>
            <Label>Link to guideline</Label>
            <Body1 style={{ color: colorsLight.brand.blue }}>
              {urlInfo.href === "" ? (
                urlInfo.hostname
              ) : (
                <GuideLink href={urlInfo.href} rel="noopener" target="_blank">
                  {urlInfo.hostname}
                </GuideLink>
              )}
            </Body1>
          </Grid>
          <Grid item xs={6}>
            <Label>Guidelines used</Label>
            <Body1>{nurseReview?.guidelineTypes?.join(", ")}</Body1>
          </Grid>
          <Grid item xs={12}>
            <Label>Clinical criteria used</Label>
            <Body1 component="div">
              <Sanitized __html={nurseReview?.criteriaUsedDescription || "- -"} />
            </Body1>
          </Grid>
          <Grid item xs={12}>
            <Label>Labs</Label>
            <Body1>{nurseReview?.labsDescription}</Body1>
          </Grid>
          <Grid item xs={12}>
            <Label>Current medications</Label>
            <Body1>{nurseReview?.currentMedicationsDescription}</Body1>
          </Grid>
          <Grid item xs={12}>
            <Label>Imaging results</Label>
            <Body1>{nurseReview?.imagingResultsDescription}</Body1>
          </Grid>
          <Grid item xs={12}>
            <Label>Additional context about your recommendation</Label>
            <Body1 component="div">
              <Sanitized __html={nurseReview.nurseClinicalNote || "- -"} />
            </Body1>
          </Grid>
        </>
      )}
      <Grid item xs={3}>
        <Label>Nudge attempted?</Label>
        <Body1>{nurseReview.nudgeAttempted ? "Yes" : "No"}</Body1>
      </Grid>
      {nurseReview.nudgeAttempted && (
        <Grid item xs={9}>
          <Label>Type of nudge</Label>
          <Body1>{nudgeTypes}</Body1>
        </Grid>
      )}
      {nurseReview.nudgeAttempted &&
        nurseReview.nurseReviewNudges?.map((nudge: Nudge) => (
          <React.Fragment key={nudge.nudgeType}>
            <Grid item xs={3}>
              <Label>{NudgeTypes[nudge.nudgeType || ""]}</Label>
            </Grid>
            <Grid item xs={5}>
              <Label>Did the nudge result in a changed auth request?</Label>
              <Body1>{nudge.nudgeResultedInChange ? "Yes" : "No"}</Body1>
            </Grid>
            <Grid item xs={4}>
              <Label>Correct point of contact reached?</Label>
              <Body1>{nudge.nudgeReachedCorrectContact ? "Yes" : "No"}</Body1>
            </Grid>
          </React.Fragment>
        ))}
      {nurseReview.nudgeAttempted && (
        <Grid item xs={12}>
          <Label>Nudge Notes</Label>
          <Body1>{nurseReview.nudgeDescription}</Body1>
        </Grid>
      )}
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid container item xs={12} direction="row">
        <Grid item xs={6}>
          <Label>Review outcome</Label>
          <Body1>
            {nurseReview.reviewStatus === "DISCARDED" ? "Discarded" : ReviewOutcomes[nurseReview.reviewOutcome || ""]}
          </Body1>
        </Grid>
        {nurseReview.serviceStatusLabel &&
          ["RECOMMENDED_DENIAL", "RECOMMENDED_PARTIAL_APPROVAL", "DENIED"].includes(
            nurseReview.reviewOutcome || ""
          ) && (
            <Grid item xs={6}>
              <Label>Denial reason</Label>
              <Body1>{nurseReview.serviceStatusLabel}</Body1>
            </Grid>
          )}
      </Grid>
    </Grid>
  );
}

interface URLInfo {
  href: string;
  hostname: string;
}

function getURLInfo(urlString: string): URLInfo {
  try {
    const isURL = new URL(urlString);
    return {
      href: isURL.href,
      hostname: isURL.hostname,
    };
  } catch (error) {
    return {
      href: "",
      hostname: urlString,
    };
  }
}

const NudgeTypes: Record<string, string> = {
  UNITS: "Units",
  FREQUENCY: "Frequency",
  SITE_OF_SERVICE: "Site of Service",
  DURATION: "Duration",
  SERVICES_ON_REQUEST: "Services on Request",
  TIMING: "Sequence / Timing",
  ADD_POST_ACUTE: "Adding Post Acute Services",
  ADD_PREHAB: "Adding Prehab Services",
};

const CriteriaDecisionOptions: Record<string, string> = {
  CRITERIA_MET: "the request met guideline criteria for approval because…",
  CRITERIA_NOT_MET: "the request did not meet guideline criteria for approval because…",
  AUTO_APPROVED: "the request meets criteria for auto-approval",
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const GuideLink = styled("a")(({ theme }) => ({
  textDecoration: "none",
  color: colorsLight.brand.blue,
}));
