import { HighlightStateContext, useConfiguration, UserClickInfoClinicalReviewPage } from "@coherehealth/common";
import {
  CopyPasteInfo,
  OutOfNetworkReview,
  ReviewType,
  SearchInfo,
  ServiceRequestResponse,
} from "@coherehealth/core-platform-api";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { isMedicalNecessityReview } from "./utils";
import { useReviewsDispatch } from "./useReviews";
import useOutreachAttempt, { OutreachAttemptProps } from "components/ServiceRequest/ReviewSection/useOutreachAttempt";
import { useOutOfNetworkReviewManager, OutOfNetworkReviewManagerProps } from "./useOutOfNetworkReviewManager";
import useFetchReviewOutcomes from "components/ServiceRequest/ReviewSection/common/hooks/useFetchReviewOutcomes";
import { ReviewOutcomeOption } from "components/ServiceRequest/ReviewSection/util/QueueManagementReviewUtil";

interface useEmbeddedOonReviewProps {
  serviceRequest: ServiceRequestResponse;
  currentReviewType: ReviewType["reviewType"];
  currentReviewId: string;
  existingReviews: ReviewType[] | null;
  isQmUserAudited: boolean;
  userClickInfoTracking?: UserClickInfoClinicalReviewPage;
  searchInfos?: SearchInfo[];
  pastedAttachmentTexts?: CopyPasteInfo[];
  userId?: string;
  setUserClickInfoTracking?: Dispatch<SetStateAction<UserClickInfoClinicalReviewPage>>;
  setSearchInfos?: Dispatch<React.SetStateAction<SearchInfo[]>>;
}

interface useEmbeddedOonReviewReturnProps extends OutOfNetworkReviewManagerProps {
  canShowOonTab: boolean;
  needsOonReview: boolean;
  embeddedOonReviewId: string | undefined;
  handleNeedsOonUpdate: (needsOonReviewUpdate: boolean) => void;
  outreachAttemptProps: OutreachAttemptProps;
  oonAllowedReviewOutcomes: ReviewOutcomeOption[];
}

const useEmbeddedOonReview = ({
  serviceRequest,
  currentReviewType,
  currentReviewId,
  existingReviews,
  isQmUserAudited,
  userClickInfoTracking,
  searchInfos,
  pastedAttachmentTexts,
  userId,
  setUserClickInfoTracking,
  setSearchInfos,
}: useEmbeddedOonReviewProps): useEmbeddedOonReviewReturnProps => {
  const oonReviewConfig = useConfiguration(
    "outOfNetworkReviewConfiguration",
    serviceRequest.healthPlanName,
    serviceRequest.delegatedVendor
  );
  const oonReviewEnabled = oonReviewConfig?.enabled;
  const isMedNecReview = isMedicalNecessityReview(currentReviewType);
  const oonReviewEnabledForReviewType = oonReviewConfig?.oonWithMedNecReviews?.includes(currentReviewType);
  const canShowOonTab = Boolean(
    serviceRequest.wasOonExceptionRequested && isMedNecReview && oonReviewEnabled && oonReviewEnabledForReviewType
  );

  const [needsOonReview, setNeedsOonReview] = useState(canShowOonTab);
  const [embeddedOonReviewId, setEmbeddedOonReviewId] = useState<string>();
  const reviewsDispatch = useReviewsDispatch();

  const outreachAttemptProps = useOutreachAttempt({ serviceRequest, reviewId: embeddedOonReviewId });
  const { setOutreachAttempt, validateOutreachForm, hasOutreachAttempt, saveOutreachAttempt, outreachAttemptLoading } =
    outreachAttemptProps;
  const outOfNetworkReviewManagerProps = useOutOfNetworkReviewManager({
    outOfNetworkReviewId: embeddedOonReviewId || "",
    validateOutreachForm,
    hasOutreachAttempt,
    serviceRequest,
    isQmUserAudited,
    saveOutreachAttempt,
    userClickInfoTracking,
    searchInfos,
    pastedAttachmentTexts,
    userId,
    setUserClickInfoTracking,
    setSearchInfos,
    outreachAttemptLoading,
    overrideRequired: oonReviewConfig?.networkStatusOverrideRequired,
  });
  const { rightColumnTab, setRightColumnTab } = useContext(HighlightStateContext);

  useEffect(() => {
    const initEmbeddedOonReview = () => {
      if (!embeddedOonReviewId && serviceRequest.healthPlanName && existingReviews) {
        let oonReviewId: string;
        const existingDraftOONReview = existingReviews?.find(
          (review) =>
            review.reviewType === "OutOfNetworkReview" &&
            review.reviewStatus === "DRAFT" &&
            !!review.medicalNecessityReviewId
        );
        if (existingDraftOONReview) {
          oonReviewId = existingDraftOONReview.id;
        } else {
          oonReviewId = UNSAVED_OON;
          const now = new Date().toISOString();
          const oonReview: OutOfNetworkReview = {
            id: oonReviewId,
            dateCreated: now,
            lastUpdated: now,
            healthPlanName: serviceRequest.healthPlanName,
            reviewType: "OutOfNetworkReview",
            reviewStatus: "DRAFT",
            serviceRequest: { id: serviceRequest.id },
            medicalNecessityReviewId: currentReviewId,
          };
          reviewsDispatch?.({
            type: "ADD_REVIEW",
            reviewId: oonReviewId,
            payload: { review: oonReview },
          });
        }
        setEmbeddedOonReviewId(oonReviewId);
        setOutreachAttempt((prevOutreachAttempt) => ({ ...prevOutreachAttempt, reviewId: oonReviewId }));
        setNeedsOonReview(!serviceRequest.oonExceptionDecision || !!existingDraftOONReview);
        if (rightColumnTab !== "REVIEW") {
          setRightColumnTab?.("OON_REVIEW");
        }
      }
    };

    if (canShowOonTab) {
      initEmbeddedOonReview();
    }
  }, [
    canShowOonTab,
    reviewsDispatch,
    serviceRequest.healthPlanName,
    embeddedOonReviewId,
    serviceRequest.id,
    serviceRequest.oonExceptionDecision,
    setOutreachAttempt,
    setRightColumnTab,
    existingReviews,
    rightColumnTab,
    currentReviewId,
  ]);

  const { allowedReviewOutcomes: oonAllowedReviewOutcomes } = useFetchReviewOutcomes({
    serviceRequestId: serviceRequest?.id,
    reviewId: embeddedOonReviewId,
    reviewType: "OutOfNetworkReview",
  });

  const handleNeedsOonUpdate = (needsOonReviewUpdate: boolean) => {
    if (needsOonReviewUpdate) {
      setNeedsOonReview(true);
    } else {
      setNeedsOonReview(false);
    }
  };

  return {
    canShowOonTab,
    needsOonReview,
    embeddedOonReviewId,
    handleNeedsOonUpdate,
    outreachAttemptProps,
    oonAllowedReviewOutcomes,
    ...outOfNetworkReviewManagerProps,
  };
};
const UNSAVED_OON = "UNSAVED_OON";

export { useEmbeddedOonReview, UNSAVED_OON };
