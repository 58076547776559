import { useFeature } from "@coherehealth/common";
import { Attachment, ReviewType } from "@coherehealth/core-platform-api";
import { convertHtmlToRichTextEditorState } from "components/ClinicalReview/reviewUtils/automatedReviewNoteRichTextEditorUtil";
import { renderAutomatedReviewNote } from "components/ClinicalReview/reviewUtils/utils";
import { useState } from "react";

interface AutomatedReviewNoteEditorStateProps {
  currentReview?: ReviewType;
  attachments?: Attachment[];
}

const getCustomEditorState = (
  automatedReviewNoteEnabled: boolean,
  currentReview?: ReviewType,
  attachments?: Attachment[]
): any | undefined => {
  if (
    automatedReviewNoteEnabled &&
    currentReview &&
    (currentReview.reviewType === "MdReview" || currentReview.reviewType === "NurseReview") &&
    currentReview.automatedReviewNote &&
    currentReview.reviewStatus !== "COMPLETE" &&
    currentReview.reviewStatus !== "DISCARDED"
  ) {
    return convertHtmlToRichTextEditorState(
      renderAutomatedReviewNote(currentReview.automatedReviewNote || [], attachments)
    );
  }

  return;
};

/*
 * This initializes the RTE state with the automated review note and keeps the state centralized in once place
 * so you don't lose your progress when switching between tabs.
 */
export const useAutomatedReviewNoteEditorState = ({
  currentReview,
  attachments,
}: AutomatedReviewNoteEditorStateProps) => {
  const automatedReviewNoteEnabled = useFeature("automatedReviewNote");

  const [automatedReviewNoteEditorState, setAutomatedReviewNoteEditorState] = useState<any>(
    getCustomEditorState(automatedReviewNoteEnabled, currentReview, attachments)
  );

  return {
    automatedReviewNoteEditorState,
    setAutomatedReviewNoteEditorState,
  };
};
