import React, { useState, useCallback, useContext, SetStateAction, Dispatch } from "react";
import {
  AuthBuilderWorkflowStep,
  ClinicalService,
  Patient,
  useDeleteServiceRequest,
  useGetTransientServiceRequestRuleActions,
} from "@coherehealth/core-platform-api";
import {
  conveneBaseUrl,
  Body1,
  colorsLight,
  H2,
  InlineButton,
  PrimaryButton,
  Sanitized,
  parseDateFromISOString,
  formatDateToISODate,
  useFeature,
  useGeneralAuthSubmissionWorflowOn,
} from "@coherehealth/common";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import MuiErrorIcon from "@material-ui/icons/Error";
import { Link } from "react-router-dom";
import {
  ServiceRequestFormContent,
  ContinuationFormContent,
  RedirectRuleCheckFn,
} from "common/SharedServiceRequestFormComponents";
import { blankModalMessageFiller } from "util/serviceRequest";
import { activitySnapshotFromServiceRequestFormContent, useTrackUserInteraction } from "util/userActivityTracker";
import ImpressionTracking, { ImpressionTrackingProps } from "common/ImpressionTracking";
import { ActivityContext } from "@coherehealth/core-platform-api";
import {
  isRecommendChangeAction,
  isRedirectAction,
  isHardRedirectAction,
  removeRulesWhenPxCodesOnDifCarepaths,
  ValidDisplayMessageAction,
} from "util/rule";
import { useGetReturnToUrl } from "util/queryParams";
import routes from "routes";
import applyActionToServiceRequest, { GenericServiceRequest } from "util/rule/applyActionToServiceRequest";
import { useAuthorized } from "authorization";
import { FiredNudgesContext } from "components/AuthBuilder/FiredNudgesContext";
import { Button } from "@coherehealth/design-system";
import { Divider, useTheme } from "@mui/material";
import ProhibitedIcon from "../../images/ProhibitedIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(7, 3),
      borderRadius: "10px",
    },
    messageHeader: {
      paddingTop: theme.spacing(3),
      textAlign: "center",
      maxWidth: "100%",
    },
    messageDetails: {
      paddingTop: theme.spacing(2),
      color: colorsLight.black.mediumEmphasis,
      textAlign: "center",
      margin: theme.spacing(0, 4),
      maxWidth: "100%",
    },
    dialogActions: {
      padding: `${theme.spacing(4)}px 0 0`,
      justifyContent: "center",
    },
    dashboardDialogActions: {
      padding: `${theme.spacing(4)}px 0 0`,
      justifyContent: "center",
    },
    warningIcon: {
      fontSize: theme.spacing(6),
      color: colorsLight.warning.dark,
    },
    actionButton: {
      padding: `${theme.spacing(2)}px ${theme.spacing(10)}px`,
    },
  })
);

const GENERIC_NUDGE_APPLICATION_OBJECT_TYPES = [
  "procedure code",
  "urgency",
  "encounter type",
  "after details",
] as const;
const OBJECT_TYPES = [
  ...GENERIC_NUDGE_APPLICATION_OBJECT_TYPES,
  "assessment",
  "attachments",
  "ordering provider",
  "patient search",
  "service change",
] as const;
export type RedirectModalErrorObjectType = typeof OBJECT_TYPES extends ReadonlyArray<infer T> ? T : never;

interface IProps {
  open: boolean;
  onClose: (success: boolean) => void;
  redirectRuleAction?: ValidDisplayMessageAction;
  context?: ActivityContext;
  formContent?: ServiceRequestFormContent;
  setFormContent?: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  objectType: RedirectModalErrorObjectType | undefined;
  isHardStop?: boolean;
}

export default function ServiceRequestFormRedirectModal({
  open,
  onClose,
  redirectRuleAction,
  formContent,
  setFormContent,
  // TODO: we should get rid of objectType entirely. Either base logic on displayTarget or the action (whether it has functional nudge)
  objectType,
  context,
  isHardStop,
}: IProps) {
  let { title, message, ruleId, onAcceptValue } = redirectRuleAction || {};
  const redirectRuleUnitQuantity = objectType === "service change" ? onAcceptValue?.value : null;
  const classes = useStyles();
  const theme = useTheme();
  const simplifiedServiceFrequency = useFeature("simplifiedServiceFrequency");
  const { mutate: deleteServiceRequest } = useDeleteServiceRequest({});

  const returnToUrl = useGetReturnToUrl();
  // TODO: the action text should not depend on objectType, but a function of displayTarget/targetForAction
  const dashboardText =
    redirectRuleAction && isRecommendChangeAction(redirectRuleAction)
      ? "Dismiss and continue"
      : (objectType === "assessment" ||
          objectType === "attachments" ||
          objectType === "after details" ||
          objectType === "service change") &&
        redirectRuleAction &&
        isRedirectAction(redirectRuleAction)
      ? "Exit request"
      : `Cancel service request and return${returnToUrl === routes.DASHBOARD ? " to dashboard" : ""}`;

  const buttonText =
    objectType === "urgency"
      ? "Continue with standard request"
      : objectType === "assessment"
      ? "Go back and make changes"
      : objectType === "attachments"
      ? "Go back and add attachments"
      : objectType === "patient search"
      ? "Go back and make changes"
      : objectType === "after details"
      ? "Accept and continue"
      : objectType === "service change" && redirectRuleUnitQuantity
      ? `Reduce quantity to ${redirectRuleUnitQuantity} and continue`
      : redirectRuleAction && isRedirectAction(redirectRuleAction)
      ? "Remove and continue"
      : "Accept and continue";

  // RTE should be left-aligned
  if (document.getElementsByClassName("public-DraftStyleDefault-block")[0]) {
    Array.from(
      document.getElementsByClassName("public-DraftStyleDefault-block") as HTMLCollectionOf<HTMLElement>
    )[0].style.textAlign = "left";
  }

  // Currently this is a hack because an empty HTML block will be 287 characters long. Really this should never be empty because Clinical Content should be adding messages.
  if (message?.length === 287) {
    message = blankModalMessageFiller;
  }

  const trackInteraction = useTrackUserInteraction();
  const userActivityParams: ImpressionTrackingProps = {
    event: redirectRuleAction && isRedirectAction(redirectRuleAction) ? "FIELD_REDIRECT" : "FIELD_RECOMMENDED_CHANGE",
    stage:
      // TODO: base this on targetForAction
      objectType === "assessment"
        ? "CLINICAL_ASSESSMENT"
        : objectType === "attachments"
        ? "ADD_ATTACHMENTS"
        : "AUTH_CREATION",
    beforeSnapshot: !!formContent ? activitySnapshotFromServiceRequestFormContent(formContent) : undefined,
    field:
      // TODO: base this on displayTarget
      objectType === "ordering provider"
        ? "ORDERING_PROVIDER"
        : objectType === "procedure code"
        ? "PROCEDURE_CODES"
        : objectType === "encounter type"
        ? "PLACE_OF_SERVICE"
        : objectType === "assessment"
        ? "ASSESSMENT"
        : objectType === "attachments"
        ? "ATTACHMENTS"
        : undefined,
    activityContext: context,
  };

  function acceptProposedChanges() {
    let resultingInteractionUpdate = null;
    if (setFormContent && formContent) {
      if (GENERIC_NUDGE_APPLICATION_OBJECT_TYPES.some((x) => x === objectType)) {
        // TODO: this is probably not the right logic. Whether we can generically apply an action is a function of the action itself,
        // not really a function of the objectType (which is more or less just a variant control for button text)
        const originalServiceRequest = formContentToSR(formContent, simplifiedServiceFrequency);
        const newSR = applyActionToServiceRequest(originalServiceRequest, redirectRuleAction);

        if (newSR) {
          const transformedFormContent = srToFormContent(
            {
              ...originalServiceRequest,
              ...newSR,
            },
            simplifiedServiceFrequency
          );
          setFormContent({ ...formContent, ...transformedFormContent });
          resultingInteractionUpdate = { ...formContent, ...transformedFormContent };
        }
      } else if (objectType === "ordering provider") {
        setFormContent({ ...formContent, orderingProvider: null });
        resultingInteractionUpdate = { ...formContent, orderingProvider: null };
      }
    }
    if (objectType === "assessment" || objectType === "attachments") {
      trackInteraction({
        ...userActivityParams, // includes before snapshot
        interactionAccept: true,
      });
    }

    if (!!resultingInteractionUpdate) {
      trackInteraction({
        ...userActivityParams, // includes before snapshot
        interactionAccept: true,
        afterSnapshot: activitySnapshotFromServiceRequestFormContent(resultingInteractionUpdate),
      });
    }
  }

  const canViewRuleLink = useAuthorized("VIEW_RULE_ACTION_SOURCE");

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      disableEscapeKeyDown={true}
      fullWidth
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") {
          return;
        }
        trackInteraction({
          ...userActivityParams, // includes before snapshot
          interactionAccept: false,
          afterSnapshot: activitySnapshotFromServiceRequestFormContent(formContent),
        }).finally(() => onClose(false));
      }}
      aria-labelledby="sr-submission-message"
      aria-describedby={message && "sr-detail-message"}
      open={open}
    >
      <ImpressionTracking {...userActivityParams}>
        <DialogContent style={{ padding: 0 }}>
          <div style={{ textAlign: "center" }}>
            {redirectRuleAction?.onAcceptAction === "WITHDRAW" ? (
              <ProhibitedIcon />
            ) : (
              <MuiErrorIcon className={classes.warningIcon} />
            )}
          </div>
          <Grid container direction="column" alignItems="center">
            <H2 id="sr-detail-message" className={classes.messageHeader}>
              {title}
            </H2>
            <Body1 id="sr-submission-message" className={classes.messageDetails}>
              <Sanitized __html={message || ""} />
            </Body1>
            {canViewRuleLink && (
              <Grid item xs={12}>
                <a target="__blank" rel="noopener" href={`${conveneBaseUrl()}/rule/${ruleId}`}>
                  Link to rule
                </a>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        {!isHardStop && (
          <DialogActions className={classes.dialogActions}>
            <PrimaryButton
              className={classes.actionButton}
              onClick={() => {
                acceptProposedChanges();
                onClose(
                  objectType === "assessment" ||
                    objectType === "attachments" ||
                    objectType === "after details" ||
                    objectType === "service change"
                );
              }}
            >
              {buttonText}
            </PrimaryButton>
          </DialogActions>
        )}
        {redirectRuleAction?.onAcceptAction === "WITHDRAW" && <Divider style={{ margin: theme.spacing(4, 4, 0, 4) }} />}
        <DialogActions className={classes.dashboardDialogActions}>
          {redirectRuleAction?.onAcceptAction === "WITHDRAW" ? (
            <Button
              priority="primary"
              onClick={() => {
                trackInteraction({
                  ...userActivityParams,
                  interactionAccept: redirectRuleAction && isRedirectAction(redirectRuleAction),
                }).finally(() => {
                  if (redirectRuleAction?.onAcceptAction === "WITHDRAW") {
                    deleteServiceRequest(formContent?.id || redirectRuleAction.srId || "")
                      .then(() => {
                        window.location.href = routes.DASHBOARD;
                      })
                      .catch((error) => {
                        window.location.href = routes.DASHBOARD;
                        console.error("Failed to delete SR.");
                      });
                  }
                  onClose(
                    !(
                      (redirectRuleAction && isRecommendChangeAction(redirectRuleAction)) ||
                      objectType === "after details"
                    ) ||
                      (objectType === "after details" &&
                        redirectRuleAction !== undefined &&
                        !isRedirectAction(redirectRuleAction))
                  );
                });
              }}
              style={{ minWidth: 0 }}
            >
              {dashboardText}
            </Button>
          ) : objectType === "assessment" || objectType === "attachments" || objectType === "after details" ? (
            <InlineButton
              onClick={() => {
                trackInteraction({
                  ...userActivityParams,
                  interactionAccept: redirectRuleAction && isRedirectAction(redirectRuleAction),
                }).finally(() => {
                  onClose(
                    !(
                      (redirectRuleAction && isRecommendChangeAction(redirectRuleAction)) ||
                      objectType === "after details"
                    ) ||
                      (objectType === "after details" &&
                        redirectRuleAction !== undefined &&
                        !isRedirectAction(redirectRuleAction))
                  );
                });
              }}
              style={{ minWidth: 0 }}
            >
              {dashboardText}
            </InlineButton>
          ) : (redirectRuleAction && isRecommendChangeAction(redirectRuleAction)) || objectType === "service change" ? (
            <InlineButton
              onClick={() => {
                trackInteraction({
                  ...userActivityParams,
                  interactionAccept: false,
                }).finally(() => onClose(false));
              }}
              style={{ minWidth: 0 }}
            >
              {dashboardText}
            </InlineButton>
          ) : (
            <Link color="inherit" to={returnToUrl} style={{ textDecoration: "none" }}>
              <InlineButton style={{ minWidth: 0 }}>{dashboardText}</InlineButton>
            </Link>
          )}
        </DialogActions>
      </ImpressionTracking>
    </Dialog>
  );
}

interface ServiceRequestFormRedirectDispatchProps {
  workflowId: string | undefined;
  formContent: ServiceRequestFormContent;
  healthPlanName: string;
  clinicalService: ClinicalService | undefined;
  patient: Patient | undefined | null;
  authStage?: AuthBuilderWorkflowStep;
}
interface ServiceRequestFormRedirectDispatchResponse {
  redirectModalProps: Omit<IProps, "formContent" | "setFormContent">;
  dispatchRedirectRuleCheck: (errorObjectType: RedirectModalErrorObjectType) => RedirectRuleCheckFn;
  shouldWaitForRuleRunResult: boolean;
  dispatchUrgencyRuleCheck: (isExpedited: boolean) => Promise<void>;
}

export function useServiceRequestFormRedirectDispatch({
  workflowId,
  formContent,
  healthPlanName,
  clinicalService,
  patient,
  authStage,
}: ServiceRequestFormRedirectDispatchProps): ServiceRequestFormRedirectDispatchResponse {
  const { firedRecommendedNudges, setFiredRecommendedNudges } = useContext(FiredNudgesContext);
  const [redirectRuleAction, setRedirectRuleAction] = useState<ValidDisplayMessageAction>();
  const [shouldWaitForRuleRunResult, setShouldWaitForRuleRunResult] = useState(false);
  const [errorObjectType, setErrorObjectType] = useState<RedirectModalErrorObjectType>();
  const [invalidObjectModalOpen, setInvalidObjectModalOpen] = useState<boolean>(false);
  const { mutate: getServiceRequestActions } = useGetTransientServiceRequestRuleActions({});
  const generalAuthSubmissionWorkflowEnabled = useGeneralAuthSubmissionWorflowOn(healthPlanName);
  const dispatchRedirectRuleCheck = useCallback(
    (errorObjectType: RedirectModalErrorObjectType): RedirectRuleCheckFn =>
      (ruleActionRequest) => {
        const ruleActionRequestIncludingDiagnosisCodes = {
          ...ruleActionRequest,
          serviceRequest: {
            ...ruleActionRequest.serviceRequest,
            id: formContent?.id || undefined,
            primaryDiagnosis: formContent?.primaryDiagnosisCode || undefined,
            secondaryDiagnoses: formContent?.secondaryDiagnosisCodes,
            primarySemanticDiagnosisCode: formContent?.primaryDiagnosisCode || undefined,
            semanticProcedureCodes: formContent?.procedureCodes || undefined,
            secondarySemanticDiagnosisCodes: formContent?.secondaryDiagnosisCodes,
            workflowId: workflowId,
          },
          authStage,
        };
        return getServiceRequestActions(ruleActionRequestIncludingDiagnosisCodes).then(
          (response) => {
            //filter out rules blocking submission COH-3623
            let filteredRules = response;
            if (generalAuthSubmissionWorkflowEnabled) {
              filteredRules = removeRulesWhenPxCodesOnDifCarepaths(response, false) || [];
            }
            const hardRedirect = filteredRules?.find(isHardRedirectAction);
            const anyRedirect = filteredRules?.find(isRedirectAction);
            const recommendChange = filteredRules
              ?.filter(
                (rule) =>
                  !firedRecommendedNudges?.find((firedRecommendedNudge) => firedRecommendedNudge.ruleId === rule.ruleId)
              )
              .find(isRecommendChangeAction);
            if (recommendChange) {
              setFiredRecommendedNudges([...firedRecommendedNudges, recommendChange]);
            }
            // Prioritize showing hard redirects
            const action = hardRedirect || anyRedirect || recommendChange;
            if (action) {
              setRedirectRuleAction(action);
              setErrorObjectType(errorObjectType);
              setInvalidObjectModalOpen(true);
            } else {
              setRedirectRuleAction(undefined);
              setErrorObjectType(undefined);
              setInvalidObjectModalOpen(false);
            }
          },
          () => {}
        );
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      formContent?.primaryDiagnosisCode,
      formContent?.secondaryDiagnosisCodes,
      formContent?.procedureCodes,
      workflowId,
      firedRecommendedNudges,
      getServiceRequestActions,
      generalAuthSubmissionWorkflowEnabled,
    ]
  );

  const dispatchUrgencyRuleCheck = useCallback(
    async (isExpedited: boolean) => {
      setShouldWaitForRuleRunResult(true);
      await dispatchRedirectRuleCheck("urgency")({
        displayTarget: "URGENCY",
        authStage: authStage,
        serviceRequest: {
          patient: patient || undefined,
          clinicalService: clinicalService,
          ...(!!formatDateToISODate(formContent?.startDate)
            ? { startDate: formatDateToISODate(formContent?.startDate) }
            : {}),
          encounterType: formContent?.isInpatient ? "INPATIENT" : "OUTPATIENT",
          urgency: {
            isExpedited,
          },
          placeOfService: formContent?.placeOfService?.id,
          workflowId: workflowId,
        },
      });
      setShouldWaitForRuleRunResult(false);
    },
    [
      clinicalService,
      patient,
      formContent?.startDate,
      formContent?.isInpatient,
      formContent?.placeOfService?.id,
      workflowId,
      dispatchRedirectRuleCheck,
      authStage,
    ]
  );

  return {
    dispatchUrgencyRuleCheck,
    shouldWaitForRuleRunResult,
    dispatchRedirectRuleCheck,
    redirectModalProps: {
      open: invalidObjectModalOpen,
      objectType: errorObjectType,
      redirectRuleAction,
      context: {
        ruleId: redirectRuleAction?.ruleId,
        actionId: redirectRuleAction?.actionId,
        clinicalServiceId: clinicalService?.id,
        serviceRequestId: formContent?.id,
        cohereAuthId: formContent?.cohereId,
      },
      onClose: setInvalidObjectModalOpen,
    },
  };
}

export const formContentToSR = (
  formContent: ServiceRequestFormContent,
  simplifiedServiceFrequency: boolean
): GenericServiceRequest => ({
  palCategory: formContent.palCategory || undefined,
  clinicalService: formContent.clinicalService,
  primaryDiagnosis: formContent.primaryDiagnosisCode || undefined,
  primarySemanticDiagnosisCode: formContent.primaryDiagnosisCode || undefined,
  secondaryDiagnoses: formContent.secondaryDiagnosisCodes || [],
  secondarySemanticDiagnosisCodes: formContent.secondaryDiagnosisCodes || [],
  startDate: formatDateToISODate(formContent.startDate),
  procedureCodes: formContent.procedureCodes,
  semanticProcedureCodes: formContent.procedureCodes,
  placeOfService: formContent.placeOfService || undefined,
  encounterType: formContent?.isInpatient ? "INPATIENT" : "OUTPATIENT",
  facility: formContent.facility || undefined,
  performingProvider: formContent.performingProvider || undefined,
  orderingProvider: formContent.orderingProvider || undefined,
  urgency: {
    isExpedited: formContent.isExpedited,
    reasonNote: formContent.isExpedited ? formContent?.expeditedReason : undefined,
  },
  recurrenceType: simplifiedServiceFrequency ? undefined : formContent.isRecurring ? "RECURRING" : "ONETIME",
  ...(formContent.isRecurring || simplifiedServiceFrequency
    ? {
        units: Number(formContent.units),
        unitType: simplifiedServiceFrequency ? undefined : formContent.unitType,
        endDate: formatDateToISODate(formContent.endDate),
      }
    : {
        units: undefined,
        unitType: undefined,
        endDate: "",
      }),
});

export const formContentToContinuation = (formContent: ContinuationFormContent): GenericServiceRequest => ({
  startDate: formatDateToISODate(formContent.startDate),
  procedureCodes: formContent.procedureCodes,
  semanticProcedureCodes: formContent.procedureCodes,
  urgency: {
    isExpedited: formContent.isExpedited,
    reasonNote: formContent.isExpedited ? formContent?.expeditedReason : undefined,
  },
  units: Number(formContent.units),
  unitType: undefined,
  endDate: formatDateToISODate(formContent.endDate),
});

const srToFormContent = (
  serviceRequest: GenericServiceRequest,
  simplifiedServiceFrequency: boolean
): Partial<ServiceRequestFormContent> => ({
  palCategory: serviceRequest.palCategory || null,
  primaryDiagnosisCode: serviceRequest.primaryDiagnosis || null,
  secondaryDiagnosisCodes: serviceRequest.secondaryDiagnoses || [],
  startDate: parseDateFromISOString(serviceRequest.startDate),
  procedureCodes: serviceRequest.procedureCodes || [],
  semanticProcedureCodes: serviceRequest.semanticProcedureCodes || [],
  placeOfService: serviceRequest.placeOfService || null,
  isInpatient: serviceRequest?.encounterType === "INPATIENT",
  facility: serviceRequest.facility || null,
  performingProvider: serviceRequest.performingProvider || null,
  orderingProvider: serviceRequest.orderingProvider || null,
  isExpedited: serviceRequest.urgency?.isExpedited || false,
  expeditedReason: serviceRequest.urgency?.reasonNote || "",
  isRecurring: simplifiedServiceFrequency ? undefined : serviceRequest.recurrenceType === "RECURRING",
  units: serviceRequest.units !== undefined ? `${serviceRequest.units}` : "",
  unitType: simplifiedServiceFrequency ? undefined : serviceRequest.unitType,
  endDate: serviceRequest.endDate ? parseDateFromISOString(serviceRequest.endDate) : undefined,
});
