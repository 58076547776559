import type { AuthorizationResponse, GroupedPatientStayDate } from "@coherehealth/core-platform-api";

import { getTimelineGroups, buildTimelineItems, getStatusColorMap } from "../utils/patientStayTimelineUtils";
import { useTheme } from "@material-ui/core";

/**
 * Defines the visual styling properties for timeline items
 */
interface TimelineItemStyle {
  backgroundColor?: string;
  borderColor?: string;
  width?: string;
  overflow?: string;
}

/**
 * Represents a line segment in the timeline with text content
 */
interface LineItem {
  type: "line";
  style: TimelineItemStyle;
  mainText: string;
  subText?: string;
  dischargedText?: string;
}

/**
 * Represents a dot marker in the timeline
 */
interface DotItem {
  type: "dot";
  style: TimelineItemStyle;
}

/**
 * Union type representing either a line segment or dot in the timeline
 */
export type TimelineItem = LineItem | DotItem;

/**
 * Hook that generates the configuration for timeline visualization
 * @param authorization - The authorization response containing patient status and auth details
 * @param groupedStayDatesForTimeline - Array of grouped stay dates for timeline display
 * @returns Array of timeline items (dots and lines) with their styling and content
 */
export const usePatientStayTimelineItems = (
  authorization?: AuthorizationResponse,
  groupedStayDatesForTimeline?: GroupedPatientStayDate[] | null
): TimelineItem[] => {
  const theme = useTheme();

  if (!authorization) {
    return [];
  }

  const { authStatus, patientStatus } = authorization;

  const statusColorMap = getStatusColorMap(theme);
  const timelineGroups = getTimelineGroups({ authStatus, patientStatus }, groupedStayDatesForTimeline);
  return buildTimelineItems(timelineGroups, { authStatus, patientStatus }, statusColorMap);
};
