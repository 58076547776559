import React, {
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useCallback,
  MutableRefObject,
  useMemo,
  useRef,
  useContext,
} from "react";
import {
  Viewer,
  Worker,
  PageChangeEvent,
  SpecialZoomLevel,
  ZoomEvent,
  RotateEvent,
  LoadError,
  Plugin,
} from "@react-pdf-viewer/core";
import {
  SearchInfo,
  Attachment,
  Segment,
  CopyPasteInfo,
  AttachmentGuidelineTextHighlightsSnapshot,
  DocumentType,
  ServiceRequestResponse,
  AppealResponse,
} from "@coherehealth/core-platform-api";
import { defaultLayoutPlugin, SidebarTab } from "@react-pdf-viewer/default-layout";

import "@react-pdf-viewer/highlight/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/search/lib/styles/index.css";

// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, Theme } from "@material-ui/core";
import DehazeIcon from "@material-ui/icons/Dehaze";
import AttachmentViewerSidePanel, { AttachmentInfo, AugmentedDocSegmentation } from "./AttachmentViewerSidePanel";
import { UserClickInfoClinicalReviewPage, HighlightStateContext, HighlightReviewNoteStateContext } from "../../util";
import { ResizeObserver as ResizeObserverPolyfill } from "@juggle/resize-observer";
import PdfViewerRenderError from "./PdfViewerRenderError";
import ToolbarControl from "./ToolbarControl";
import CustomPage from "./CustomPage";
import HighlightEditTooltip from "./HighlightTooltip";
import { useFeature } from "../../components";
import { AttachmentViewerConfig } from "./util";
import { IAttachmentsMultiSearchHolder } from "./useAttachmentsMultiSearch";

interface Props {
  attachmentsMultiSearchHolder?: IAttachmentsMultiSearchHolder;
  currentAttachmentId?: string;
  fileName: string;
  pdfUrl: string;
  patientSummaryStyling?: boolean;
  crrStyling?: boolean;
  attachments?: Attachment[];
  handleAttachmentClick?: (index: number, landingPage?: number) => void;
  appealsForAuth?: AppealResponse[];
  attachmentIndexOpen?: number;
  openSidePanel?: boolean;
  setOpenSidePanel?: Dispatch<SetStateAction<boolean>>;
  setUserClickInfoTracking?: Dispatch<SetStateAction<UserClickInfoClinicalReviewPage>>;
  setSearchInfos?: Dispatch<React.SetStateAction<SearchInfo[]>>;
  currentPage?: MutableRefObject<number>;
  zoomLevel?: MutableRefObject<number>;
  currentRotate?: MutableRefObject<number>;
  hideSearchInAttachments?: boolean;
  hideBorders?: boolean;
  modalView?: boolean;
  withDocSegmentedSidePanel?: boolean;
  attachmentsInfo?: AttachmentInfo[];
  setAttachmentsInfo?: Dispatch<SetStateAction<AttachmentInfo[]>>;
  handleSegmentChange?: (
    updatedSegment: Segment,
    isDeletion: boolean,
    updatedPage: number,
    numberOfPages: number
  ) => void;
  savingFeedbackUpdates?: boolean;
  segmentsUpdated?: boolean;
  docSegmentationInfo?: AugmentedDocSegmentation[] | null;
  setCopiedAttachmentText?: Dispatch<SetStateAction<CopyPasteInfo | undefined>>;
  setAttachmentGuidelineTextHighlightsSnapshot?: Dispatch<SetStateAction<AttachmentGuidelineTextHighlightsSnapshot>>;
  previousAttachmentsExpanded?: boolean;
  setPreviousAttachmentsExpanded?: Dispatch<React.SetStateAction<boolean>>;
  isContinuationRequest?: boolean;
  viewerConfig?: AttachmentViewerConfig;
  serviceRequest?: ServiceRequestResponse | null;
  loadingRemainingAttachments?: boolean;
  onAttachmentOpenLoaded?: (index?: number) => void;
}

export interface DocTypeItem {
  id: DocumentType;
  label: string;
}

const DOC_TYPES: DocumentType[] = ["Clinical Note", "Diagnostic Image/Imaging", "Therapy Note", "Other"];
// Expanded document segmentation types used for the IP CPP experiment
const DOC_TYPES_EXPANDED: DocumentType[] = [
  "Progress Note",
  "Clinical Note",
  "Therapy Note",
  "Consult",
  "Discharge Summary",
  "ED Notes",
  "H&P",
  "Diagnostic Image/Imaging",
  "Criteria Summary",
  "Lab Report",
  "Other",
];
const DOC_TYPE_LABELS: Record<DocumentType, string> = {
  "Progress Note": "Progress note",
  "Clinical Note": "Clinical note",
  "Therapy Note": "Therapy note",
  Consult: "Consult",
  "Discharge Summary": "Discharge summary",
  "ED Notes": "ED notes",
  "H&P": "H&P",
  "Diagnostic Image/Imaging": "Imaging",
  "Criteria Summary": "Criteria summary",
  "Lab Report": "Lab report",
  Other: "Other",
};

export default function PdfViewer({
  attachmentsMultiSearchHolder,
  currentAttachmentId,
  fileName,
  pdfUrl,
  crrStyling,
  attachments,
  handleAttachmentClick,
  appealsForAuth,
  attachmentIndexOpen,
  setUserClickInfoTracking,
  setSearchInfos,
  currentPage,
  zoomLevel,
  currentRotate,
  openSidePanel,
  setOpenSidePanel,
  hideSearchInAttachments,
  hideBorders,
  modalView,
  withDocSegmentedSidePanel,
  attachmentsInfo,
  setAttachmentsInfo,
  handleSegmentChange,
  savingFeedbackUpdates,
  segmentsUpdated,
  docSegmentationInfo,
  setCopiedAttachmentText,
  setAttachmentGuidelineTextHighlightsSnapshot,
  previousAttachmentsExpanded,
  setPreviousAttachmentsExpanded,
  isContinuationRequest,
  viewerConfig,
  serviceRequest,
  loadingRemainingAttachments,
  onAttachmentOpenLoaded,
}: Props) {
  if (typeof window !== "undefined") {
    window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;
  }
  const [slideInSidePanel, setSlideInSidePanel] = useState(openSidePanel);
  const [sidePanelIsMounted, setSidePanelIsMounted] = useState(openSidePanel);
  const textInputRef = useRef<HTMLInputElement>();
  const currentDocSegmentation = docSegmentationInfo?.find(
    (docSeg) =>
      attachmentIndexOpen !== undefined && docSeg.attachmentId === attachmentsInfo?.[attachmentIndexOpen].uploadId
  );
  // Feature flag for IP CPP experiment
  const newDocSegmentationTypes = useFeature("newDocSegmentationTypes");

  const docTypeOptions: DocTypeItem[] = useMemo(
    () =>
      (newDocSegmentationTypes ? DOC_TYPES_EXPANDED : DOC_TYPES).map((docType) => ({
        id: docType,
        label: DOC_TYPE_LABELS[docType],
      })),
    [newDocSegmentationTypes]
  );

  const isCurrentAttachmentActive = useMemo(() => {
    const hasActiveAttachment = attachmentIndexOpen !== undefined;
    const mutliSearchDisabled = currentAttachmentId === undefined;
    const multiSearchEnabledAndIsActiveAttachment =
      hasActiveAttachment &&
      !mutliSearchDisabled &&
      currentAttachmentId === attachmentsInfo?.[attachmentIndexOpen]?.attachmentId;

    return hasActiveAttachment && (mutliSearchDisabled || multiSearchEnabledAndIsActiveAttachment);
  }, [currentAttachmentId, attachmentIndexOpen, attachmentsInfo]);

  const copyListner = useCallback(
    async (event: ClipboardEvent) => {
      if (!isCurrentAttachmentActive) {
        return;
      }

      let text = window?.getSelection()?.toString();
      const countNewLine = text?.split("\n").length;
      const countSpace = text?.split(" ").length;
      if (countNewLine && countSpace && countNewLine > countSpace) {
        text = text?.replaceAll("\n", " ");
        await navigator.clipboard?.writeText(text ? text : "");
        event.clipboardData?.setData("text/plain", text ? text : "");
        event.preventDefault();
      }
      const copyPasteInfo = {
        copiedText: text,
        page: currentPage?.current !== undefined ? currentPage?.current + 1 : undefined,
        attachmentId: attachmentIndexOpen !== undefined ? attachmentsInfo?.[attachmentIndexOpen].uploadId : undefined,
      };
      setCopiedAttachmentText?.(copyPasteInfo);
    },
    [setCopiedAttachmentText, attachmentIndexOpen, attachmentsInfo, currentPage, isCurrentAttachmentActive]
  );

  const isMacOs = () => {
    if (navigator.userAgent.indexOf("Mac") !== -1) {
      return true;
    }
    return false;
  };

  const keyDownListener = useCallback(
    (event: KeyboardEvent) => {
      if (!isCurrentAttachmentActive) {
        return;
      }
      if (!isMacOs() && event.ctrlKey && event.key === "f") {
        event.preventDefault();
        textInputRef.current?.focus();
      }
      if (isMacOs() && event.metaKey && event.key === "f") {
        event.preventDefault();
        textInputRef.current?.focus();
      }
    },
    [textInputRef, isCurrentAttachmentActive]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyDownListener);
    document.addEventListener("copy", copyListner);
    return () => {
      document.removeEventListener("keydown", keyDownListener);
      document.removeEventListener("copy", copyListner);
    };
  }, [keyDownListener, copyListner]);

  const renderToolbar = useCallback(ToolbarControl, [
    openSidePanel,
    setOpenSidePanel,
    crrStyling,
    currentDocSegmentation,
    attachmentsMultiSearchHolder,
  ]);

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {
      searchPlugin: {
        onHighlightKeyword: (props) => {
          props.highlightEle.style.pointerEvents = "none";
        },
      },
    },
    renderToolbar: (Toolbar) =>
      renderToolbar(
        Toolbar,
        attachmentsMultiSearchHolder,
        currentAttachmentId,
        openSidePanel,
        setOpenSidePanel,
        hideSearchInAttachments,
        setUserClickInfoTracking,
        currentDocSegmentation,
        setSearchInfos,
        crrStyling,
        currentPage,
        currentRotate,
        fileName,
        textInputRef,
        viewerConfig,
        loadingRemainingAttachments
      ),
    sidebarTabs: () => sideBarTabs,
  });

  const { toolbarPluginInstance } = defaultLayoutPluginInstance;
  const { pageNavigationPluginInstance } = toolbarPluginInstance;
  const { jumpToPage, CurrentPageLabel } = pageNavigationPluginInstance;
  /* these need to stay stable throughout the component lifecycle or we get
    "Maximum update depth exceeded" errors
   */
  const stableJumpToPage = useRef(jumpToPage);
  const stableCurrentPageLabel = useRef(CurrentPageLabel);

  const sidePanelRef = useCallback(
    (node: React.ReactNode) => {
      if (node !== null || openSidePanel) {
        setSidePanelIsMounted(true);
      } else {
        setSidePanelIsMounted(false);
      }
    },
    [openSidePanel]
  );

  const SidePanelComponent = viewerConfig?.sidePanelComponent;

  const sidePanel = useMemo(() => {
    if (!!handleAttachmentClick && !!attachments) {
      const jumpToPage = stableJumpToPage.current;
      const CurrentPageLabel = stableCurrentPageLabel.current;
      return {
        content: (
          <CurrentPageLabel>
            {({ currentPage, numberOfPages }) =>
              SidePanelComponent ? (
                <SidePanelComponent
                  attachments={attachments}
                  handleAttachmentClick={handleAttachmentClick}
                  attachmentIndexOpen={attachmentIndexOpen}
                  previousAttachmentsExpanded={previousAttachmentsExpanded}
                  openSidePanel={slideInSidePanel}
                  sidePanelRef={sidePanelRef}
                  attachmentsInfo={attachmentsInfo}
                  setAttachmentsInfo={setAttachmentsInfo}
                  jumpToPage={jumpToPage}
                  closeDialogFunction={viewerConfig.closeDialogFunction}
                />
              ) : (
                <AttachmentViewerSidePanel
                  attachments={attachments}
                  appealsForAuth={appealsForAuth}
                  handleAttachmentClick={handleAttachmentClick}
                  attachmentIndexOpen={attachmentIndexOpen}
                  previousAttachmentsExpanded={previousAttachmentsExpanded}
                  setPreviousAttachmentsExpanded={setPreviousAttachmentsExpanded}
                  openSidePanel={slideInSidePanel}
                  sidePanelRef={sidePanelRef}
                  withDocSegmentedSidePanel={withDocSegmentedSidePanel}
                  attachmentsInfo={attachmentsInfo}
                  numberOfPages={numberOfPages}
                  setAttachmentsInfo={setAttachmentsInfo}
                  jumpToPage={jumpToPage}
                  currentPage={currentPage}
                  segmentsUpdated={segmentsUpdated}
                  docSegmentationInfo={docSegmentationInfo}
                  savingFeedbackUpdates={savingFeedbackUpdates}
                  setAttachmentGuidelineTextHighlightsSnapshot={setAttachmentGuidelineTextHighlightsSnapshot}
                  isContinuationRequest={isContinuationRequest}
                  docTypeOptions={docTypeOptions}
                  isCurrentAttachmentActive={isCurrentAttachmentActive}
                  attachmentsMultiSearchHolder={attachmentsMultiSearchHolder}
                />
              )
            }
          </CurrentPageLabel>
        ),
        icon: <DehazeIcon />,
        title: "Attachment list",
      };
    } else {
      return null;
    }
  }, [
    handleAttachmentClick,
    attachments,
    appealsForAuth,
    SidePanelComponent,
    attachmentIndexOpen,
    previousAttachmentsExpanded,
    slideInSidePanel,
    sidePanelRef,
    attachmentsInfo,
    setPreviousAttachmentsExpanded,
    withDocSegmentedSidePanel,
    setAttachmentsInfo,
    segmentsUpdated,
    docSegmentationInfo,
    savingFeedbackUpdates,
    setAttachmentGuidelineTextHighlightsSnapshot,
    isContinuationRequest,
    docTypeOptions,
    viewerConfig,
    isCurrentAttachmentActive,
    attachmentsMultiSearchHolder,
  ]);
  const [sideBarTabs, setSideBarTabs] = useState<SidebarTab[]>(openSidePanel && sidePanel ? [sidePanel] : []);

  useEffect(() => {
    if (openSidePanel && sidePanel && !sidePanelIsMounted) {
      setSideBarTabs([sidePanel]);
      setSlideInSidePanel(true);
    } else if (!openSidePanel && sidePanel && sidePanelIsMounted) {
      setSideBarTabs([sidePanel]); /** trigger slide out animation without removing sidePanel from state array */
      setSlideInSidePanel(false);
    } else {
      setSideBarTabs(openSidePanel && sidePanel ? [sidePanel] : []);
    }
  }, [openSidePanel, sidePanel, sidePanelIsMounted, currentAttachmentId]);
  useEffect(() => {
    /** after slide out animation completes, remove sidePanel component */
    if (!sidePanelIsMounted) {
      setSideBarTabs([]);
    }
  }, [sidePanelIsMounted]);

  const handleDocumentLoad = () => {
    const { activateTab } = defaultLayoutPluginInstance;
    if (sidePanel) {
      activateTab(0);
    }

    onAttachmentOpenLoaded?.(attachmentIndexOpen);
  };

  const handlePageChange = (e: PageChangeEvent) => {
    currentPage && (currentPage.current = e.currentPage);
  };

  const handleZoomChange = (e: ZoomEvent) => {
    shouldUpdatePdfSpans && (shouldUpdatePdfSpans.current = true);
    zoomScale && (zoomScale.current = e.scale);
    zoomLevel && (zoomLevel.current = e.scale);
  };

  const handlePageRotation = (e: RotateEvent) => {
    currentRotate && (currentRotate.current = e.rotation);
  };

  const renderError = (error: LoadError) => {
    return (
      <PdfViewerRenderError
        previousAttachmentsExpanded={previousAttachmentsExpanded}
        setPreviousAttachmentsExpanded={setPreviousAttachmentsExpanded}
        error={error}
        attachments={attachments}
        handleAttachmentClick={handleAttachmentClick}
        attachmentIndexOpen={attachmentIndexOpen}
        openSidePanel={openSidePanel}
        setOpenSidePanel={setOpenSidePanel}
        attachmentsInfo={attachmentsInfo}
        crrStyling
        withDocSegmentedSidePanel={withDocSegmentedSidePanel}
        viewerConfig={viewerConfig}
        setAttachmentsInfo={setAttachmentsInfo}
      />
    );
  };

  const [headerPositionRight, setHeaderPositionRight] = useState<boolean>(true);
  const { linking, highlightPluginInstance, shouldUpdatePdfSpans, zoomScale } = useContext(HighlightStateContext);
  const { highlightReviewNotePluginInstance } = useContext(HighlightReviewNoteStateContext);

  let plugins: Plugin[] = [defaultLayoutPluginInstance];
  !!highlightPluginInstance && plugins.push(highlightPluginInstance);
  !!highlightReviewNotePluginInstance && plugins.push(highlightReviewNotePluginInstance);

  return (
    <>
      <DocumentWrapper hideBorders={hideBorders} scrollingDisabled={!!linking} hasViewerConfig={!!viewerConfig}>
        <Worker workerUrl="/pdf.worker.min.js">
          <PdfViewerDiv modalView={modalView} crrStyling={crrStyling} hasViewerConfig={!!viewerConfig}>
            <Viewer
              fileUrl={pdfUrl}
              plugins={plugins}
              onDocumentLoad={handleDocumentLoad}
              defaultScale={SpecialZoomLevel.PageWidth}
              initialPage={currentPage?.current || 0}
              onPageChange={handlePageChange}
              onZoom={handleZoomChange}
              onRotate={handlePageRotation}
              renderError={renderError}
              renderPage={
                !viewerConfig && withDocSegmentedSidePanel && attachmentIndexOpen !== undefined
                  ? (renderPageProps) => (
                      <CustomPage
                        renderPageProps={renderPageProps}
                        stableCurrentPageLabel={stableCurrentPageLabel}
                        handleSegmentChange={handleSegmentChange}
                        savingFeedbackUpdates={savingFeedbackUpdates}
                        docSegmentationInfo={currentDocSegmentation}
                        headerPositionRight={headerPositionRight}
                        setHeaderPositionRight={setHeaderPositionRight}
                        docTypeOptions={docTypeOptions}
                      />
                    )
                  : undefined
              }
            />
          </PdfViewerDiv>
        </Worker>
      </DocumentWrapper>
      <HighlightEditTooltip />
    </>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DocumentWrapper = styled(({ hideBorders, scrollingDisabled, hasViewerConfig, ...other }) => <div {...other} />)<
  Theme,
  { hideBorders?: boolean; scrollingDisabled: boolean; hasViewerConfig?: boolean }
>(({ theme, hideBorders, scrollingDisabled, hasViewerConfig }) => ({
  position: "relative",
  width: "100%",
  "& .rpv-default-layout__sidebar-headers": {
    display: "none",
  },
  "& .rpv-default-layout__container": {
    borderStyle: hideBorders ? "none" : "1px solid rgba(0,0,0,.3)",
    border: "1px solid rgba(0,0,0,0.1)",
  },
  "& .rpv-default-layout__sidebar--opened": {
    width: hasViewerConfig ? "400px" : "266px",
  },
  "& .rpv-core__splitter": {
    display: "none",
  },
  "& .rpv-core__inner-pages": {
    overflow: scrollingDisabled ? "hidden !important" : "auto",
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PdfViewerDiv = styled(({ modalView, crrStyling, hasViewerConfig, ...other }) => <div {...other} />)<
  Theme,
  { modalView?: boolean; crrStyling?: boolean; hasViewerConfig?: boolean }
>(({ modalView, crrStyling, hasViewerConfig }) => ({
  width: "100%",
  height: hasViewerConfig ? "100vh" : modalView ? 948 : crrStyling ? "calc(100vh - 148px)" : "100vh",
  "& .rpv-search__highlights": {
    "& .rpv-search__highlight": {
      opacity: "0.3",
      backgroundColor: "#C7C7C7",
    },
    "& .rpv-search__highlight--current": {
      backgroundColor: "#6E6E6E",
    },
  },
}));
