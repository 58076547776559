import { Dispatch, SetStateAction, useEffect, useState, useContext, useRef, useCallback } from "react";
import { useSnackbar } from "notistack";

import {
  Caption,
  H5,
  SecondaryButton,
  Alert,
  Body2,
  colorsLight,
  Sanitized,
  H4,
  Tooltip,
  InlineButton,
  formatDateStrAsEastern,
  useFeature,
  HEALTH_HELP_NAME,
  HEALTH_HELP_V2_NAME,
  useGeneralAuthSubmissionWorflowOn,
  SummaryCardMedicalIcon,
  getNoTimezoneDateTimeString,
  extractClinicalServicesText,
  useConfiguration,
} from "@coherehealth/common";
import {
  ServiceRequestResponse,
  useDeleteServiceRequest,
  RuleActions,
  useGetServiceRequestAttachments,
  ServiceRequestUpdatePayload,
  useUpdateAuthorizationById,
  UpdateServiceRequestQueryParams,
  UpdateServiceRequestPathParams,
  Attachment,
  ServiceRequestCreatePayload,
} from "@coherehealth/core-platform-api";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import MUIAlert from "@material-ui/lab/Alert";

import ServiceRequestForm from "components/ServiceRequest/ServiceRequestForm/ServiceRequestFormSubmissionWrapper";
import ServiceRequestReadonlyDetail from "components/ServiceRequest/ReadonlyDetail";
import { removeEmptyAttachments, useIsFaxAuthBuilderWorkflow } from "util/attachmentUtil";
import { canBeDeleted, useIsDelegatedToVendor } from "util/serviceRequest";
import {
  isSetValueAction,
  isRedirectAction,
  isRecommendChangeAction,
  RedirectAction,
  RecommendChangeAction,
  ValidDisplayMessageAction,
} from "util/rule";
import { GetDataError } from "restful-react";
import ImpressionTracking from "common/ImpressionTracking";
import { activityContextFromServiceRequest, activitySnapshotFromServiceRequest } from "util/userActivityTracker";
import { ClinicalAssessmentContext } from "components/ClinicalAssessment";
import { SectionHeaderContainer } from "../../PatientSummary/ServiceRequestSummaryCard/ServiceRequestSummaryDetails";
import PencilIcon from "@material-ui/icons/Create";
import AttachmentSection from "../../Attachments/AttachmentSection";
import ServiceRequestIntegrationSummary from "../../IntegrationManagement/ServiceRequestIntegrationSummary";
import { useAuthorized } from "../../../authorization";
import ServiceRequestAuditHistory from "../../ServiceRequest/ReadonlyDetail/ServiceRequestAuditHistory";
import ServiceRequestHistory from "../../ServiceRequest/ReadonlyDetail/ServiceRequestHistory";
import ClinicalAssessmentSection from "../../PatientSummary/ServiceRequestSummaryCard/ClinicalAssessmentSection";
import { useGetServiceRequestRuleActions } from "@coherehealth/core-platform-api";
import RequestedByInfo from "../../PatientSummary/AuthorizationPatientSummary/RequestedByInfo";
import useIsOverflow from "../../../hooks/useIsOverflow";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { MutateMethod } from "restful-react";
import useGetFacilityBasedRequestConfigurationByPayer from "hooks/useGetFeatureConfigurations";
import { convertStringToAuthBuilderWorkflowStep } from "../common";
import { SmartOnFhirContext } from "components/SmartOnFhir/SmartOnFhirSecurityProvider";
import { routeToPatientSummaryFromReviewAfterDelete } from "../../../util/routeUtils/routeUtils";
import { AuthorizationResponse } from "@coherehealth/core-platform-api";
import AuthVisibilityToggle from "common/AuthVisibilityToggle";
import AuthVisibilityMessage from "common/AuthVisibilityMessage";
import { UserResponse } from "@coherehealth/core-platform-api";
import { useLocation, useNavigate } from "react-router-dom";
import { ServiceRequestStatusDisplay } from "components/ServiceRequestStatusDisplay/StatusBanner/ServiceRequestStatusDisplay";
import { PatientStayTimeline } from "components/ServiceRequest/PatientStay/PatientStayTimeline/PatientStayTimeline";

const useStyles = makeStyles((theme) => ({
  h5BodyContainer: {
    width: "100%",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: "100%",
    "&.MuiGrid-item": {
      margin: theme.spacing(0.5, 0),
    },
  },
  headerContainer: {
    padding: "16px 1px 4px 0",
  },
  hover: {
    "&:hover": {
      background: "rgba(0, 0, 0, 0.04)",
      "background-blend-mode": "multiply",
    },
  },
  headerWrapper: {
    display: "flex",
    gap: "8px",
  },
  gridPadding: {
    flexDirection: "row",
    padding: theme.spacing(0.5, 1),
  },
  zeroMargin: {
    "&.MuiGrid-item": {
      margin: theme.spacing(0),
    },
  },
  customWidth: {
    maxWidth: 700,
  },
  requestDetailsSubheader: {
    paddingLeft: 8,
    whiteSpace: "nowrap",
    alignSelf: "center",
  },
  editRequestWrapper: {
    justifyContent: "flex-end",
    margin: 0,
  },
  editRequestTooltip: {
    padding: theme.spacing(0, 1),
  },
  deleteRequestWrapper: {
    padding: theme.spacing(0, 1, 0, 2),
  },
  serviceChangeBannerContainer: {
    marginBottom: theme.spacing(3),
  },
  serviceChangeBanner: {
    color: theme.palette.info.dark,
    fontWeight: 400,
    fontFamily: "Gilroy-SemiBold",
    fontSize: theme.typography.body1.fontSize,
  },
  cancelButton: {
    marginRight: 0,
  },
  cancelMargin: {
    marginRight: theme.spacing(1),
  },
}));

interface Props {
  serviceRequest: ServiceRequestResponse;
  setServiceRequest: (sr: ServiceRequestResponse) => void;
  onEdit?: (sr: ServiceRequestResponse) => void;
  onDelete?: (sr: ServiceRequestResponse) => void;
  ruleActions: RuleActions;
  refetchActions: ReturnType<typeof useGetServiceRequestRuleActions>["refetch"];
  updateServiceRequest: MutateMethod<
    ServiceRequestResponse,
    ServiceRequestUpdatePayload,
    UpdateServiceRequestQueryParams,
    UpdateServiceRequestPathParams
  >;
  submitting: boolean;
  submitError: GetDataError<unknown> | null;
  disableEdits?: boolean;
  workflowId?: string;
  setReviewEdit: Dispatch<SetStateAction<boolean>>;
  refetchServiceRequest: () => void;
  willAutoApprove: boolean;
  caqSkipped: boolean;
  isContinuationSR?: boolean;
  setPerformingProviderExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  performingProviderExceptionRequest?: boolean;
  setFacilityExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  facilityExceptionRequest?: boolean;
  setOrderingProviderExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  orderingProviderExceptionRequest?: boolean;
  setCareParticipantExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  careParticipantExceptionRequest?: boolean;
  setAcceptedNudge: Dispatch<SetStateAction<ValidDisplayMessageAction[]>>;
  showServiceChangeBanner?: boolean;
  setShowServiceChangeBanner: Dispatch<SetStateAction<boolean>>;
  isInAuthBuilder?: boolean;
  authorization?: AuthorizationResponse | null;
  user?: UserResponse | null;
  visibilityToggleStatus: boolean;
  setVisibilityToggleStatus: Dispatch<SetStateAction<boolean>>;
  setPerformingProviderPracticeExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  performingProviderPracticeExceptionRequest?: boolean;
  setPerformingProviderPracticeOonCheckLoading?: Dispatch<SetStateAction<boolean>>;
}

/**
 * Expanded service request summary, with an edit button
 *
 * This is used for both care-path cart and single service requests (so don't use the CarePathCartContext)
 */
export default function ServiceRequestReview({
  serviceRequest,
  onEdit,
  onDelete,
  ruleActions,
  refetchActions,
  updateServiceRequest,
  submitting,
  submitError,
  setServiceRequest,
  disableEdits,
  workflowId,
  setReviewEdit,
  refetchServiceRequest,
  willAutoApprove,
  caqSkipped,
  isContinuationSR,
  setAcceptedNudge,
  setPerformingProviderExceptionRequest,
  performingProviderExceptionRequest,
  setFacilityExceptionRequest,
  facilityExceptionRequest,
  setOrderingProviderExceptionRequest,
  orderingProviderExceptionRequest,
  setCareParticipantExceptionRequest,
  careParticipantExceptionRequest,
  showServiceChangeBanner,
  setShowServiceChangeBanner,
  isInAuthBuilder,
  authorization,
  user,
  visibilityToggleStatus,
  setVisibilityToggleStatus,
  performingProviderPracticeExceptionRequest,
  setPerformingProviderPracticeExceptionRequest,
  setPerformingProviderPracticeOonCheckLoading,
}: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const { clinicalAssessment, updateClinicalAssessment } = useContext(ClinicalAssessmentContext);
  const [editing, setEditing] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const canViewServiceRequestIntegration = useAuthorized("EDIT_SERVICE_REQUEST_STATUS");
  const canShowHistory = useAuthorized("VIEW_SERVICE_REQUEST_AUDIT_LOG");
  const auditServiceHistory = useFeature("auditServiceHistory");
  const multiSingleService = useFeature("multiSingleService");
  const { facilityBasedFeatureEnabled } = useGetFacilityBasedRequestConfigurationByPayer({
    healthPlanName: serviceRequest?.healthPlanName,
    encounterType: serviceRequest?.encounterType,
    skipRequestTimingCheck: true,
    delegatedVendorName: serviceRequest?.delegatedVendor,
    srContent: serviceRequest,
  });
  const isDelegatedToHealthHelp = useIsDelegatedToVendor(serviceRequest, [HEALTH_HELP_NAME, HEALTH_HELP_V2_NAME]);

  const isSingleService = !Boolean(serviceRequest?.carePathJourney?.id);

  // There are some old service requests in the system with a PAL category; these have no assessment
  const hasNoAssessment =
    (isDelegatedToHealthHelp && !serviceRequest?.vendorIdentifier) ||
    (isSingleService && serviceRequest.palCategory) ||
    caqSkipped;

  const { mutate: deleteServiceRequest, loading: deleting, error: deleteError } = useDeleteServiceRequest({});
  useEffect(() => {
    if (deleteError) {
      enqueueSnackbar(`Error removing service request: ${deleteError.message}`, { variant: "error" });
    }
  }, [enqueueSnackbar, deleteError]);
  const deleteSR = useCallback(async () => {
    if (canBeDeleted(serviceRequest)) {
      await deleteServiceRequest(serviceRequest.id);
      if (isInAuthBuilder) {
        routeToPatientSummaryFromReviewAfterDelete(serviceRequest?.patient?.id, navigate);
      }
      setEditing(false);
      onDelete?.(serviceRequest);
    }
  }, [deleteServiceRequest, onDelete, serviceRequest, isInAuthBuilder, navigate]);

  const { mutate: patchAuthorization } = useUpdateAuthorizationById({ id: "" });

  useEffect(() => {
    if (submitError) {
      enqueueSnackbar(`Error updating service request: ${submitError.message}`, { variant: "error" });
    }
  }, [enqueueSnackbar, submitError]);

  const setIsAdvanceCoverageDeterminationAction = ruleActions
    .filter(isSetValueAction)
    .find(
      (action) => action.objectAttribute === "isAdvanceCoverageDetermination" && action.objectValue?.value === true
    );

  const { data: serviceRequestAttachments, refetch: refetchAttachments } = useGetServiceRequestAttachments({
    id: serviceRequest.id,
    resolve: (data: Attachment[]) => {
      return removeEmptyAttachments(data) || [];
    },
  });

  const serviceCategoryName =
    serviceRequest.type === "Pharmacy" ? "Drug" : serviceRequest.clinicalService?.serviceCategoryName;
  const hasServiceCategory = !!serviceCategoryName;

  const editSRButtonHandler = () => {
    setEditing(true);
    setReviewEdit(true);
  };
  const cancelEditSRHandler = () => {
    setEditing(false);
    setReviewEdit(false);
  };

  const generalAuthSubmissionWorkflowEnabled = useGeneralAuthSubmissionWorflowOn(serviceRequest.healthPlanName || "");

  const textElementRef = useRef<HTMLInputElement | null>(null);

  const { hoverStatus } = useIsOverflow(textElementRef);

  const rulesMap = new Map();
  ruleActions.forEach((rule) => {
    if (rule.ruleId && !rulesMap.get(rule.ruleId)) {
      rulesMap.set(rule.ruleId, rule);
    }
  });

  const uniqueRulesActions = rulesMap.size > 0 ? Array.from(rulesMap.values()) : ruleActions;
  const smartClient = useContext(SmartOnFhirContext);
  const inSmartOnFhirWorkflow = Boolean(smartClient);
  const location = useLocation();
  const isFaxAuthBuilderFlow = useIsFaxAuthBuilderWorkflow(location);

  const serviceRequestIsRestricted = serviceRequest?.isRestricted;
  const serviceRequestIsViewable = !serviceRequest?.isRestricted && serviceRequest?.hasRestrictedCodes;

  const facilityBasedConfigEnabled = useConfiguration(
    "facilityBasedRequestConfiguration",
    serviceRequest?.healthPlanName,
    serviceRequest?.delegatedVendor
  );
  const showPatientStayTimeline =
    useFeature("showPatientStayTimeline") &&
    serviceRequest.encounterType === "INPATIENT" &&
    facilityBasedConfigEnabled?.enabled;

  return (
    <Grid container spacing={2}>
      <Grid container alignItems={hasServiceCategory ? "flex-start" : "center"} className={classes.gridPadding}>
        {hasServiceCategory && (
          <Grid item xs={12}>
            <Caption data-public>{""}</Caption>
          </Grid>
        )}
        {!generalAuthSubmissionWorkflowEnabled &&
        !facilityBasedFeatureEnabled &&
        serviceRequest.encounterType !== "INPATIENT" ? ( // Apply the new header to IP with facilityBasedFeatureEnabled enabled SRs and the original to OP
          <Grid item xs={12}>
            <H5 data-public>
              {serviceRequest.clinicalService?.name ||
                serviceRequest.palCategory?.name ||
                serviceRequest?.prescribedDrug?.drugDescription ||
                "Request"}
            </H5>
          </Grid>
        ) : (
          <Tooltip
            title={extractClinicalServicesText(
              serviceRequest.clinicalServices || [],
              serviceRequest.authCategory,
              serviceRequest.authSubcategory,
              serviceRequest.palCategory
            )}
            disableHoverListener={!hoverStatus}
            placement="top"
            classes={{ tooltip: classes.customWidth }}
            data-public
          >
            <Grid
              item
              xs={12}
              className={
                hoverStatus
                  ? `${classes.h5BodyContainer} ${classes.hover} ${classes.zeroMargin}`
                  : `${classes.h5BodyContainer}`
              }
              ref={textElementRef}
            >
              <div className={classes.headerWrapper}>
                <SummaryCardMedicalIcon />
                <H5 style={{ alignSelf: "center" }} data-public>
                  {extractClinicalServicesText(
                    serviceRequest.clinicalServices || [],
                    serviceRequest.authCategory,
                    serviceRequest.authSubcategory,
                    serviceRequest.palCategory
                  )}
                </H5>
              </div>
            </Grid>
          </Tooltip>
        )}
      </Grid>
      {serviceRequest.type !== "Pharmacy" &&
        uniqueRulesActions.filter(isRedirectAction).map((redirectAction) => (
          <Grid key={redirectAction.actionId} item xs={12}>
            {MessageBanner(redirectAction, serviceRequest, workflowId)}
          </Grid>
        ))}
      {serviceRequest.type !== "Pharmacy" &&
        uniqueRulesActions.filter(isRecommendChangeAction).map(
          (recommendChangeAction) =>
            !recommendChangeAction.onAcceptAttribute && (
              <Grid item xs={12}>
                {MessageBanner(recommendChangeAction, serviceRequest, workflowId)}
              </Grid>
            )
        )}
      {willAutoApprove && (
        <Grid item xs={12}>
          <MUIAlert severity="success" data-public>
            Service request will auto-approve
          </MUIAlert>
        </Grid>
      )}
      <Grid item xs={12}>
        <ServiceRequestStatusDisplay
          serviceRequest={serviceRequest}
          openServiceRequestForEditing={() => setEditing(true)}
          deleteServiceRequestHandler={deleteSR}
          isInAuthBuilder
          onEdit={editSRButtonHandler}
          actionConfiguration={{
            editStatusActionConfiguration: {
              disabled: Boolean(disableEdits),
              hidden: false,
            },
            draftDeleteActionConfiguration: {
              disabled: inSmartOnFhirWorkflow,
              hidden: inSmartOnFhirWorkflow,
              noRender: inSmartOnFhirWorkflow,
            },
          }}
        />
      </Grid>
      {serviceRequestIsViewable && (
        <Grid item xs={12} style={{ paddingBottom: theme.spacing(0.5) }}>
          <AuthVisibilityToggle
            authorization={serviceRequest?.authorization}
            renderedFromPatientSummary={false}
            user={user}
            visibilityToggleStatus={visibilityToggleStatus}
            setVisibilityToggleStatus={setVisibilityToggleStatus}
          />
        </Grid>
      )}
      <AuthVisibilityMessage
        serviceRequest={serviceRequest}
        user={user}
        isRestricted={serviceRequestIsRestricted}
        isViewable={serviceRequestIsViewable}
        visibilityToggleStatus={visibilityToggleStatus}
      />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {Boolean(setIsAdvanceCoverageDeterminationAction) && !editing && (
        <>
          <Grid item xs={12}>
            <Alert severity="warning" message="This request will be processed as an advance coverage determination.">
              This is a Medicare Advantage private fee-for-service (PFFS) member.
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      )}
      {showPatientStayTimeline && (
        <>
          <Grid item xs={12}>
            <H4 data-public>Patient Stay</H4>
          </Grid>
          <Grid item xs={12}>
            <PatientStayTimeline serviceRequest={serviceRequest} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      )}
      <SectionHeaderContainer className={classes.headerContainer}>
        <H4 className={classes.requestDetailsSubheader} data-public>
          Details
        </H4>
        {editing ? (
          <SecondaryButton
            onClick={cancelEditSRHandler}
            warning
            className={isFaxAuthBuilderFlow ? classes.cancelMargin : classes.cancelButton}
          >
            Cancel
          </SecondaryButton>
        ) : (
          <Grid
            container
            spacing={0}
            className={classes.editRequestWrapper}
            style={{ marginRight: isFaxAuthBuilderFlow ? 6 : 0 }}
          >
            <Grid item className={classes.editRequestTooltip} style={{ paddingRight: isFaxAuthBuilderFlow ? 0 : 8 }}>
              <Tooltip title={disableEdits ? "Edit requests" : ""} placement="top" arrow>
                <div>
                  <InlineButton disabled={disableEdits} startIcon={<PencilIcon />} onClick={editSRButtonHandler}>
                    <Body2>Edit</Body2>
                  </InlineButton>
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        )}
      </SectionHeaderContainer>
      <Grid item xs={12}>
        {showServiceChangeBanner && (
          <Alert severity={"info"} className={classes.serviceChangeBannerContainer}>
            <Body2 className={classes.serviceChangeBanner}>
              You accepted the suggestion to change the procedure. Before submitting, we recommend double checking dates
              of service, provider details, and facility information.
            </Body2>
          </Alert>
        )}
        {editing ? (
          <ServiceRequestForm
            serviceRequest={serviceRequest}
            submitting={submitting || deleting}
            submitButtonText="Save"
            onSave={async (sr) => {
              const retVal = await updateServiceRequest({
                ...sr,
              });

              await patchAuthDischargedData(sr, retVal);

              onEdit?.(retVal);
              setEditing(false);
              setServiceRequest(retVal);
              refetchActions({
                pathParams: {
                  id: serviceRequest.id,
                },
                queryParams: {
                  displayTarget: "PRE_SUBMISSION_REVIEW",
                  authStage: convertStringToAuthBuilderWorkflowStep("REVIEW"),
                },
              });
              setReviewEdit(false);
              //clear out accepted nudges so that after edit, users are nudged again if they manually reverse the results of an accepted nudge per INTER-14
              //https://coherehealth.atlassian.net/browse/INTER-14
              setAcceptedNudge([]);
              // on save, remove nudge to swap banner (requires editing SR)
              //https://coherehealth.atlassian.net/browse/INTER-329
              setShowServiceChangeBanner(false);
            }}
            onDelete={deleteSR}
            patient={serviceRequest.patient}
            workflowId={workflowId}
            populateDefaults={false}
            isAuthorizationSr={isContinuationSR}
            performingProviderExceptionRequest={performingProviderExceptionRequest}
            setPerformingProviderExceptionRequest={setPerformingProviderExceptionRequest}
            facilityExceptionRequest={facilityExceptionRequest}
            setFacilityExceptionRequest={setFacilityExceptionRequest}
            orderingProviderExceptionRequest={orderingProviderExceptionRequest}
            setOrderingProviderExceptionRequest={setOrderingProviderExceptionRequest}
            careParticipantExceptionRequest={careParticipantExceptionRequest}
            setCareParticipantExceptionRequest={setCareParticipantExceptionRequest}
            setPerformingProviderPracticeExceptionRequest={setPerformingProviderPracticeExceptionRequest}
            performingProviderPracticeExceptionRequest={performingProviderPracticeExceptionRequest}
            setPerformingProviderPracticeOonCheckLoading={setPerformingProviderPracticeOonCheckLoading}
          />
        ) : (
          <ServiceRequestReadonlyDetail
            serviceRequest={{
              ...serviceRequest,
              attachments: serviceRequestAttachments || [],
            }}
            openServiceRequestForEditing={() => setEditing(true)}
            disableEdits={disableEdits}
            ruleActions={ruleActions}
            refetchServiceRequest={refetchServiceRequest}
            onAssessmentUpdate={(clinicalAssessment) => {
              updateClinicalAssessment({ type: "populate", clinicalAssessment });
            }}
            caqSkipped={caqSkipped}
            isContinuationSR={isContinuationSR}
            onReviewPage={true}
            showServiceChangeBanner={showServiceChangeBanner}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <AttachmentSection
          serviceRequest={{ ...serviceRequest, attachments: serviceRequestAttachments || [] }}
          onEdit={refetchServiceRequest}
          onSaveAttachments={() => {
            refetchAttachments();
            refetchActions({
              pathParams: {
                id: serviceRequest.id,
              },
              queryParams: {
                displayTarget: "PRE_SUBMISSION_REVIEW",
                authStage: convertStringToAuthBuilderWorkflowStep("REVIEW"),
              },
            });
          }}
        />
      </Grid>
      {canViewServiceRequestIntegration && (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ServiceRequestIntegrationSummary serviceRequest={serviceRequest} onEdit={refetchServiceRequest} />
          </Grid>
        </>
      )}
      {canShowHistory && (
        <Grid item xs={12}>
          <InlineButton
            title="Toggle audit history"
            onClick={() => {
              if (canShowHistory) {
                setShowHistory(!showHistory);
              }
            }}
          >
            <Caption color="primary" data-public>
              {serviceRequest.authStatus === "DRAFT"
                ? "Draft"
                : "Submitted " + formatDateStrAsEastern(serviceRequest.intakeTimestamp)}
            </Caption>
          </InlineButton>
        </Grid>
      )}
      {showHistory && canShowHistory && (
        <Grid item xs={12}>
          {auditServiceHistory ? (
            <ServiceRequestAuditHistory serviceRequestId={serviceRequest.id} />
          ) : (
            <ServiceRequestHistory serviceRequestId={serviceRequest.id} />
          )}
        </Grid>
      )}
      {!hasNoAssessment && (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ClinicalAssessmentSection
              serviceRequest={serviceRequest}
              showReadOnlyVendorSummary={isDelegatedToHealthHelp}
              onAssessmentUpdate={(updatedClinicalAssessment) => {
                // If the updatedClinicalAssessment questions are not the same length than we have in state than
                // match based on originalVersionId and update the answers accordingly
                if (
                  multiSingleService &&
                  clinicalAssessment?.questions?.length !== updatedClinicalAssessment.questions?.length
                ) {
                  const mergedClinicalQuestions = clinicalAssessment?.questions?.map((question) => {
                    const foundQuestion = updatedClinicalAssessment.questions?.find(
                      (updatedQuestion) =>
                        updatedQuestion.clinicalQuestion?.originalVersionId ===
                        question.clinicalQuestion?.originalVersionId
                    );
                    if (foundQuestion) {
                      return {
                        ...question,
                        answerContexts: foundQuestion.answerContexts,
                        answers: foundQuestion.answers,
                        clinicalQuestion: foundQuestion.clinicalQuestion,
                      };
                    } else {
                      return { ...question };
                    }
                  });
                  const mergedClinicalAssessment = {
                    ...clinicalAssessment,
                    questions: mergedClinicalQuestions,
                  };
                  updateClinicalAssessment({ type: "populate", clinicalAssessment: mergedClinicalAssessment });
                } else {
                  updateClinicalAssessment({ type: "populate", clinicalAssessment: updatedClinicalAssessment });
                }
                refetchActions({
                  pathParams: {
                    id: serviceRequest.id,
                  },
                  queryParams: {
                    displayTarget: "PRE_SUBMISSION_REVIEW",
                    authStage: convertStringToAuthBuilderWorkflowStep("REVIEW"),
                  },
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <RequestedByInfo
              serviceRequest={serviceRequest}
              serviceRequestRefetch={async () => {
                refetchServiceRequest();
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );

  async function patchAuthDischargedData(sr: ServiceRequestCreatePayload, retVal: ServiceRequestResponse) {
    if ((sr?.dischargedTo || (sr.dischargeDate && sr.dischargeTime)) && retVal?.authorization?.id) {
      const patchAuthParams = { dischargedTo: "", actualDischargeDateTime: "" };
      if (sr?.dischargedTo) {
        patchAuthParams.dischargedTo = sr?.dischargedTo;
      }

      const dischargeDateTime = getNoTimezoneDateTimeString(sr.dischargeDate, sr.dischargeTime);
      if (dischargeDateTime !== "") {
        patchAuthParams.actualDischargeDateTime = dischargeDateTime;
      }
      const authRes = await patchAuthorization(patchAuthParams, {
        pathParams: { id: retVal.authorization.id },
      });
      retVal.dischargedTo = authRes?.dischargedTo;
    }
  }
}

const MessageBanner = (
  displayMessageAction: RedirectAction | RecommendChangeAction,
  serviceRequest: ServiceRequestResponse,
  workflowId: string | undefined
) => {
  return (
    <Alert severity={isRedirectAction(displayMessageAction) ? "error" : "warning"}>
      <ImpressionTracking
        event="DISPLAY_BANNER"
        stage="AUTH_SUMMARY"
        beforeSnapshot={activitySnapshotFromServiceRequest(serviceRequest)}
        activityContext={{
          ...activityContextFromServiceRequest(serviceRequest),
          ruleId: displayMessageAction.ruleId,
          actionId: displayMessageAction.actionId,
          workflowId: workflowId,
        }}
      >
        <Body2
          style={{
            color: isRedirectAction(displayMessageAction) ? colorsLight.error.dark : colorsLight.warning.dark,
          }}
        >
          <Sanitized __html={displayMessageAction.title || "Warning."} />
        </Body2>
        <Body2 style={{ color: colorsLight.font.secondary }}>
          <Sanitized __html={displayMessageAction.message || "Something is off."} />
        </Body2>
      </ImpressionTracking>
    </Alert>
  );
};
